import React from "react";
import { Backdrop, Button, Typography } from "@mui/material";
import { TutorialMaster, TutorialArrow } from "Components";
import { colors } from "Context";
import { TypingMultiline } from "react-kr-typing-anim";
import { ResponsiveTutorialBox } from "Components/Icons/ResponsiveTutorialBox";

interface PropType {
  open: boolean;
  handleTutorialClose(): void;
  handleNext(): void;
}

export const Tutorial = (props: PropType) => {
  const { open, handleNext } = props;
  const [state, setState] = React.useState({
    nextBtn: false,
    second: false,
    confirmBtn: false,
  });
  const handleNextBtnActive = () => {
    setState({ ...state, nextBtn: true });
  };
  const handleSecondTutorialActive = () => {
    setState({ ...state, second: true });
  };
  const handleConfirmBtnActive = () => {
    setState({ ...state, confirmBtn: true });
  };
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        background: "rgba(37, 37, 37, 0.9)",
      }}
      open={open}
      // onClick={handleTutorialClose}
    >
      <div>
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "1rem",
              paddingRight: "1rem",
            }}
          >
            <TutorialMaster style={{ minWidth: 80 }} />
            <div style={{ position: "relative", marginLeft: "2rem" }}>
              <ResponsiveTutorialBox>
                <div
                  style={{
                    // position: "absolute",
                    width: "100%",
                    // minWidth: 678,
                    top: 46,
                    left: 59,
                    fontSize: 18,
                    fontWeight: 500,
                    letterSpacing: -0.3,
                    paddingLeft: 24,
                    paddingRight: 24,
                  }}
                >
                  {open ? (
                    <TypingMultiline
                      Tag="div"
                      postDelay={700}
                      speed={30}
                      cursor={false}
                      fixedWidth
                      onDone={handleNextBtnActive}
                      strs={`원하는 시공 파트너를 선택하셨네요!
                  선택한 시공 파트너와 계약을 진행할 거예요. 🤝`}
                    />
                  ) : null}
                  <div
                    style={{
                      display: state.nextBtn ? "flex" : "none",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      onClick={handleSecondTutorialActive}
                      disableRipple
                      sx={{
                        color: colors.white,
                        fontSize: 12,
                        fontWeight: 400,
                        letterSpacing: -0.3,
                        textShadow: `0px 0px 10px #EDEDED`,
                        height: 16,
                        mt: 1,
                      }}
                    >
                      다음
                    </Button>
                  </div>
                </div>
              </ResponsiveTutorialBox>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "27%",
            }}
          >
            <TutorialArrow />
            <Typography
              sx={{ ml: 2, fontWeight: 400, fontSize: 12, letterSpacing: -0.3 }}
            >
              다음 버튼을 눌러 다음 페이지를 확인하세요.
            </Typography>
          </div>
        </div>
        <div
          style={{
            display: state.second ? "flex" : "none",
            flexDirection: "column",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            marginTop: 50,
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <TutorialMaster style={{ minWidth: 80 }} />
            <div style={{ position: "relative", marginLeft: "2rem" }}>
              <ResponsiveTutorialBox>
                <div
                  style={{
                    width: "100%",
                    // minWidth: 678,
                    top: 46,
                    left: 59,
                    fontSize: 18,
                    fontWeight: 500,
                    letterSpacing: -0.3,
                    paddingLeft: 24,
                    paddingRight: 24,
                  }}
                >
                  {state.second ? (
                    <TypingMultiline
                      Tag="div"
                      postDelay={700}
                      speed={30}
                      cursor={false}
                      fixedWidth
                      onDone={handleConfirmBtnActive}
                      strs={`계약은 빌더허브가 제공하는 표준 계약서 양식📃으로 안전하게 이루어질 겁니다.
                    계약이 완료되고 공사가 착공되는 시점부터 공사 진행 상황을 데일리로 확인할 수 있어요.`}
                    />
                  ) : null}
                  <div
                    style={{
                      display: state.confirmBtn ? "flex" : "none",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      onClick={handleNext}
                      disableRipple
                      sx={{
                        color: colors.white,
                        fontSize: 12,
                        fontWeight: 400,
                        letterSpacing: -0.3,
                        textShadow: `0px 0px 10px #EDEDED`,
                        height: 16,
                        mt: 1,
                      }}
                    >
                      확인
                    </Button>
                  </div>
                </div>
              </ResponsiveTutorialBox>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "27%",
            }}
          >
            <TutorialArrow />
            <Typography
              sx={{ ml: 2, fontWeight: 400, fontSize: 12, letterSpacing: -0.3 }}
            >
              확인 버튼을 눌러 다음 페이지를 확인하세요.
            </Typography>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};
