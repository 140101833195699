import { styled, Paper, Grid } from "@mui/material";
import {
  SitePicOne,
  AddNewPic,
  SitePicTwo,
  SitePicThree,
} from "Components/Images";
const StyledPaper = styled(Paper)(() => ({
  width: "100%",
  height: "100%",
  border: "1px solid #F2F2F2",
  boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.08)",
  borderRadius: "8px",
  paddingTop: 31,
  paddingLeft: 24,
  paddingRight: 24,
  paddingBottom: 24,
}));

const SectionHeader = styled(`div`)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  borderBottom: "1px solid #dbdbdb",
  paddingBottom: 20,
  "span:first-of-type": {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "24px",
    color: "#262338",
  },
  "span:last-of-type": {
    fontWeight: 400,
    fontSize: 10,
    lineHeight: "14px",
    color: "#a8a7af",
  },
}));

const StyledGrid = styled(Grid)(() => ({
  marginTop: 20,
  svg: {
    marginRight: 16,
  },
}));

export const SitePics = () => {
  return (
    <StyledPaper>
      <SectionHeader>
        <span>현장 사진</span>
        <span>
          건축주가 생생하게 현장을 확인할 수 있게 현장 구석구석을 직접 촬영한
          사진으로 등록해 주세요.
        </span>
      </SectionHeader>
      <StyledGrid container item lg={12}>
        <AddNewPic />
        <SitePicOne />
        <SitePicTwo />
        <SitePicThree />
      </StyledGrid>
    </StyledPaper>
  );
};
