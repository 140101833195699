import { Button, styled } from "@mui/material";
import Slider from "react-slick";

export const ImageContainer = styled("div")`
  .container {
    position: relative;
    width: 100%;
    cursor: pointer;
    z-index: 1;
  }

  .image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: 0.5s ease;
    backface-visibility: hidden;
  }

  .middle {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 80%;
    left: 40%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }

  .container:hover .image {
    opacity: 0.9;
  }

  .container:hover .middle {
    opacity: 1;
  }

  .text {
    color: #514f60;
    font-size: 9px;
    border-radius: 10px;
    border: solid 1px white;
    background: rgba(255, 255, 255, 0.7);
    padding: 2px;
  }
`;

export const StyledButton = styled(Button)(() => ({
  "@keyframes blink": {
    "0%": { filter: "drop-shadow(0px 0px 20px rgba(102, 74, 227, 0.7))" },
    "50%": { filter: "none" },
    "100%": { filter: "drop-shadow(0px 0px 20px rgba(102, 74, 227, 0.7))" },
  },
  animation: "blink 1.5s linear infinite",
  borderRadius: "12px",
  "&:hover": {
    animation: "none",
  },
}));

export const GlowingDiv = styled("div")(() => ({
  "@keyframes blink": {
    "0%": { filter: "drop-shadow(0px 0px 20px rgba(102, 74, 227, 0.7))" },
    "50%": { filter: "none" },
    "100%": { filter: "drop-shadow(0px 0px 20px rgba(102, 74, 227, 0.7))" },
  },
  animation: "blink 1.5s linear infinite",

  "&:hover": {
    boxShadow: "0px 0px 16px rgba(102, 74, 227, 0.24)",
    animation: "none",
  },
  borderRadius: "8px",
}));

export const StyledSlider = styled(Slider)(() => ({
  ".slick-list": {
    position: "relative",
    overflow: "visible",
  },
  ".slick-slider": {
    userSelect: "auto",
  },
}));

export const GlowingBack = styled("div")(() => ({
  "@keyframes backblink": {
    "0%": {
      width: 0,
      height: 0,
      left: "45%",
      top: "45%",
    },
    "100%": {
      width: 20,
      height: 20,
      left: "30%",
      top: "30%",
    },
  },
  animation: "backblink 1.5s linear infinite",
  boxShadow: "0px 0px 30px rgba(102, 74, 227, 1)",
  borderRadius: "8px",
  position: "absolute",
  backgroundColor: "rgba(102, 74, 227, 0.2)",
  left: "25%",
  top: "25%",
  width: 20,
  height: 20,
}));
