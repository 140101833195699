import * as React from "react";
import { SVGProps } from "react";

export const OrderKakaopay = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="72"
    height="29"
    viewBox="0 0 72 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.9953 29H15.0047C6.71851 29 0 22.5075 0 14.5C0 6.49254 6.71851 0 15.0047 0H57.0327C65.3188 0 72.0373 6.49254 72.0373 14.5C72.0373 22.5075 65.2815 29 56.9953 29Z"
      fill="#FFEB00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.8343 6.96094C12.318 6.96094 8.66016 9.7383 8.66016 13.201C8.66016 15.4012 10.1905 17.349 12.4673 18.4672L11.6835 21.2806C11.6462 21.3527 11.6835 21.4609 11.7581 21.5331C11.7955 21.5691 11.8701 21.6052 11.9074 21.6052C11.9448 21.6052 12.0194 21.5691 12.0567 21.5691L15.3787 19.405C15.8639 19.4771 16.3491 19.5132 16.8717 19.5132C21.388 19.5132 25.0458 16.6997 25.0458 13.2731C25.0458 9.7383 21.388 6.96094 16.8343 6.96094Z"
      fill="black"
    />
    <path
      d="M31.9508 18.3945V22.4343H28.9648V7.53752H31.055L31.4283 8.47533C32.0628 7.86214 33.0333 7.21289 34.5636 7.21289C37.475 7.21289 38.856 9.30493 38.856 12.7315C38.856 16.3024 36.7285 18.5748 33.6678 18.5748C33.0706 18.6109 32.6227 18.5748 31.9508 18.3945ZM31.9508 9.95418V16.3746C32.1001 16.4107 32.5107 16.4467 32.9213 16.4467C35.0488 16.4467 35.87 15.0039 35.87 12.7315C35.87 10.7477 35.3101 9.55742 33.6305 9.55742C33.0333 9.55742 32.4361 9.7017 31.9508 9.95418Z"
      fill="black"
    />
    <path
      d="M45.3139 11.7578H46.9935V11.3971C46.9935 10.2068 46.2843 9.66575 45.0899 9.66575C44.1568 9.66575 42.9997 9.91824 42.0292 10.3871L41.2081 8.47545C42.2532 7.75406 43.8955 7.28516 45.3512 7.28516C48.1879 7.28516 49.7555 8.72794 49.7555 11.5053V18.3225H47.6653L47.3667 17.4207C46.1723 18.2503 45.0526 18.611 44.0821 18.611C41.9173 18.611 40.6855 17.3486 40.6855 15.2205C40.6855 12.9481 42.2905 11.7578 45.3139 11.7578ZM47.0308 15.5451V13.5613H45.6498C44.1195 13.5613 43.3356 14.1023 43.3356 15.1483C43.3356 15.9419 43.7462 16.3386 44.642 16.3386C45.4632 16.3747 46.5083 15.9779 47.0308 15.5451Z"
      fill="black"
    />
    <path
      d="M58.7871 17.2041C57.742 19.8732 56.5102 21.821 54.6813 22.9031L52.8524 21.2799C53.8975 20.3782 54.6813 19.5125 55.3158 18.3583L51.3594 7.93416L54.3081 7.17669L56.8462 15.617L59.3469 7.14062L62.2583 7.93416L58.7871 17.2041Z"
      fill="black"
    />
  </svg>
);
