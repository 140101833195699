import { TableCell, TableRow, styled } from "@mui/material";
import { colors } from "Context";

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#FAFAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  cursor: "pointer",
}));

export const CustomHeadTableCell = styled(TableCell)(() => ({
  fontSize: "14px",
  fontWeight: 500,
  color: colors.grey._60,
  borderBottom: `solid 0.5px ${colors.grey._20}`,
}));

export const CustomTableCell = styled(TableCell)(() => ({
  fontSize: "14px",
  fontWeight: 400,
  color: colors.grey._100,
  border: 0,
}));
