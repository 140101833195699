import {
  Address,
  DeliveryDate,
  QuotationDetail,
  PaymentInfo,
  ProjectSummary,
  Payment,
} from "./Content/index";
import { Grid } from "@mui/material";
import { Container, OrderHead, OrderWrap, RightSide } from "./Styles";
import React from "react";

export const Order = () => {
  const [selected, setSelected] = React.useState("");
  const handlePaymentInfoButtonColor = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    event.stopPropagation();
    setSelected(event.currentTarget.id);
  };

  return (
    <Container
      id="wrap"
      onClick={handlePaymentInfoButtonColor}
      sx={{ overflowX: { xs: "hidden", sm: "visible" } }}
    >
      <OrderWrap container columnSpacing={2}>
        <Grid item lg={9} sm={8} xs={12}>
          <OrderHead>주문서 작성하기</OrderHead>
          <Address />
          <DeliveryDate />
          <QuotationDetail />
          <PaymentInfo
            selected={selected}
            handlePaymentInfoButtonColor={handlePaymentInfoButtonColor}
          />
        </Grid>
        <Grid item lg={3} sm={4} xs={12}>
          <RightSide>
            <ProjectSummary />
            <Payment />
          </RightSide>
        </Grid>
      </OrderWrap>
    </Container>
  );
};
