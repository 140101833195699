import * as React from "react";
import { SVGProps } from "react";

export const FormIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={15}
    height={17}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.579 8.394v3.279c0 .68.529 1.23 1.184 1.23.655 0 1.184-.55 1.184-1.23V8.394c0-.68-.529-1.229-1.184-1.229-.655 0-1.184.55-1.184 1.23Zm4.737 0v3.279c0 .68.529 1.23 1.184 1.23.655 0 1.184-.55 1.184-1.23V8.394c0-.68-.529-1.229-1.184-1.229-.655 0-1.184.55-1.184 1.23ZM1.184 17h12.632C14.47 17 15 16.45 15 15.77c0-.68-.529-1.229-1.184-1.229H1.184c-.655 0-1.184.55-1.184 1.23C0 16.45.529 17 1.184 17Zm9.869-8.606v3.279c0 .68.529 1.23 1.184 1.23.655 0 1.184-.55 1.184-1.23V8.394c0-.68-.529-1.229-1.184-1.229-.655 0-1.184.55-1.184 1.23ZM6.766.191.529 3.6A1.023 1.023 0 0 0 0 4.502c0 .565.442 1.024.987 1.024H14.02c.537 0 .979-.459.979-1.024 0-.377-.205-.73-.529-.902L8.234.19a1.51 1.51 0 0 0-1.468 0Z"
      fill="#D4D3D7"
    />
  </svg>
);
