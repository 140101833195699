import React from "react";
import { MonthlyContainer } from "../Styles";
import { GroupedBarChart } from "./GroupedBarChart";
import { Typography, Box } from "@mui/material";
import { colors } from "Context";
import moment from "moment";
import { nanoid } from "nanoid";

function createData(month: string, partnerValue: number, fieldValue: number) {
  return { key: nanoid(10), month, partnerValue, fieldValue };
}

const data = [
  createData("1월", 80, 40),
  createData("2월", 75, 30),
  createData("3월", 45, 80),
  createData("4월", 65, 86),
  createData("5월", 70, 72),
  createData("6월", 40, 78),
  createData("7월", 90, 78),
  createData("8월", 82, 98),
  createData("9월", 42, 88),
  createData("10월", 52, 38),
  createData("11월", 0, 0),
  createData("12월", 0, 0),
];

export function MonthlyPaymentStatus() {
  return (
    <MonthlyContainer>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{
              fontSize: "1rem",
              fontWeight: 700,
              minWidth: "2rem",
            }}
          >
            월별 기성금 지급 현황
          </Typography>
          <Typography
            sx={{
              color: colors.grey._40,
              fontSize: 10,
              mt: "0.4em",
              fontWeight: 400,
            }}
          >
            바를 선택 시 현황 내역을 간략하게 확인 할 수 있어요.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            my: "20px",
            width: "100%",
          }}
        >
          <Typography sx={{ color: colors.grey._40, fontSize: "12px" }}>
            {moment().format("YYYY년")}
          </Typography>
          <Legend />
        </Box>
        {data.map((item) => (
          <GroupedBarChart {...item} />
        ))}
      </Box>
    </MonthlyContainer>
  );
}

function Legend() {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box
        sx={{
          height: 12,
          width: 12,
          background: colors.purple._40,
          borderRadius: "4px",
        }}
      ></Box>
      <Typography
        sx={{
          ml: "0.5rem",
          fontSize: 12,
          fontWeight: 700,
          color: colors.grey._60,
        }}
      >
        거래처
      </Typography>
      <Box
        sx={{
          ml: "1em",
          height: 12,
          width: 12,
          background: "#84EDE0",
          borderRadius: "4px",
        }}
      ></Box>
      <Typography
        sx={{
          ml: "0.5rem",
          fontSize: 12,
          fontWeight: 700,
          color: colors.grey._60,
        }}
      >
        현장
      </Typography>
    </Box>
  );
}
