import * as React from "react";
import { SVGProps } from "react";
import { createSvgIcon } from "@mui/material";

export const WriteIcon = createSvgIcon(
  <>
    <path
      d="M4.16 23.563h14.446c2.186 0 3.411-1.171 3.411-3.424V7.645l-1.532 1.447v10.975c0 1.352-.783 2.05-1.904 2.05H4.194c-1.398 0-2.158-.698-2.158-2.05V6.753c0-1.355.76-2.057 2.158-2.057h11.724l1.533-1.446H4.16C1.746 3.25.5 4.41.5 6.673V20.14c0 2.272 1.246 3.424 3.66 3.424Z"
      fill="#fff"
    />
    <path
      d="m9.103 16.233 2.094-.979 11.39-11.802-1.412-1.455L9.793 13.8l-.997 2.106c-.086.18.117.409.307.327ZM23.411 2.61l.793-.84c.388-.42.396-.926.015-1.313L24.02.244c-.345-.358-.856-.31-1.229.067l-.8.822 1.42 1.477Z"
      fill="#fff"
    />
  </>,
  "WriteIcon"
);

export const Write = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={26}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.16 23.563h14.446c2.186 0 3.411-1.171 3.411-3.424V7.645l-1.532 1.447v10.975c0 1.352-.783 2.05-1.904 2.05H4.194c-1.398 0-2.158-.698-2.158-2.05V6.753c0-1.355.76-2.057 2.158-2.057h11.724l1.533-1.446H4.16C1.746 3.25.5 4.41.5 6.673V20.14c0 2.272 1.246 3.424 3.66 3.424Z"
      fill="#fff"
    />
    <path
      d="m9.103 16.233 2.094-.979 11.39-11.802-1.412-1.455L9.793 13.8l-.997 2.106c-.086.18.117.409.307.327ZM23.411 2.61l.793-.84c.388-.42.396-.926.015-1.313L24.02.244c-.345-.358-.856-.31-1.229.067l-.8.822 1.42 1.477Z"
      fill="#fff"
    />
  </svg>
);
