import * as React from "react";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Logo, Builderhub } from "Components";
import {
  Wrap,
  BuilderhubAppBar,
  BuilderhubToolbar,
  ButtonDiv,
  ServiceBtn,
  UserBtn,
  ProjectBtn,
} from "./Styles";
import { useNavigate, useLocation } from "react-router-dom";
import { colors } from "Context";

interface Props {
  window?: () => Window;
  children: React.ReactNode;
}

const drawerWidth = 240;

export function Layout(props: Props) {
  const { window, children } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [state, setState] = React.useState({
    navServiceItems: [
      { name: "서비스 둘러보기", path: "" },
      { name: "빌더허브 바로가기", path: "" },
      { name: "FAQ", path: "" },
    ],
    navUserItems: [
      { name: "로그인", path: "" },
      { name: "회원가입", path: "" },
    ],
    navProjectItems: [{ name: "프로젝트 등록", path: "" }],
  });
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const navigation = useNavigate();
  const location = useLocation();
  React.useEffect(() => {
    if (location.pathname.startsWith("/partner")) {
      setState({
        ...state,
        navServiceItems: [
          { name: "대시보드", path: "/partner" },
          { name: "프로젝트", path: "/partner/project-list" },
          { name: "공사관리", path: "/partner/manage-construction" },
          { name: "기성관리", path: "/partner/manage-payments" },
          { name: "자재Shop", path: "#" },
        ],
        navUserItems: [{ name: "왕방울 파트너", path: "#" }],
      });
    } else if (location.pathname.startsWith("/customer")) {
      setState({
        ...state,
        navServiceItems: [
          { name: "프로젝트", path: "/customer" },
          { name: "파트너 매칭", path: "/customer/matching" },
          { name: "공사관리", path: "/customer/construction-status" },
          { name: "자재Shop", path: "/customer/order" },
        ],
        navUserItems: [{ name: "왕방울 의뢰인", path: "#" }],
      });
    } else {
      setState({
        ...state,
        navServiceItems: [
          { name: "서비스 둘러보기", path: "#" },
          { name: "빌더허브 바로가기", path: "#" },
          { name: "FAQ", path: "" },
        ],
        navUserItems: [
          { name: "로그인", path: "#" },
          { name: "회원가입", path: "#" },
        ],
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  const { navServiceItems, navUserItems, navProjectItems } = state;
  const navItems = [
    ...state.navServiceItems,
    ...state.navUserItems,
    ...state.navProjectItems,
  ];
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Button
        sx={{ my: 2, mt: 3, cursor: "pointer" }}
        onClick={() => navigation("/")}
      >
        <Builderhub />
      </Button>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton sx={{ textAlign: "left" }}>
              <ListItemText
                sx={{
                  color:
                    location.pathname === item.path
                      ? colors.purple._60
                      : colors.grey._40,
                }}
                primary={item.name}
                onClick={() => navigation(item.path)}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Wrap>
      <BuilderhubAppBar>
        <BuilderhubToolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ ml: 0, display: { sm: "none" } }}
          >
            <Logo />
          </IconButton>
          <div style={{ width: 145 }}>
            <Button
              sx={{
                flexGrow: 1,
                display: { xs: "none", sm: "block" },
                pt: "10px",
              }}
              onClick={() => {
                navigation("/");
              }}
              fullWidth
            >
              <Builderhub />
            </Button>
          </div>
          <ButtonDiv>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              {navServiceItems.map((item) => (
                <ServiceBtn
                  key={item.name}
                  sx={{
                    color:
                      location.pathname === item.path
                        ? colors.purple._60
                        : colors.grey._40,
                  }}
                  onClick={() => navigation(item.path)}
                >
                  {item.name}
                </ServiceBtn>
              ))}
            </Box>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              {navUserItems.map((item) => (
                <UserBtn key={item.name}>{item.name}</UserBtn>
              ))}
              {navProjectItems.map((item) => (
                <ProjectBtn variant="contained" key={item.name}>
                  {item.name}
                </ProjectBtn>
              ))}
            </Box>
          </ButtonDiv>
        </BuilderhubToolbar>
      </BuilderhubAppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ width: "100%" }}>
        <BuilderhubToolbar />
        {children}
      </Box>
    </Wrap>
  );
}
