import * as React from "react";
export const DashboardGraphPurple = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="18" height="18" rx="4" fill="#836DE9" />
  </svg>
);
