import React from "react";
import { Box, Grid, styled, Typography } from "@mui/material";
import { AppContainer } from "../Styles";
import { AppStore } from "./AppStore";
import { GooglePlay } from "./GooglePlay";
import mobileImage from "./mobileApp.png";

const Container = styled(Grid)(() => ({
  display: "flex",
  alignItems: "flex-end",
}));

const ImageContainer = styled(Grid)(() => ({
  display: "flex",
  alignItems: "flex-end",
  height: "100%",
  minHeight: 245,
  // transform: "rotate(2deg)",
}));

const ImageBackground = styled(Box)(() => ({
  background: "#CCD7F4",
  position: "absolute",
  width: 281,
  zIndex: 0,
}));

const Image = styled("img")(() => ({
  position: "absolute",
  zIndex: 1,
}));

export function AppDownload() {
  return (
    <AppContainer>
      <Container container>
        <Grid
          item
          md={6}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            // alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              maxWidth: "260px",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography
              sx={{
                backgroundColor: "#E0EBFF",
                width: "169px",
                height: "24px",
                borderRadius: "4px",
                color: "#0544B6",
                fontSize: "12px",
                fontWeight: 500,
                mb: "1em",
                mt: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              빌더허브 공사관리앱 다운로드
            </Typography>
            <Typography
              sx={{ fontSize: "20px", fontWeight: 400, color: "#44485B" }}
            >
              공사 현장 관리,
            </Typography>
            <Typography
              sx={{ fontSize: "20px", fontWeight: 400, color: "#44485B" }}
            >
              빌더허브 공사관리앱으로
            </Typography>
            <Typography
              sx={{ fontSize: "20px", fontWeight: 400, color: "#44485B" }}
            >
              <span style={{ color: "#23267E", fontWeight: 500 }}>원스톱</span>{" "}
              해결하세요.
            </Typography>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                mt: "28px",
              }}
            >
              <GooglePlay bgColor="#6165D7" viewBox="0 0 144 48" />
              <AppStore viewBox="0 0 132 48" />
            </Box>
          </Box>
        </Grid>
        <ImageContainer item md={6} xs={12} sx={{ ml: { md: 0, xs: "5vw" } }}>
          <Image src={mobileImage} alt="mobile app" />
          <ImageBackground
            sx={{
              mb: { md: "42px", xs: 0 },
              minWidth: { md: "281px", xs: "100px" },
              minHeight: { md: "112px", sx: "40px" },
              filter: { md: "blur(50px)", sx: "blur(10px)" },
            }}
          />
          {/* <BlurBox style={{ position: "absolute", zIndex: 1, minWidth: 447 }} /> */}
        </ImageContainer>
      </Container>
    </AppContainer>
  );
}
