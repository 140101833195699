import { Grid } from "@mui/material";
import {
  DashboardDailyInsertDefault,
  DashboardDailyRegistPhotoClicked,
  DashboardDailyRegistWorkClicked,
  DashboardDailyInsertClicked,
} from "Components/Icons/index";
import { colors } from "Context";
import {
  Board,
  Column,
  DetailTypo,
  Row,
  SmallestTypo,
  TitleTypo,
} from "./Styles";
import { useNavigate } from "react-router-dom";
import React from "react";

export const DailyReport = () => {
  const navigate = useNavigate();
  const [clicked, setClicked] = React.useState("");
  const handleSvgColor = (event: React.MouseEvent<HTMLElement>) => {
    setClicked(event.currentTarget.id);
  };
  const goMageConstruction = () => {
    navigate("/partner/manage-construction");
  };
  return (
    <Board sx={{ pb: 5 }}>
      <Row sx={{ pb: 2 }}>
        <TitleTypo>데일리 현장 레포트</TitleTypo>
        <DetailTypo
          sx={{
            fontWeight: 700,
            cursor: "pointer",
            color: colors.purple._60,
            "&:hover": {
              color: colors.purple._10,
            },
          }}
          onClick={goMageConstruction}
        >
          + 더보기
        </DetailTypo>
      </Row>

      <Grid container spacing={1} rowSpacing={2} sx={{ margin: "auto" }}>
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Column
            sx={{
              alignItems: "start",
              cursor: "pointer",
              opacity: 1,
              "&:hover": {
                opacity: 0.5,
              },
            }}
            id="registPhoto"
            onClick={goMageConstruction}
          >
            <DashboardDailyRegistPhotoClicked />
            <SmallestTypo
              style={{
                paddingTop: 12,
                fontWeight: 700,
                color: colors.purple._60,
              }}
            >
              현장 사진 등록
            </SmallestTypo>
          </Column>
        </Grid>

        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Column
            sx={{
              alignItems: "start",
              cursor: "pointer",
              opacity: 1,
              "&:hover": {
                opacity: 0.5,
              },
            }}
            id="registWork"
            // onClick={handleSvgColor}
            onClick={goMageConstruction}
          >
            {/* {clicked === "registWork" ? ( */}
            <DashboardDailyRegistWorkClicked />
            {/* ) : (
              <DashboardDailyRegistWorkDefault />
            )} */}
            {/* {clicked === "registWork" ? ( */}
            <SmallestTypo
              style={{
                paddingTop: 12,
                fontWeight: 700,
                color: colors.purple._60,
              }}
            >
              작업 내용 등록
            </SmallestTypo>
            {/* ) : (
              <SmallestTypo style={{ paddingTop: 12, fontWeight: 700 }}>
                작업 내용 등록
              </SmallestTypo>
            )} */}
          </Column>
        </Grid>

        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Column
            sx={{
              alignItems: "start",
              cursor: "pointer",
              opacity: 1,
              "&:hover": {
                opacity: 0.5,
              },
            }}
            id="insert"
            onClick={handleSvgColor}
          >
            {clicked === "insert" ? (
              <DashboardDailyInsertClicked />
            ) : (
              <DashboardDailyInsertDefault />
            )}
            {clicked === "insert" ? (
              <SmallestTypo
                style={{
                  paddingTop: 12,
                  fontWeight: 700,
                  color: colors.purple._60,
                }}
              >
                투입 현황 등록
              </SmallestTypo>
            ) : (
              <SmallestTypo style={{ paddingTop: 12, fontWeight: 700 }}>
                투입 현황 등록
              </SmallestTypo>
            )}
          </Column>
        </Grid>
      </Grid>
    </Board>
  );
};
