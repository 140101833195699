import { createSvgIcon } from "@mui/material";

export const BldgTypeIcon = createSvgIcon(
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#a)">
      <path
        d="M5.579 11.395v3.278c0 .68.529 1.23 1.184 1.23.655 0 1.184-.55 1.184-1.23v-3.278c0-.68-.529-1.23-1.184-1.23-.655 0-1.184.55-1.184 1.23Zm4.737 0v3.278c0 .68.529 1.23 1.184 1.23.655 0 1.184-.55 1.184-1.23v-3.278c0-.68-.529-1.23-1.184-1.23-.655 0-1.184.55-1.184 1.23ZM5.184 20h12.632C18.47 20 19 19.45 19 18.77c0-.68-.529-1.229-1.184-1.229H5.184c-.655 0-1.184.55-1.184 1.23C4 19.45 4.529 20 5.184 20Zm9.869-8.605v3.278c0 .68.529 1.23 1.184 1.23.655 0 1.184-.55 1.184-1.23v-3.278c0-.68-.529-1.23-1.184-1.23-.655 0-1.184.55-1.184 1.23ZM10.766 3.19 4.529 6.6A1.023 1.023 0 0 0 4 7.502c0 .565.442 1.024.987 1.024H18.02c.537 0 .979-.459.979-1.024 0-.377-.205-.73-.529-.902l-6.237-3.41a1.51 1.51 0 0 0-1.468 0Z"
        fill="#D4D3D7"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>,
  "BldgTypeIcon"
);

export const BldgScaleIcon = createSvgIcon(
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 2 2 5l8 3 8-3-8-3Z"
      stroke="#D4D3D7"
      strokeWidth={2.8}
      strokeLinejoin="round"
    />
    <path
      d="M10 8 2 5v9.75l8 3V8ZM18 14.75V5l-8 3v9.75l8-3Z"
      stroke="#D4D3D7"
      strokeWidth={2.8}
      strokeLinejoin="round"
    />
  </svg>,
  "BldgScaleIcon"
);

export const BldgBudgetIcon = createSvgIcon(
  <path
    d="M9 0C4.032 0 0 4.032 0 9s4.032 9 9 9 9-4.032 9-9-4.032-9-9-9Zm1.269 14.481v.522c0 .657-.54 1.197-1.197 1.197h-.009c-.657 0-1.197-.54-1.197-1.197v-.54c-1.197-.252-2.259-.909-2.709-2.016-.207-.495.18-1.044.72-1.044h.216c.333 0 .603.225.729.54.261.675.945 1.143 2.259 1.143 1.764 0 2.16-.882 2.16-1.431 0-.747-.396-1.449-2.403-1.926-2.232-.54-3.762-1.458-3.762-3.303 0-1.548 1.251-2.556 2.799-2.889v-.54c0-.657.54-1.197 1.197-1.197h.009c.657 0 1.197.54 1.197 1.197v.558c1.242.306 2.025 1.08 2.367 2.034.18.495-.198 1.017-.729 1.017h-.234c-.333 0-.603-.234-.693-.558-.207-.684-.774-1.125-1.908-1.125-1.35 0-2.16.612-2.16 1.476 0 .756.585 1.251 2.403 1.719 1.818.468 3.762 1.251 3.762 3.519-.018 1.647-1.251 2.547-2.817 2.844Z"
    fill="#D4D3D7"
  />,
  "BldgBudgetIcon"
);

export const CalendarIcon = createSvgIcon(
  <svg width={14} height={12} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#calendaricon)">
      <path
        d="M2.084 12h9.835C13.299 12 14 11.34 14 10.066V1.952C14 .68 13.3.018 11.919.018H2.084C.705.018 0 .668 0 1.952v8.114C0 11.349.705 12 2.084 12Zm-.062-.819c-.748 0-1.147-.366-1.147-1.082V4.043c0-.711.4-1.082 1.147-1.082h9.951c.735 0 1.152.371 1.152 1.082v6.054c0 .716-.417 1.083-1.152 1.083H2.022ZM5.6 5.311h.417c.221 0 .279-.05.279-.255v-.388c0-.205-.058-.26-.28-.26H5.6c-.221 0-.284.055-.284.26v.388c0 .205.063.255.284.255Zm2.395 0h.417c.221 0 .284-.05.284-.255v-.388c0-.205-.063-.26-.284-.26h-.417c-.221 0-.284.055-.284.26v.388c0 .205.063.255.284.255Zm2.395 0h.418c.22 0 .284-.05.284-.255v-.388c0-.205-.063-.26-.284-.26h-.418c-.22 0-.277.055-.277.26v.388c0 .205.056.255.277.255Zm-7.186 2.21h.413c.226 0 .284-.05.284-.255v-.389c0-.205-.058-.254-.284-.254h-.413c-.226 0-.284.05-.284.254v.389c0 .205.058.254.284.254Zm2.396 0h.417c.221 0 .279-.05.279-.255v-.389c0-.205-.058-.254-.28-.254H5.6c-.221 0-.284.05-.284.254v.389c0 .205.063.254.284.254Zm2.395 0h.417c.221 0 .284-.05.284-.255v-.389c0-.205-.063-.254-.284-.254h-.417c-.221 0-.284.05-.284.254v.389c0 .205.063.254.284.254Zm2.395 0h.418c.22 0 .284-.05.284-.255v-.389c0-.205-.063-.254-.284-.254h-.418c-.22 0-.277.05-.277.254v.389c0 .205.056.254.277.254ZM3.204 9.733h.413c.226 0 .284-.054.284-.257v-.39c0-.205-.058-.255-.284-.255h-.413c-.226 0-.284.05-.284.254v.391c0 .203.058.257.284.257Zm2.396 0h.417c.221 0 .279-.054.279-.257v-.39c0-.205-.058-.255-.28-.255H5.6c-.221 0-.284.05-.284.254v.391c0 .203.063.257.284.257Zm2.395 0h.417c.221 0 .284-.054.284-.257v-.39c0-.205-.063-.255-.284-.255h-.417c-.221 0-.284.05-.284.254v.391c0 .203.063.257.284.257Z"
        fill="#664AE3"
      />
    </g>
    <defs>
      <clipPath id="calendaricon">
        <path fill="#fff" d="M0 0h14v12H0z" />
      </clipPath>
    </defs>
  </svg>,
  "CalendarIcon"
);

export const BookmarkIcon = createSvgIcon(
  <path
    d="M2.123 32c.982 0 1.562-.442 3.365-1.814l7.732-5.106c.1-.079.28-.079.36 0l7.932 5.106C23.315 31.558 23.895 32 24.877 32 26.219 32 27 31.306 27 30.076V4.526C27 1.53 25.097 0 21.332 0H5.668C1.903 0 0 1.53 0 4.526v25.55C0 31.306.781 32 2.123 32Z"
    fill="#664AE3"
  />,
  "BookmarkIcon"
);

export const BookmarkUnselectedIcon = createSvgIcon(
  <path
    d="M1.873 31.953c.822 0 1.351-.327 2.726-1.316l8.734-6.47c.097-.067.251-.067.334 0l8.734 6.47c1.375.984 1.898 1.316 2.726 1.316 1.169 0 1.873-.58 1.873-1.57V4.087C27 1.392 25.137 0 21.509 0H5.49C1.863 0 0 1.392 0 4.086v26.298c0 .99.704 1.57 1.873 1.57ZM3.179 28.9c-.316.241-.66.176-.66-.152V4.12c0-1.493 1.047-2.268 3.1-2.268h15.777c2.038 0 3.085.775 3.085 2.268v24.627c0 .328-.324.393-.66.152l-9.39-6.895a1.603 1.603 0 0 0-1.861 0L3.179 28.9Z"
    fill="#DBDBDB"
  />,
  "BookmarkUnselectedIcon"
);
