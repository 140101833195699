import React from "react";
import { Grid, Divider, Box } from "@mui/material";
import {
  Wrap,
  Wrapper,
  NameTypo,
  AddressTypo,
  Title,
  Content,
  FlexRow,
  FlexColumn,
  TitleGrid,
  EditBtn,
  FlexAlignCenter,
  ContentGrid,
} from "./ProjectInfoStyles";
import { SettingIcon, MapPinIcon } from "Components/Icons";
import { colors } from "Context";

interface Info {
  title: string;
  address: string;
  constructionType: string;
  budget: string;
  desiredCompletionDate: string;
  status: string;
  requirement: string;
}
interface PropType {
  info: Info;
}

export const ProjectInfo = (props: PropType) => {
  const {
    info: { title, address, constructionType, budget, desiredCompletionDate },
  } = props;
  return (
    <Wrap>
      <Wrapper
        container
        sx={{
          border: { lg: "1px solid #DBDBDB", md: "none" },
          padding: "0px 16px",
        }}
      >
        <TitleGrid
          item
          lg={5}
          md={12}
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            border: {
              lg: "none",
              md: "1px solid #DBDBDB",
              xs: "1px solid #DBDBDB",
            },
          }}
        >
          <Box>
            <NameTypo>{title}</NameTypo>
            <FlexAlignCenter>
              <MapPinIcon />
              <AddressTypo>{address}</AddressTypo>
            </FlexAlignCenter>
          </Box>
          <Box sx={{ display: { lg: "none", xs: "flex" } }}>
            <EditBtn
              variant="contained"
              color="inherit"
              startIcon={<SettingIcon color={colors.grey._40} />}
            >
              <Content>수정</Content>
            </EditBtn>
          </Box>
        </TitleGrid>
        <ContentGrid
          container
          item
          lg={7}
          md={12}
          xs={12}
          sx={{
            minHeight: 56,
            border: {
              lg: "none",
              md: "1px solid #DBDBDB",
              xs: "1px solid #DBDBDB",
            },
            mt: {
              lg: 0,
              md: 2,
              xs: 2,
            },
            p: {
              lg: "10px 0px 10px 16px",
              md: "10px 36px",
            },
          }}
        >
          <Grid item lg={10} xs={12}>
            <FlexRow>
              <FlexColumn>
                <Title>유형</Title>
                <Content>{constructionType}</Content>
              </FlexColumn>
              <Divider orientation="vertical" variant="middle" flexItem />
              <FlexColumn>
                <Title>완공시기</Title>
                <Content>{desiredCompletionDate}</Content>
              </FlexColumn>
              <Divider orientation="vertical" variant="middle" flexItem />
              <FlexColumn>
                <Title>예산</Title>
                <Content>{budget}</Content>
              </FlexColumn>
              <Divider orientation="vertical" variant="middle" flexItem />
              <FlexColumn>
                <Title>요청사항</Title>
                <Content
                  sx={{
                    textDecoration: "underline",
                    color: colors.grey._40,
                    cursor: "pointer",
                    fontWeight: 500,
                  }}
                >
                  자세히보기
                </Content>
              </FlexColumn>
            </FlexRow>
          </Grid>
          <Grid
            item
            lg={2}
            sx={{
              display: { lg: "flex", xs: "none" },
              justifyContent: "center",
              pl: 2,
            }}
          >
            <EditBtn
              variant="contained"
              color="inherit"
              startIcon={<SettingIcon color={colors.grey._40} />}
            >
              <Content>수정</Content>
            </EditBtn>
          </Grid>
        </ContentGrid>
      </Wrapper>
    </Wrap>
  );
};
