import { Paper, styled, Grid } from "@mui/material";
import { DashboardWeatherRefresh } from "Components/Icons/DashboardWeatherRefresh";
import { ArrowLeft, ArrowRight, SunnyDay } from "Components/Icons";

const StyledPaper = styled(Paper)(() => ({
  width: "100%",
  height: "100%",
  minHeight: 331,
  border: "1px solid #F2F2F2",
  boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.08)",
  borderRadius: "8px",
  paddingTop: 24,
  paddingLeft: 24,
  paddingBottom: 19,
  paddingRight: 24,
}));

const PaperHeader = styled(`div`)(() => ({
  display: "flex",
  alignItems: "center",
  marginBottom: 20,
  "span:first-of-type": {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: "24px",
    letterSpacing: -0.3,
  },
  "span:last-of-type": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontWeight: 400,
    fontSize: 10,
    lineHeight: "14px",
    letterSpacing: -0.3,
  },
  svg: {
    marginLeft: 12,
    marginRight: 12,
  },
}));

const MiddleText = styled(`div`)(() => ({
  marginBottom: 20,
  "span:first-of-type": {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "16px",
    marginRight: 8,
  },
  "span:last-of-type": {
    fontWeight: 400,
    fontSize: 10,
    lineHeight: "14px",
  },
}));

const IndicatorBox = styled(`div`)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 32,
  div: {
    span: {
      marginLeft: 12.9,
      fontWeight: 700,
      fontSize: 52,
      lineHeight: "56px",
      letterSpacing: -0.42,
    },
    svg: {
      maxWidth: 45,
      maxHeight: 45,
      width: "100%",
      height: "100%",
    },
  },
}));
const IndicatorText = styled(`div`)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  marginBottom: 20,
  div: {
    fontSize: 12,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",

    "span:first-of-type": {
      fontWeight: 400,
      color: "#a8a7af",
      marginRight: 8,
    },
    "span:last-of-type": {
      fontWeight: 500,
    },
  },
  "div:nth-of-type(2)": {
    borderRight: "1px solid #dbdbdb",
    borderLeft: "1px solid #dbdbdb",
    paddingLeft: 13,
    paddingRight: 13,
  },
}));

const SingleCard = styled(`div`)((props: { i: number }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  maxWidth: 68,
  width: "100%",
  minHeight: 72,
  borderRadius: 4,
  background:
    props.i === 1 || props.i === 2
      ? "#F2Faff"
      : props.i === 3
      ? "#eff8f7"
      : "#fffbe8",
  "span:first-of-type": {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "20px",
    width: "70%",
    textAlign: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  "span:last-of-type": {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: "24px",
    color:
      props.i === 1 || props.i === 2
        ? "#008fe2"
        : props.i === 3
        ? "#00a185"
        : "#ffb139",
  },
}));

export const SiteWeather = () => {
  return (
    <StyledPaper>
      <PaperHeader>
        <span>현장 날씨</span>
        <DashboardWeatherRefresh />
        <span>업데이트 2022.10.18 오전 10:10:15</span>
      </PaperHeader>
      <MiddleText>
        <span>10월20일 목요일</span>
        <span>강남구 삼성1동</span>
      </MiddleText>
      <IndicatorBox>
        <ArrowLeft />
        <div>
          <SunnyDay />
          <span>10</span>°C
        </div>
        <ArrowRight />
      </IndicatorBox>
      <IndicatorText>
        <div>
          <span>습도</span>
          <span>30%</span>
        </div>
        <div>
          <span>체감</span>
          <span>13.0°</span>
        </div>
        <div>
          <span>바람</span>
          <span>북서풍 2.5m/s</span>
        </div>
      </IndicatorText>
      <Grid container item lg={12} md={12} xs={12} spacing={1}>
        <Grid item lg={3} md={3} xs={3}>
          <SingleCard i={1}>
            <span>미세먼지</span>
            <span>좋음</span>
          </SingleCard>
        </Grid>
        <Grid item lg={3} md={3} xs={3}>
          <SingleCard i={2}>
            <span>초미세먼지</span>
            <span>좋음</span>
          </SingleCard>
        </Grid>
        <Grid item lg={3} md={3} xs={3}>
          <SingleCard i={3}>
            <span>자외선</span>
            <span>보통</span>
          </SingleCard>
        </Grid>
        <Grid item lg={3} md={3} xs={3}>
          <SingleCard i={4}>
            <span>일몰</span>
            <span>17:52</span>
          </SingleCard>
        </Grid>
      </Grid>
    </StyledPaper>
  );
};
