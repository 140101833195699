import React from "react";
import { Typography, Button, Grid } from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { colors } from "Context";
import { StyledButton } from "./Styles";

interface PurchaseProps {
  price: number;
  handleNavigateOrder(): void;
  handleOpen(): void;
}

export function Purchase(props: PurchaseProps) {
  const { price, handleNavigateOrder, handleOpen } = props;
  return (
    <Grid
      container
      sx={{
        pl: "2rem",
        pr: "3rem",
        pt: "2rem",
        pb: "20px",
        // mb: "2rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#FAFAFC",
        position: "fixed",
        bottom: 0,
        // width: "100%",
        width: { lg: "41.67%", xs: "100%" },
        zIndex: 0,
      }}
    >
      <Grid item lg={1.5} xs={6}>
        <Button
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <RestartAltIcon sx={{ color: colors.grey._60, mb: "10px" }} />
          <Typography
            sx={{
              fontSize: "14px",
              color: colors.grey._60,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            다시견적
          </Typography>
        </Button>
      </Grid>
      <Grid
        item
        lg={1.5}
        xs={6}
        sx={{
          whiteSpace: "nowrap",
          // overflow: "hidden",
          // textOverflow: "ellipsis",
        }}
      >
        <StyledButton
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#FAFAFC",
          }}
          onClick={handleNavigateOrder}
        >
          <ShoppingCartOutlinedIcon
            sx={{
              color: colors.grey._60,
              mb: "10px",
            }}
          />
          <Typography
            sx={{
              fontSize: "14px",
              color: colors.grey._60,
            }}
          >
            구매하기
          </Typography>
        </StyledButton>
      </Grid>
      <Grid
        item
        lg={3}
        xs={12}
        sx={{ pr: 1, pt: { lg: 0, xs: 2 }, pb: { lg: 0, xs: 2 } }}
      >
        <StyledButton
          variant="contained"
          startIcon={
            <CheckCircleOutlinedIcon sx={{ width: "26px", height: "26px" }} />
          }
          sx={{
            height: "72px",
            position: "static",
            width: "100%",
            fontSize: "16px",
            fontWeight: 400,
            background: colors.purple._60,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          onClick={handleOpen}
        >
          견적완료
        </StyledButton>
      </Grid>
      <Grid
        item
        lg={2}
        xs={6}
        sx={{
          pl: 1,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <Typography
          component="span"
          sx={{
            fontSize: "20px",
            fontWeight: 400,
            color: colors.grey._60,
          }}
        >
          총 비용은
        </Typography>
      </Grid>
      <Grid
        item
        lg={4}
        xs={6}
        style={{ display: "flex", alignItems: "center" }}
      >
        <Typography
          component="span"
          sx={{ fontSize: "24px", color: colors.purple._60 }}
        >
          {price.toLocaleString()}
        </Typography>
        <Typography
          component="span"
          sx={{ fontSize: "18px", color: colors.purple._60, pl: "2px" }}
        >
          원
        </Typography>
      </Grid>
    </Grid>
  );
}
