import React from "react";
import { Typography, Grid } from "@mui/material";
import { colors } from "Context";

interface NotifiCardProps {
  title: string;
  count: number;
}

export function NotifiCard(props: NotifiCardProps) {
  const { title, count } = props;
  return (
    <Grid item md={2.4} sm={3} xs={6} sx={{ mt: "1rem" }}>
      <Typography
        sx={{ fontSize: "1rem", fontWeight: 500, color: colors.grey._60 }}
      >
        {title}
      </Typography>
      <Typography
        component="span"
        sx={{ fontSize: "2rem", fontWeight: 700, color: colors.grey._100 }}
      >
        {count}
      </Typography>
      <Typography
        component="span"
        sx={{
          ml: "0.25rem",
          fontSize: "1rem",
          fontWeight: 500,
          color: colors.grey._60,
        }}
      >
        건
      </Typography>
    </Grid>
  );
}
