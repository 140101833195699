import React from "react";
import { Routes, Route } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { Layout } from "Components";
import { useScrollTop } from "hooks";
import { Main, Customer, Partner } from "Views";

function App() {
  useScrollTop();
  return (
    <Layout>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/customer/*" element={<Customer />} />
        <Route path="/partner/*" element={<Partner />} />
      </Routes>
    </Layout>
  );
}

export default App;
