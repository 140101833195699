import React from "react";
import { styled, Typography, Box } from "@mui/material";
import { PartnerListFooterBanner, PartnerListFooterBannerGt } from "Components";
import { colors } from "Context";

export const Banner = () => {
  return (
    <FooterDiv>
      <PartnerListFooterBanner style={{ minWidth: 64 }} />
      <Box
        sx={{
          display: "flex",
          alignItems: { md: "center", xs: "flex-start" },
          flexDirection: { md: "row", xs: "column" },
          whiteSpace: "nowrap",
        }}
      >
        <Typography
          component="div"
          sx={{
            fontSize: { lg: 24, md: 22, xs: 20 },
            fontWeight: 500,
            color: colors.alert._80,
            letterSpacing: -0.3,
            ml: { md: 2.5, xs: 1 },
          }}
        >
          마음에 드는 시공사가 없으세요?
        </Typography>
        <Typography
          component="div"
          sx={{
            fontSize: { lg: 20, md: 18, xs: 16 },
            fontWeight: 400,
            color: "#FF9A8B",
            letterSpacing: -0.3,
            ml: 1,
          }}
        >
          원하는 시공사를 찾아 직접 참여를 제안해보세요
          <PartnerListFooterBannerGt style={{ marginLeft: 8 }} />
        </Typography>
      </Box>
    </FooterDiv>
  );
};

const FooterDiv = styled(`div`)(() => ({
  display: "flex",
  // minWidth: 550,
  justifyContent: "center",
  alignItems: "center",
  background: "#FDEFF1",
  marginBottom: 114,
  width: "100%",
  height: 120,
  cursor: "pointer",
  padding: "0 5%",
}));
