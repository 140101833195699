import * as React from "react";
import { TextField, InputAdornment } from "@mui/material";
import { Search } from "Components";

export function CustomizedInputBase() {
  return (
    <TextField
      sx={{
        p: "5px 11px",
        flex: 1,
        width: { md: "90%", xs: "98%" },
        mb: { md: 0, xs: "1rem" },
        filter: "drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1))",
        borderRadius: "999px",
        "& .MuiOutlinedInput-root": {
          height: 42,
          fontSize: 14,
          fontWeight: 400,
          color: "#A0A3BD",
        },
        ".MuiOutlinedInput-notchedOutline": {
          border: " 1px solid #D9DBE9",
          borderRadius: 999,
        },
      }}
      placeholder="프로젝트 이름을 검색하세요."
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
    />
  );
}
