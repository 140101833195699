import React from "react";
import { NotiContainer } from "../Styles";
import {
  Typography,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Grid,
} from "@mui/material";
import moment from "moment";
import { colors } from "Context";
import { Reload } from "./Icons";
import { NotifiCard } from "./NotiCard";

enum ToggleValue {
  request = "request",
  payment = "payment",
}

export function Notification() {
  const [now, setNow] = React.useState("");
  const [alignment, setAlignment] = React.useState<ToggleValue>(
    ToggleValue.request
  );
  const handleButtonChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: ToggleValue
  ) => {
    if (!newAlignment) return;
    setAlignment(newAlignment);
  };
  const handleSetNow = React.useCallback(() => {
    moment.locale("kr", {
      weekdays: ["일", "월", "화", "수", "목", "금", "토"],
      meridiem: (hours) => (hours < 12 ? "오전" : "오후"),
    });
    const nthOfMonth = Math.ceil(moment().date() / 7);
    const nowString = `${moment().format("MM")}월 ${nthOfMonth}주 (${moment()
      .subtract(1, "weeks")
      .format("MM.DD")} - ${moment().format("MM.DD")})`;
    setNow(nowString);
  }, []);
  React.useEffect(() => {
    handleSetNow();
  }, [handleSetNow]);
  return (
    <NotiContainer>
      <Grid
        container
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          sm={6}
          xs={12}
        >
          <Typography
            sx={{
              fontSize: "1rem",
              fontWeight: 700,
              minWidth: "2rem",
            }}
          >
            알림
          </Typography>
          <Button
            size="small"
            sx={{
              ml: "12px",
              minWidth: 24,
              width: 24,
              height: 24,
              border: "1px solid #F2F2F2",
              boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
              borderRadius: "4px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={handleSetNow}
          >
            <Reload sx={{ ml: 1.5, mt: 1.5 }} />
          </Button>
          <Typography
            sx={{
              color: colors.grey._60,
              fontSize: 12,
              fontWeight: 400,
              ml: "12px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {now}
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: { sm: "flex-end", xs: "center" },
            alignItems: "center",
          }}
          sm={6}
          xs={12}
        >
          <ToggleButtonGroup
            color="primary"
            value={alignment}
            onChange={handleButtonChange}
            exclusive
            aria-label="Request and Payment"
            fullWidth
            sx={{
              width: { sm: "128px", xs: "100%" },
              mt: { sm: 0, xs: 1 },
              "& .Mui-selected": {
                color: "#FFFFFF !important",
                backgroundColor: `${colors.purple._60} !important`,
                "&:hover": {
                  color: "#FFFFFF",
                  backgroundColor: "#664AE3",
                },
              },
            }}
          >
            <ToggleButton
              value={ToggleValue.request}
              sx={{
                minWidth: "64px",
                height: "28px",
              }}
            >
              청구
            </ToggleButton>
            <ToggleButton
              value={ToggleValue.payment}
              sx={{ minWidth: "64px", height: "28px" }}
            >
              지급
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      <Grid container sx={{ mt: "1rem" }}>
        <NotifiCard title="승인 대기 중" count={2} />
        <NotifiCard title="승인 진행 중" count={2} />
        <NotifiCard title="승인 반려" count={1} />
        <NotifiCard title="지급 대기 중" count={4} />
        <NotifiCard title="지급 완료" count={12} />
      </Grid>
    </NotiContainer>
  );
}
