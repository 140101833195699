import * as React from "react";
import { SVGProps } from "react";

export const Period = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="29"
    height="28"
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <ellipse
      opacity="0.3"
      cx="14.1757"
      cy="14"
      rx="14.1757"
      ry="14"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.15901 7.90901C7.58097 7.48705 8.15326 7.25 8.75 7.25H19.25C19.8467 7.25 20.419 7.48705 20.841 7.90901C21.2629 8.33097 21.5 8.90326 21.5 9.5V10.625C21.5 11.0392 21.1642 11.375 20.75 11.375C20.3358 11.375 20 11.0392 20 10.625V9.5C20 9.30109 19.921 9.11032 19.7803 8.96967C19.6397 8.82902 19.4489 8.75 19.25 8.75H8.75C8.55109 8.75 8.36032 8.82902 8.21967 8.96967C8.07902 9.11032 8 9.30109 8 9.5V20C8 20.1989 8.07902 20.3897 8.21967 20.5303C8.36032 20.671 8.55109 20.75 8.75 20.75H11.375C11.7892 20.75 12.125 21.0858 12.125 21.5C12.125 21.9142 11.7892 22.25 11.375 22.25H8.75C8.15326 22.25 7.58097 22.0129 7.15901 21.591C6.73705 21.169 6.5 20.5967 6.5 20V9.5C6.5 8.90326 6.73705 8.33097 7.15901 7.90901Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 5.75C17.4142 5.75 17.75 6.08579 17.75 6.5V9.5C17.75 9.91421 17.4142 10.25 17 10.25C16.5858 10.25 16.25 9.91421 16.25 9.5V6.5C16.25 6.08579 16.5858 5.75 17 5.75Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 5.75C11.4142 5.75 11.75 6.08579 11.75 6.5V9.5C11.75 9.91421 11.4142 10.25 11 10.25C10.5858 10.25 10.25 9.91421 10.25 9.5V6.5C10.25 6.08579 10.5858 5.75 11 5.75Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 12.5C6.5 12.0858 6.83579 11.75 7.25 11.75H11C11.4142 11.75 11.75 12.0858 11.75 12.5C11.75 12.9142 11.4142 13.25 11 13.25H7.25C6.83579 13.25 6.5 12.9142 6.5 12.5Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 14.75C17.4142 14.75 17.75 15.0858 17.75 15.5V16.8362L18.6051 17.5488C18.9233 17.814 18.9663 18.2869 18.7012 18.6051C18.436 18.9233 17.9631 18.9663 17.6449 18.7012L16.5199 17.7637C16.3489 17.6212 16.25 17.4101 16.25 17.1875V15.5C16.25 15.0858 16.5858 14.75 17 14.75Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 13.25C16.0054 13.25 15.0516 13.6451 14.3483 14.3483C13.6451 15.0516 13.25 16.0054 13.25 17C13.25 17.9946 13.6451 18.9484 14.3483 19.6517C15.0516 20.3549 16.0054 20.75 17 20.75C17.9946 20.75 18.9484 20.3549 19.6517 19.6517C20.3549 18.9484 20.75 17.9946 20.75 17C20.75 16.0054 20.3549 15.0516 19.6517 14.3483C18.9484 13.6451 17.9946 13.25 17 13.25ZM13.2877 13.2877C14.2723 12.3031 15.6076 11.75 17 11.75C18.3924 11.75 19.7277 12.3031 20.7123 13.2877C21.6969 14.2723 22.25 15.6076 22.25 17C22.25 18.3924 21.6969 19.7277 20.7123 20.7123C19.7277 21.6969 18.3924 22.25 17 22.25C15.6076 22.25 14.2723 21.6969 13.2877 20.7123C12.3031 19.7277 11.75 18.3924 11.75 17C11.75 15.6076 12.3031 14.2723 13.2877 13.2877Z"
      fill="white"
    />
  </svg>
);
