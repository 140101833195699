import React from "react";
import { Backdrop, Button, Typography } from "@mui/material";
import { TutorialMaster, TutorialArrow } from "Components";
import { colors } from "Context";
import { TypingMultiline } from "react-kr-typing-anim";
import { ResponsiveTutorialBox } from "Components/Icons/ResponsiveTutorialBox";

interface PropType {
  open: boolean;
  handleNext(): void;
}

export const Tutorial = (props: PropType) => {
  const { open, handleNext } = props;
  const [state, setState] = React.useState({
    nextBtn: false,
    second: false,
    confirmBtn: false,
  });
  const handleNextBtnActive = () => {
    setState({ ...state, nextBtn: true });
  };
  const handleSecondTutorialActive = () => {
    setState({ ...state, second: true });
  };
  const handleConfirmBtnActive = () => {
    setState({ ...state, confirmBtn: true });
  };
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        background: "rgba(37, 37, 37, 0.9)",
      }}
      open={open}
    >
      <div>
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "1rem",
              paddingRight: "1rem",
            }}
          >
            <TutorialMaster style={{ minWidth: 80 }} />
            <div style={{ position: "relative", marginLeft: "2rem" }}>
              <ResponsiveTutorialBox>
                <div
                  style={{
                    width: "100%",
                    // minWidth: 678,
                    top: 46,
                    left: 59,
                    fontSize: 18,
                    fontWeight: 500,
                    letterSpacing: -0.3,
                    paddingLeft: 24,
                    paddingRight: 24,
                  }}
                >
                  {open ? (
                    <TypingMultiline
                      Tag="div"
                      postDelay={700}
                      speed={30}
                      cursor={false}
                      fixedWidth
                      onDone={handleNextBtnActive}
                      strs={`짝짝짝 👏👏 잘 하셨습니다!
                    진행 중인 공사 내용을 빠짐없이 리포팅해 주셨어요!`}
                    />
                  ) : null}
                  <div
                    style={{
                      display: state.nextBtn ? "flex" : "none",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      onClick={handleSecondTutorialActive}
                      disableRipple
                      sx={{
                        color: colors.white,
                        fontSize: 12,
                        fontWeight: 400,
                        letterSpacing: -0.3,
                        textShadow: `0px 0px 10px #EDEDED`,
                        height: 16,
                        mt: 1,
                      }}
                    >
                      다음
                    </Button>
                  </div>
                </div>
              </ResponsiveTutorialBox>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "27%",
            }}
          >
            <TutorialArrow />
            <Typography
              sx={{ ml: 2, fontWeight: 400, fontSize: 12, letterSpacing: -0.3 }}
            >
              다음 버튼을 눌러 다음 페이지를 확인하세요.
            </Typography>
          </div>
        </div>
        <div
          style={{
            display: state.second ? "flex" : "none",
            flexDirection: "column",
            marginTop: 50,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "1rem",
              paddingRight: "1rem",
            }}
          >
            <TutorialMaster style={{ minWidth: 80 }} />
            <div style={{ position: "relative", marginLeft: "2rem" }}>
              {/* <TutorialBox /> */}
              <ResponsiveTutorialBox>
                <div
                  style={{
                    width: "100%",
                    // minWidth: 678,
                    top: 46,
                    left: 59,
                    fontSize: 18,
                    fontWeight: 500,
                    letterSpacing: -0.3,
                    paddingLeft: 24,
                    paddingRight: 24,
                  }}
                >
                  {state.second ? (
                    <TypingMultiline
                      Tag="div"
                      postDelay={700}
                      speed={30}
                      cursor={false}
                      fixedWidth
                      onDone={handleConfirmBtnActive}
                      strs={`입력하신 공사 현황을 토대로 스마트한 기성금 관리도 가능합니다.
                    기성금 관리는 어떻게 이루어지고 있는지 확인해 보러 갈까요?`}
                    />
                  ) : null}
                  <div
                    style={{
                      display: state.confirmBtn ? "flex" : "none",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      onClick={handleNext}
                      disableRipple
                      sx={{
                        color: colors.white,
                        fontSize: 12,
                        fontWeight: 400,
                        letterSpacing: -0.3,
                        textShadow: `0px 0px 10px #EDEDED`,
                        height: 16,
                        mt: 1,
                      }}
                    >
                      확인
                    </Button>
                  </div>
                </div>
              </ResponsiveTutorialBox>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "27%",
            }}
          >
            <TutorialArrow />
            <Typography
              sx={{ ml: 2, fontWeight: 400, fontSize: 12, letterSpacing: -0.3 }}
            >
              확인 버튼을 눌러 다음 페이지를 확인하세요.
            </Typography>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};
