import * as React from "react";
import { SVGProps } from "react";

export const BuildingIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.834 6.334h.916a.916.916 0 1 0 0-1.833h-.916a.917.917 0 0 0 0 1.833Zm0 3.667h.916a.917.917 0 0 0 0-1.834h-.916a.917.917 0 1 0 0 1.834ZM7.25 6.334h.917a.917.917 0 1 0 0-1.833H7.25a.917.917 0 0 0 0 1.833Zm0 3.667h.917a.917.917 0 0 0 0-1.834H7.25a.917.917 0 1 0 0 1.834Zm11 7.333h-.916V1.751a.917.917 0 0 0-.917-.917H3.583a.917.917 0 0 0-.916.917v15.583H1.75a.917.917 0 0 0 0 1.833h16.5a.917.917 0 0 0 0-1.833Zm-7.333 0H9.084v-3.667h1.833v3.667Zm4.583 0h-2.75v-4.583a.917.917 0 0 0-.916-.917H8.167a.917.917 0 0 0-.917.917v4.583H4.5V2.667h11v14.667Z"
      fill="#DBDBDB"
    />
  </svg>
);
