import React from "react";
import location from "./location.png";
import { BuildingIcon } from "./BuildingIcon";
import {
  Box,
  IconButton,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  styled,
} from "@mui/material";
import { colors } from "Context";

export function Location() {
  return (
    <Box sx={{ width: "100%", position: "relative", height: "auto" }}>
      <img src={location} alt="building" width="100%" />
      <LocationPin />
    </Box>
  );
}

function LocationPin() {
  return (
    <Box
      sx={{
        position: "absolute",
        width: "200px",
        height: "200px",
        backgroundColor: "rgba(102, 73, 226, 0.2)",
        borderRadius: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        left: "60%",
        top: "8%",
      }}
    >
      <CustomTooltip
        title="정확한 공사 위치는 참여 신청 완료 후에 확인가능해요"
        arrow
        placement="top"
      >
        <IconButton
          sx={{
            backgroundColor: colors.purple._60,
            width: "36px",
            height: "36px",
          }}
        >
          <BuildingIcon sx={{ ml: "6px", mt: "4px" }} />
        </IconButton>
      </CustomTooltip>
    </Box>
  );
}

export function CustomTooltip(props: TooltipProps) {
  const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      "&:before": {
        border: `1px solid #FCFCFC`,
        boxShadow:
          "2px 2px 6px rgba(102, 73, 226, 0.1), 0px 0px 2px rgba(102, 73, 226, 0.1)",
      },
      color: "#ffffff",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      color: colors.grey._60,
      backgroundColor: "#FCFCFC",
      minHeight: 40,
      boxShadow:
        "2px 2px 6px rgba(102, 73, 226, 0.1), 0px 0px 2px rgba(102, 73, 226, 0.1)",
      display: "flex",
      alignItems: "center",
      fontSize: "12px",
    },
  }));
  return <CustomTooltip {...props} />;
}
