import {
  OrderCreditcard,
  OrderKakaopay,
  OrderWithoutBankbook,
} from "Components";
import OrderSelectCard from "Components/OrderSelectCard.tsx";
import OrderSelectPayMonth from "Components/OrderSelectPayMonth";
import React from "react";
import {
  Column,
  Head,
  StyledPaymentInfoButton,
  Row,
  Wrap,
  UnselectedPaymentInfoTitle,
  SelectedPaymentInfoTitle,
} from "../Styles";

export const PaymentInfo = (props: {
  handlePaymentInfoButtonColor:
    | React.MouseEventHandler<HTMLButtonElement>
    | undefined;
  selected: string;
}) => {
  return (
    <Wrap>
      <Head sx={{ mb: 4, pb: 2, borderBottom: "1px solid #D9D9D9" }}>
        결제정보
      </Head>

      <Row sx={{ maxWidth: 710, justifyContent: "space-between" }}>
        <StyledPaymentInfoButton
          id="creditcard"
          onClick={props.handlePaymentInfoButtonColor}
          variant="outlined"
        >
          <Column sx={{ alignItems: "center" }}>
            <OrderCreditcard
              fill={props.selected ? "#664AE3" : "rgba(168, 167, 175, 1)"}
            />
            {props.selected === "creditcard" ? (
              <SelectedPaymentInfoTitle>
                신용/체크 카드
              </SelectedPaymentInfoTitle>
            ) : (
              <UnselectedPaymentInfoTitle>
                신용/체크 카드
              </UnselectedPaymentInfoTitle>
            )}
          </Column>
        </StyledPaymentInfoButton>

        <StyledPaymentInfoButton
          id="withoutBankbook"
          onClick={props.handlePaymentInfoButtonColor}
          variant="outlined"
        >
          <Column sx={{ alignItems: "center" }}>
            <OrderWithoutBankbook
              stroke={props.selected ? "#664AE3" : "rgba(168, 167, 175, 1)"}
            />
            {props.selected === "withoutBankbook" ? (
              <SelectedPaymentInfoTitle>무통장 입금</SelectedPaymentInfoTitle>
            ) : (
              <UnselectedPaymentInfoTitle>
                무통장 입금
              </UnselectedPaymentInfoTitle>
            )}
          </Column>
        </StyledPaymentInfoButton>

        <StyledPaymentInfoButton
          id="kakaopay"
          onClick={props.handlePaymentInfoButtonColor}
          variant="outlined"
        >
          <Column sx={{ alignItems: "center" }}>
            <OrderKakaopay />
            {props.selected === "kakaopay" ? (
              <SelectedPaymentInfoTitle>카카오페이</SelectedPaymentInfoTitle>
            ) : (
              <UnselectedPaymentInfoTitle>
                카카오페이
              </UnselectedPaymentInfoTitle>
            )}
          </Column>
        </StyledPaymentInfoButton>
      </Row>

      <OrderSelectCard />
      <OrderSelectPayMonth />
    </Wrap>
  );
};
