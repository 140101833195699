import React from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
} from "@mui/material";
import { createData } from "./createData";
import { StyledTableRow, CustomHeadTableCell, CustomTableCell } from "./Styles";
import { useAppSelector } from "store";

const rows = [
  createData(
    "층간소음재 설치 다시해헤후",
    "100*100/콘크리트",
    "EAUIED",
    15000,
    1200000,
    "Wall"
  ),
  createData(
    "목조조천틀/기준층",
    "80*100/콘크리트입니다킄",
    "EA",
    4200,
    560000,
    "BW"
  ),
  createData("몰딩/콘크리트", "40*6600/알루미늄", "EA", 84, 1560000, "Slab"),
  createData(
    "외장용 고밀도 목재 패널",
    "100*100/콘크리트",
    "EA",
    32,
    890000,
    "Beam"
  ),
  createData(
    "층간소음재 설치 다시해",
    "1000*100/철근",
    "EA",
    8777,
    1560000,
    "Slab"
  ),
  createData(
    "외장용 고밀도 목재 패널2",
    "40*6600/알루미늄",
    "EA",
    100,
    743000,
    "Slab"
  ),
  createData("몰딩", "1000*100/철근", "EA", 9000, 43000, "Slab"),
];

export const GlowingTableRow = styled(StyledTableRow)(() => ({
  "@keyframes blink": {
    "0%": { filter: "drop-shadow(0px 0px 20px rgba(102, 74, 227, 0.7))" },
    "50%": { filter: "none" },
    "100%": { filter: "drop-shadow(0px 0px 20px rgba(102, 74, 227, 0.7))" },
  },
  animation: "blink 1.5s linear infinite",
  background: "white",

  // "&:hover": {
  //   boxShadow: "0px 0px 16px rgba(102, 74, 227, 0.24)",
  //   animation: "none",
  // },
  // borderRadius: "8px",
}));

export function Finishing({ material }: { material: boolean }) {
  const { viewer3D } = useAppSelector((state) => state.forge);
  const handleClick = React.useCallback(
    (keyword: string) => {
      if (!viewer3D) return;
      viewer3D.search(
        keyword,
        function onSuccess(result: any) {
          // console.log({ result });
          viewer3D.hideAll();
          viewer3D.show([result]);
          viewer3D.select(result);
        },
        function onError(error: any) {
          console.log({ error });
        },
        []
      );
    },
    [viewer3D]
  );
  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow:
          "2px 2px 6px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.05);",
        borderRadius: "12px",
        overflow: { lg: "visible", xs: "auto" },
      }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <CustomHeadTableCell>품목</CustomHeadTableCell>
            <CustomHeadTableCell>규격</CustomHeadTableCell>
            <CustomHeadTableCell>단위</CustomHeadTableCell>
            <CustomHeadTableCell>수량</CustomHeadTableCell>
            <CustomHeadTableCell>단가</CustomHeadTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            const { id, name, keyword } = row;
            return (
              <FinishingRow
                {...row}
                key={id}
                handleClick={() => handleClick(keyword)}
                glowing={name === "외장용 고밀도 목재 패널" && material}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

interface FinishingRowProps {
  id: string;
  name: string;
  standard: string;
  unit: string;
  amount: number;
  price: number;
  handleClick(): void;
  glowing?: boolean;
}

function FinishingRow(props: FinishingRowProps) {
  const { id, name, standard, unit, amount, price, handleClick, glowing } =
    props;
  return (
    <>
      {glowing && (
        <GlowingTableRow
          key={id}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
          onClick={handleClick}
        >
          <CustomTableCell component="th" scope="row">
            {glowing ? "시멘트 사이딩 외장 블록" : name}
          </CustomTableCell>
          <CustomTableCell>
            {glowing ? "100*100/시멘트" : standard}
          </CustomTableCell>
          <CustomTableCell>{unit}</CustomTableCell>
          <CustomTableCell>{amount.toLocaleString()}</CustomTableCell>
          <CustomTableCell align="right">
            ₩{(price + (glowing ? 886000 : 0)).toLocaleString()}
          </CustomTableCell>
        </GlowingTableRow>
      )}
      {!glowing && (
        <StyledTableRow
          key={id}
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          onClick={handleClick}
        >
          <CustomTableCell component="th" scope="row">
            {name}
          </CustomTableCell>
          <CustomTableCell>{standard}</CustomTableCell>
          <CustomTableCell>{unit}</CustomTableCell>
          <CustomTableCell>{amount.toLocaleString()}</CustomTableCell>
          <CustomTableCell align="right">
            ₩{price.toLocaleString()}
          </CustomTableCell>
        </StyledTableRow>
      )}
    </>
  );
}
