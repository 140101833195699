import * as React from "react";
import { SVGProps } from "react";

export const BudgetIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9 0C4.032 0 0 4.032 0 9s4.032 9 9 9 9-4.032 9-9-4.032-9-9-9Zm1.269 14.481v.522c0 .657-.54 1.197-1.197 1.197h-.009c-.657 0-1.197-.54-1.197-1.197v-.54c-1.197-.252-2.259-.909-2.709-2.016-.207-.495.18-1.044.72-1.044h.216c.333 0 .603.225.729.54.261.675.945 1.143 2.259 1.143 1.764 0 2.16-.882 2.16-1.431 0-.747-.396-1.449-2.403-1.926-2.232-.54-3.762-1.458-3.762-3.303 0-1.548 1.251-2.556 2.799-2.889v-.54c0-.657.54-1.197 1.197-1.197h.009c.657 0 1.197.54 1.197 1.197v.558c1.242.306 2.025 1.08 2.367 2.034.18.495-.198 1.017-.729 1.017h-.234c-.333 0-.603-.234-.693-.558-.207-.684-.774-1.125-1.908-1.125-1.35 0-2.16.612-2.16 1.476 0 .756.585 1.251 2.403 1.719 1.818.468 3.762 1.251 3.762 3.519-.018 1.647-1.251 2.547-2.817 2.844Z"
      fill="#D4D3D7"
    />
  </svg>
);
