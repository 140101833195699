import { Grid, styled, Button, Typography } from "@mui/material";
import { colors } from "Context";

export const Wrap = styled(`div`)(() => ({
  display: "flex",
  justifyContent: "center",
}));

export const Wrapper = styled(Grid)(() => ({
  marginTop: 63,
  borderRadius: 999,
  alignItems: "center",
  maxWidth: 944,
}));
export const TitleGrid = styled(Grid)(() => ({
  borderRadius: 999,
  boxShadow: `4px 0px 4px rgba(171, 141, 255, 0.2)`,
  padding: "10px 16px 10px 36px",
  display: "flex",
  alignItems: "flex-start",
  flexDirection: "column",
  justifyContent: "center",
}));
export const ContentGrid = styled(Grid)(() => ({
  alignItems: "center",
  borderRadius: 999,
  padding: "10px 36px",
}));
export const NameTypo = styled(Typography)(() => ({
  fontWeight: 500,
  fontSize: 16,
  letterSpacing: -0.3,
  color: colors.grey._60,
}));

export const AddressTypo = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: 14,
  color: colors.grey._40,
}));

export const FlexRow = styled(`div`)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
}));
export const FlexColumn = styled(`div`)(() => ({
  display: "flex",
  flexDirection: "column",
}));
export const FlexAlignCenter = styled(`div`)(() => ({
  display: "flex",
  alignItems: "center",
}));

export const Title = styled(Typography)(() => ({
  fontWeight: 400,
  color: colors.grey._40,
  fontSize: 14,
}));
export const Content = styled(Typography)(() => ({
  fontWeight: 500,
  color: colors.grey._60,
  fontSize: 14,
}));

export const EditBtn = styled(Button)(() => ({
  borderRadius: 999,
  backgroundColor: colors.grey._10,
  height: 35,
  width: 77,
  fontSize: 16,
  color: colors.grey._60,
  fontWeight: 500,
  whiteSpace: "nowrap",
  boxShadow: "none",
}));
