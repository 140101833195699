import React from "react";
import { CurationDialog } from "Components";
import { useNavigate } from "react-router-dom";
import { Tutorial } from "./Tutorial";
import { MaterialSlide } from "./MaterialSlide";
import { Purchase } from "./Purchase";

interface MaterialProps {
  material: boolean;
  handleClickMaterial(): void;
}

export function Materials(props: MaterialProps) {
  const { material, handleClickMaterial } = props;
  const [open, setOpen] = React.useState(false);
  const [tutorialOpen, setTutorial] = React.useState(false);
  const navigate = useNavigate();
  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);
  const handleDownload = () => {
    // console.log("Download PDF");
    setOpen(false);
  };
  const handleMatching = () => {
    navigate("/customer/matching");
  };
  const handleTutorialOpen = () => {
    setOpen(false);
    setTutorial(true);
  };
  const handleTutorialClose = () => {
    setTutorial(false);
  };
  const handleNavigateOrder = () => {
    navigate("/customer/order");
  };
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Tutorial
        open={tutorialOpen}
        handleTutorialClose={handleTutorialClose}
        handleMatching={handleMatching}
      />
      <CurationDialog
        open={open}
        handleClose={handleClose}
        handleDownload={handleDownload}
        handleMatching={handleTutorialOpen}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          width: "100%",
        }}
      >
        <MaterialSlide handleClickMaterial={handleClickMaterial} />
        <Purchase
          price={material ? 21500886000 : 21500000000}
          handleNavigateOrder={handleNavigateOrder}
          handleOpen={handleOpen}
        />
      </div>
    </>
  );
}
