import { styled, Grid, Tab, Tabs, Typography } from "@mui/material";
import { colors } from "Context";

export const Wrap = styled(`div`)(() => ({
  display: "flex",
  justifyContent: "center",
  marginBottom: 83,
  background:
    "linear-gradient(360deg, rgba(235, 231, 253, 0.3) 9.8%, rgba(235, 231, 253, 0) 95.93%)",
}));
export const TabGrid = styled(Grid)(() => ({
  maxWidth: 1300,
  marginTop: 54,
  marginBottom: 40,
}));
export const TabDiv = styled(`div`)(() => ({
  width: "100%",
}));

export const CustomTabs = styled(Tabs)(() => ({
  "& .MuiTabs-indicator": {
    backgroundColor: colors.grey._100,
    height: 3,
  },
}));
export const CustomTab = styled(Tab)(() => ({
  fontSize: 16,
  fontWeight: 500,
  letterSpacing: -0.3,
  color: colors.grey._60,
  "&.Mui-selected": {
    color: colors.grey._100,
  },
}));

export const TitleDiv = styled(`div`)(
  (props: { color: string; background: string }) => ({
    height: 48,
    background: props.background,
    borderRadius: 8,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    color: props.color,
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: -0.3,
    paddingLeft: 16,
  })
);

export const ParterInfoBox = styled(`div`)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: 9999,
  boxShadow: "2px 2px 6px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.05)",
  padding: "12px 20px",
  marginTop: 16,
  marginBottom: 12,
  background: colors.white,
}));
export const RankDiv = styled(`div`)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

export const IconDiv = styled(`div`)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  paddingLeft: 10,
}));
export const TitleInfoDiv = styled(`div`)(() => ({
  display: "flex",
  flexDirection: "column",
  paddingLeft: 4,
}));

export const NameTypo = styled(Typography)(() => ({
  fontWeight: 500,
  fontSize: 14,
  letterSpacing: -0.3,
  color: colors.grey._100,
}));
export const InfoTypo = styled(Typography)(() => ({
  fontSize: 12,
  letterSpacing: -0.3,
  color: colors.grey._40,
}));
export const ContentDiv = styled(`div`)(() => ({
  display: "flex",
  alignItems: "center",
}));
export const ContentTypo = styled(Typography)(() => ({
  fontWeight: 500,
  fontSize: 14,
  letterSpacing: -0.3,
  color: colors.grey._100,
}));
export const UnitTypo = styled(Typography)(() => ({
  fontSize: 12,
  letterSpacing: -0.3,
  color: colors.grey._100,
  marginLeft: 2,
}));
