import React from "react";
import { ProjectInfo } from "./ProjectInfo";
import { Tab } from "./Tab";
import { PartnerList } from "./List";
import { Banner } from "./Banner";
import { Wrap, Container } from "./Styles";
import { PropType, projectInfo } from "./Types";

export const MachtingPartner = (props: PropType) => {
  const { handleTutorialOpen } = props;
  return (
    <Wrap>
      <Container>
        <ProjectInfo info={projectInfo} />
        <Tab />
        <PartnerList handleTutorialOpen={handleTutorialOpen} />
        <Banner />
      </Container>
    </Wrap>
  );
};
