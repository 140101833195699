import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { OrderBack } from "Components";
import { colors } from "Context";
import {
  Column,
  Detail,
  PaymentHead,
  Row,
  StyledBackButton,
  StyledPayButton,
  Title,
  Wrap,
} from "../Styles";

export const Payment = () => {
  const navigate = useNavigate();
  return (
    <Wrap sx={{ mt: 1 }}>
      <Wrap sx={{ p: 3, backgroundColor: "#FCFCFC" }}>
        <Title sx={{ mb: 2 }}>결제금액 상세</Title>

        <Grid container sx={{ pb: 2, borderBottom: "1px solid #D9D9D9" }}>
          <Grid item md={5} sm={12} xs={5}>
            <Title sx={{ color: colors.grey._60 }}>총 자재 금액</Title>
          </Grid>
          <Grid
            item
            md={7}
            sm={12}
            xs={7}
            sx={{
              textAlign: { md: "right", sm: "left", xs: "right" },
              mb: { xs: 1, md: 0 },
            }}
          >
            <Title sx={{ fontWeight: 400 }}>20,405,500원</Title>
          </Grid>
          <Grid item md={5} sm={12} xs={5}>
            <Title sx={{ color: colors.grey._60 }}>배송비</Title>
          </Grid>
          <Grid
            item
            md={7}
            sm={12}
            xs={7}
            sx={{ textAlign: { md: "right", sm: "left", xs: "right" } }}
          >
            <Title sx={{ fontWeight: 400 }}>500,000원</Title>
          </Grid>
        </Grid>

        <Grid container sx={{ mt: 2, mb: 3 }}>
          <Grid item md={6} sm={12} xs={6}>
            <PaymentHead>최종 결제 금액</PaymentHead>
          </Grid>
          <Grid
            item
            md={6}
            sm={12}
            xs={6}
            sx={{
              textAlign: { md: "right", sm: "left", xs: "right" },
              mb: { xs: 1, md: 0 },
            }}
          >
            <PaymentHead sx={{ fontWeight: 500, color: colors.purple._60 }}>
              20,905,500원
            </PaymentHead>
          </Grid>
        </Grid>

        <Column sx={{ mb: 3 }}>
          <Detail sx={{ color: colors.grey._60, fontWeight: 500, pb: 1 }}>
            주문 내용을 확인하였으며, 정보 제공 등에 동의합니다.
          </Detail>
          <Row sx={{ justifyContent: "left" }}>
            <Detail>개인정보 수집 및 제3자 제공 동의&nbsp;</Detail>
            <Detail
              sx={{
                textDecoration: "underline",
                textUnderlinePosition: "under",
                cursor: "pointer",
              }}
            >
              (보기)
            </Detail>
          </Row>
          <Row sx={{ justifyContent: "left" }}>
            <Detail>결제대행 서비스 이용약관 동의&nbsp;</Detail>
            <Detail
              sx={{
                textDecoration: "underline",
                textUnderlinePosition: "under",
                cursor: "pointer",
              }}
            >
              (보기)
            </Detail>
          </Row>
        </Column>

        <StyledPayButton variant="contained">
          <Grid container columnSpacing={1}>
            <Grid
              item
              md={7}
              sm={12}
              xs={7}
              sx={{
                textAlign: { md: "right", sm: "center", xs: "right" },
                fontSize: { md: 18, sm: 16 },
              }}
            >
              20,905,500원
            </Grid>
            <Grid
              item
              md={5}
              sm={12}
              xs={5}
              sx={{
                textAlign: { md: "left", sm: "center", xs: "left" },
                fontSize: { md: 18, sm: 16 },
              }}
            >
              결제하기
            </Grid>
          </Grid>
        </StyledPayButton>
      </Wrap>
      <StyledBackButton
        startIcon={<OrderBack />}
        sx={{ fontSize: { md: 18, sm: 14 } }}
        onClick={() => {
          navigate("/customer");
        }}
      >
        이전으로 돌아가기
      </StyledBackButton>
    </Wrap>
  );
};
