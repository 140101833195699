import {
  Wrap,
  MainTypo,
  PageContainer,
  TitleDiv,
  CardContainer,
  SubTypo,
  SingleCard,
  FilterContainer,
} from "./Styles";
import { ProjectCard } from "Components";
import { Grid } from "@mui/material";
import { CustomizedInputBase } from "./Search";
import {
  LocaleSelect,
  BudgetSelect,
  BuildingTypeSelect,
  AvailablitySelect,
} from "./SelectBox";
import { Selected } from "./Selected";
import { useNavigate } from "react-router-dom";

export const ProjectList = () => {
  const navigate = useNavigate();
  const handleParticipate = (id: number) => {
    navigate(`/partner/constructor-bidding/${id}`);
  };
  return (
    <Wrap>
      <PageContainer container>
        <TitleDiv>
          <MainTypo>당신의 참여를 기다리고 있는 프로젝트</MainTypo>
          <SubTypo>
            원하는 프로젝트가 있다면 지금 바로 참여 신청을 해보세요. 고민하다가
            놓칠지도 몰라요.
          </SubTypo>
        </TitleDiv>
        <FilterContainer container>
          <Grid item md={6} xs={12}>
            <CustomizedInputBase />
          </Grid>
          <Grid item md={1} sm={2} xs={6}>
            <LocaleSelect />
          </Grid>
          <Grid item md={1} sm={2} xs={6}>
            <BudgetSelect />
          </Grid>
          <Grid item md={2} sm={4} xs={6}>
            <BuildingTypeSelect />
          </Grid>
          <Grid item md={2} sm={4} xs={6}>
            <AvailablitySelect />
          </Grid>
        </FilterContainer>
        <Selected />
        <CardContainer container item lg={12} md={12} xs={12} spacing={2}>
          {PROJECT_DATA.map((item) => {
            return (
              <SingleCard
                item
                lg={3}
                md={6}
                xs={12}
                key={item.id}
                onClick={
                  item.id === 1 || item.id === 2
                    ? () => handleParticipate(item.id)
                    : () => {}
                }
              >
                <ProjectCard
                  id={item.id}
                  title={item.title}
                  location={item.location}
                  form={item.form}
                  area={item.area}
                  budget={item.budget}
                  isValid={item.isValid}
                  handleParticipate={handleParticipate}
                />
              </SingleCard>
            );
          })}
        </CardContainer>
      </PageContainer>
    </Wrap>
  );
};

export const PROJECT_DATA = [
  {
    id: 1,
    title: "신사동 비트라 카페",
    description: "현재 3개 시공사 참여 희망",
    location: "서울특별시 강남구 신사동",
    form: "상가 주택",
    area: "232",
    budget: "10억",
    isValid: true,
  },
  {
    id: 2,
    title: "창소프트 재건축 프로젝트",
    description: "현재 24개 시공사 참여 희망",
    location: "서울시 강남구 삼성동",
    form: "4층 상가주택",
    area: "273",
    budget: "31억",
    isValid: true,
  },
  {
    id: 3,
    title: "동해 선셋 팬션",
    description: "파트너 선택 완료",
    location: "강원 동해시 부곡동",
    form: "숙박 시설",
    area: "345",
    budget: "16억",
    isValid: false,
  },
  {
    id: 4,
    title: "위너스하우스",
    description: "21개 시공사 참여 완료",
    location: "서울 강북구 번동",
    form: "단독주택",
    area: "526",
    budget: "49억",
    isValid: false,
  },
];
