import { styled, Grid, Typography } from "@mui/material";
import { colors } from "Context";

export const Wrap = styled(`div`)(() => ({
  minWidth: "100vw",
  minHeight: "100vh",
  paddingTop: 44,
  paddingBottom: 44,
  display: "flex",
  justifyContent: "center",
}));

export const PageContainer = styled(Grid)(() => ({
  maxWidth: 1400,
  paddingLeft: "2rem",
  paddingRight: "2rem",
  width: "100%",
  height: "100%",
}));

export const TitleDiv = styled(`div`)(() => ({}));

export const FilterContainer = styled(Grid)(() => ({
  width: "100%",
  marginTop: 66,
  marginBottom: 20,
}));

export const CardContainer = styled(Grid)(() => ({
  width: "100%",
  margin: 0,
  padding: 0,
  marginBottom: 106,
}));

export const SingleCard = styled(Grid)(() => ({
  width: "100%",
  paddingRight: "1rem",
  // border: "1px solid purple",
  display: "flex",
  justifyContent: "center",
}));

export const MainTypo = styled(Typography)(() => ({
  fontSize: 20,
  fontWeight: 500,
  letterSpacing: -0.3,
  color: colors.grey._100,
}));
export const SubTypo = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 400,
  letterSpacing: -0.3,
  color: colors.grey._60,
  marginTop: 4,
}));
