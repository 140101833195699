import * as React from "react";
import { SVGProps } from "react";

export const OrderWithoutBankbook = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="35"
    height="30"
    viewBox="0 0 35 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.6 14.9996V7.99981H4.575C3.62685 7.99981 2.71754 7.63107 2.04709 6.97471C1.37665 6.31835 1 5.42814 1 4.4999C1 2.57496 2.60875 1 4.575 1H26.025V7.99981"
      // stroke="#A8A7AF"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 4.23242V25.4626C1 27.4087 2.60875 29.001 4.575 29.001H29.6V21.9242"
      // stroke="#A8A7AF"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.6667 15C25.6942 15 24.7616 15.3404 24.0739 15.9462C23.3863 16.5521 23 17.3739 23 18.2307C23 20.0076 24.65 21.4614 26.6667 21.4614H34V15H26.6667Z"
      // stroke="#A8A7AF"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
