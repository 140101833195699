import React from "react";
import {
  styled,
  Button,
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material";
import { colors } from "Context";
import { CurationIcon } from "Components";

interface PropType {
  open: boolean;
  handleClose(): void;
  handleDownload(): void;
  handleMatching(): void;
}

export const CurationDialog = (props: PropType) => {
  const { open, handleClose, handleDownload, handleMatching } = props;
  const [hover, setHover] = React.useState<boolean>(false);
  return (
    <div>
      <CustomDialog open={open} onClose={handleClose}>
        <DialogContent>
          <IconDiv>
            잠깐!
            <CurationIcon />
          </IconDiv>

          <TitleTypo>
            완성한 견적서로
            <br />
            시공 파트너를 만나보시겠어요?
          </TitleTypo>
          <SubTypo>
            안심 건축을 지향하는 374곳의
            <br />
            시공 파트너들이 기다리고 있어요!
          </SubTypo>
          <ButtonDiv>
            <Button
              variant="contained"
              sx={{ background: colors.grey._30 }}
              onClick={handleDownload}
            >
              견적서 다운로드
            </Button>
            <CustomBtn
              variant="contained"
              hover={hover.toString()}
              onMouseOver={() => setHover(true)}
              onMouseOut={() => setHover(false)}
              onClick={handleMatching}
            >
              시공 파트너 매칭
            </CustomBtn>
          </ButtonDiv>
        </DialogContent>
      </CustomDialog>
    </div>
  );
};
const CustomBtn = styled(Button)((props: { hover: string }) => ({
  background: colors.purple._60,
  "@keyframes blink": {
    "0%": { filter: "drop-shadow(0px 0px 20px rgba(102, 74, 227, 0.7))" },
    "50%": { filter: "none" },
    "100%": { filter: "drop-shadow(0px 0px 20px rgba(102, 74, 227, 0.7))" },
  },
  animation: props.hover === "true" ? "none" : "blink 2s linear infinite",
  width: 218,
}));

const CustomDialog = styled(Dialog)(() => ({
  ".MuiDialog-paper": {
    minWidth: 386,
    width: 386,
    borderRadius: 20,
    background: colors.white,
  },
  ".MuiDialogContent-root": {
    padding: "38px 36px 42px 36px",
  },
  marginLeft: 1,
}));
const IconDiv = styled(`div`)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: 51,
  color: "#F5E664",
  fontSize: 16,
  fontWeight: 500,
  svg: {
    marginTop: 11,
  },
}));
const TitleTypo = styled(Typography)(() => ({
  fontSize: 20,
  fontWeight: 500,
  letterSpacing: -0.3,
  color: colors.grey._100,
  textAlign: "center",
  marginBottom: 12,
}));
const SubTypo = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 400,
  letterSpacing: -0.3,
  color: colors.grey._40,
  textAlign: "center",
}));
const ButtonDiv = styled(`div`)(() => ({
  marginTop: 66,
  display: "flex",
  justifyContent: "space-between",
  button: {
    width: 150,
    height: 44,
    color: colors.white,
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: -0.3,
    borderRadius: 4,
  },
}));
