import React from "react";
import { Typography, Divider, Box } from "@mui/material";
import { Star, FavoriteIcon } from "Components";
import { Ability, Completion, Period } from "Components/Partner";
import {
  CustomBox,
  ImageDiv,
  InfoDiv,
  IconDiv,
  NameTypo,
  TagTypo,
  ReviewDiv,
  Footer,
  TitleTypo,
  AmountTypo,
  Btn,
  HoverDiv,
  FavoriteBox,
  PartnerInfoBox,
  InfoBox,
  HoverBtn,
  ItemWrap,
  ItemDiv,
  FilterDiv,
} from "./Styles";
import { PropType, ItemPropType } from "./Types";

export const PartnerCard = (props: PropType) => {
  const {
    info: {
      image,
      icon,
      name,
      tag,
      rate,
      review,
      amount,
      abilityEvaluation,
      completion,
      period,
    },
    handleTutorialOpen,
  } = props;
  const [active, setActive] = React.useState(false);
  const handleActive = () => {
    setActive(true);
  };
  const handleUnActive = () => {
    setActive(false);
  };

  return (
    <CustomBox onMouseOver={handleActive} onMouseOut={handleUnActive}>
      <FilterDiv
        active={active.toString()}
        blinkActive={(name === "나선 종합건설" && !active).toString()}
      >
        <ImageDiv>{image}</ImageDiv>
        <InfoDiv>
          {active ? null : <IconDiv>{icon}</IconDiv>}
          <Box sx={{ visibility: active ? "hidden" : "visible" }}>
            <div>
              <NameTypo>{name}</NameTypo>
              <TagTypo>
                {tag.map((item) => {
                  return <span key={item}>{item}</span>;
                })}
              </TagTypo>
              <ReviewDiv>
                <Star />
                <Typography sx={{ ml: 0.25 }}>{rate}</Typography>
                <Typography sx={{ ml: 1 }}>리뷰 {review}</Typography>
              </ReviewDiv>
            </div>
            <Divider sx={{ m: "20px 0" }} />
            <Footer>
              <div>
                <TitleTypo>총 공사비</TitleTypo>
                <AmountTypo>
                  {amount.toLocaleString()}
                  <span>원</span>
                </AmountTypo>
              </div>
              <Btn variant="contained">선정하기</Btn>
            </Footer>
          </Box>
        </InfoDiv>
      </FilterDiv>
      {active ? (
        <HoverDiv>
          <FavoriteBox>
            <FavoriteIcon style={{ cursor: "pointer" }} />
          </FavoriteBox>
          <PartnerInfoBox>
            {icon}
            <Typography>{name}</Typography>
          </PartnerInfoBox>
          <InfoBox>
            <Item
              icon={<Ability />}
              title="시공능력평가"
              content={`토건 상위 ${abilityEvaluation}%`}
            />
            <Item
              icon={<Completion />}
              title="1년간 시공건수"
              content={`${completion}건 (준공완료)`}
            />
            <Item icon={<Period />} title="공사기간" content={period} />
          </InfoBox>
          <HoverBtn variant="contained" onClick={handleTutorialOpen}>
            선정하기
          </HoverBtn>
        </HoverDiv>
      ) : null}
    </CustomBox>
  );
};

const Item = (props: ItemPropType) => {
  const { icon, title, content } = props;
  return (
    <ItemWrap>
      <ItemDiv>
        {icon}
        <span>{title}</span>
      </ItemDiv>
      {content}
    </ItemWrap>
  );
};
