import React from "react";
import { Backdrop, Grid, styled } from "@mui/material";
import {
  TutorialMaster,
  TutorialSelectRoleBox,
  RoleClient,
  RolePartner,
} from "Components";
import { TypingMultiline } from "react-kr-typing-anim";
import { useNavigate } from "react-router-dom";
import { ResponsiveTutorialBox } from "Components/Icons/ResponsiveTutorialBox";

interface PropType {
  open: boolean;
  handleTutorialClose(): void;
}

export const Tutorial = (props: PropType) => {
  const navigate = useNavigate();
  const { open, handleTutorialClose } = props;
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        background: "rgba(37, 37, 37, 0.9)",
      }}
      open={open}
      onClick={handleTutorialClose}
    >
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingLeft: "1rem",
            paddingRight: "1rem",
          }}
        >
          <TutorialMaster style={{ minWidth: 80 }} />
          <div style={{ position: "relative", marginLeft: "2rem" }}>
            {/* <TutorialBox /> */}
            <ResponsiveTutorialBox>
              <div
                style={{
                  width: "100%",
                  // minWidth: 612,
                  top: 46,
                  left: 59,
                  fontSize: 18,
                  fontWeight: 500,
                  letterSpacing: -0.3,
                  paddingLeft: 24,
                  paddingRight: 24,
                }}
              >
                {open ? (
                  <TypingMultiline
                    Tag="div"
                    postDelay={700}
                    speed={30}
                    cursor={false}
                    fixedWidth
                    strs={`👏👏 클라이언트를 위한 데모 페이지를 모두 둘러 보셨습니다.
                  아래의 버튼을 눌러 체험하고 싶은 페이지를 선택해 주세요.`}
                  />
                ) : null}
              </div>
            </ResponsiveTutorialBox>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: "20%",
          }}
        >
          <BoxGrid item onClick={() => navigate("/customer")}>
            <TutorialSelectRoleBox
              icon={<RoleClient />}
              title="클라이언트"
              sub="건축주, 설계사"
            />
          </BoxGrid>

          <BoxGrid item onClick={() => navigate("/partner")}>
            <TutorialSelectRoleBox
              icon={<RolePartner />}
              title="파트너"
              sub="시공사, 시행사, 전문건설사"
            />
          </BoxGrid>
        </div>
      </div>
    </Backdrop>
  );
};

const BoxGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  maxWidth: 285,
  width: "100%",
  background: "rgba(255, 255, 255, 0.25)",
  marginRight: 10,
  marginLeft: 10,
  borderRadius: 12,
}));
