import * as React from "react";
import { Tabs, Box, Grid } from "@mui/material";
import img from "./img.png";
import { colors } from "Context";
import {
  Board,
  BodyTypo,
  DetailTypo,
  ProjectPercentTypo,
  ProjectStatusProgress,
  ProjectStatusTab,
  Row,
  SubtitleTypo,
  TitleTypo,
} from "./Styles";

export const ProjectStatus = () => {
  const [value, setValue] = React.useState(2);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Board sx={{ p: 0 }}>
      <div style={{ borderBottom: "1px solid #DBDBDB" }}>
        <TitleTypo style={{ padding: "24px 24px 8px 24px" }}>
          프로젝트별 현황
        </TitleTypo>
        <Box sx={{ width: "100%", minWidth: { xs: 100, sm: 400 } }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            <ProjectStatusTab label="입찰 참여 중 (3건)" />
            <ProjectStatusTab label="진행 대기(계약) 중 (0건)" />
            <ProjectStatusTab label="공사 진행 중 (3건)" />
            <ProjectStatusTab label="공사 완료 (0건)" />
          </Tabs>
        </Box>
      </div>

      <div style={{ padding: "20px 24px 28px 24px" }}>
        <SubtitleTypo style={{ paddingBottom: 19 }}>공사 개요</SubtitleTypo>
        <Grid container spacing={3}>
          <Grid item md={7} xs={12} sx={{ p: 0 }}>
            <Grid container>
              <GridItems
                title="프로젝트 이름"
                content={<BodyTypo>창소프트 재건축 프로젝트</BodyTypo>}
              />
              <GridItems
                title="공사 위치"
                content={
                  <BodyTypo>서울시 강남구 테헤란로 103길 8-7 (삼성동)</BodyTypo>
                }
              />
              <GridItems
                title="공사 면적"
                content={<BodyTypo>273㎡</BodyTypo>}
              />
              <GridItems
                title="건물 유형"
                content={<BodyTypo>4층 상가주택</BodyTypo>}
              />
              <GridItems
                title="공사 시작일"
                content={<BodyTypo>2022.09.02</BodyTypo>}
              />
              <GridItems
                title="공사 종료일(예정일)"
                content={
                  <Row style={{ justifyContent: "start" }}>
                    <BodyTypo>2023.02.28</BodyTypo>
                    <BodyTypo
                      style={{
                        color: colors.purple._60,
                        fontWeight: 700,
                        paddingLeft: 5,
                      }}
                    >
                      [D-238]
                    </BodyTypo>
                  </Row>
                }
              />
              <GridItems
                title="현장 소장"
                content={
                  <Row style={{ justifyContent: "start" }}>
                    <BodyTypo>조정현</BodyTypo>
                    <BodyTypo style={{ padding: "0 7px" }}>|</BodyTypo>
                    <BodyTypo>010 1020 3040</BodyTypo>
                  </Row>
                }
              />
            </Grid>

            <Row>
              <SubtitleTypo>공사 진행률</SubtitleTypo>
              <ProjectPercentTypo style={{ color: colors.purple._60 }}>
                55.3%
              </ProjectPercentTypo>
            </Row>
            <ProjectStatusProgress
              variant="determinate"
              value={55.3}
              sx={{ width: "100%" }}
            />
          </Grid>

          <Grid
            item
            md={5}
            xs={12}
            sx={{
              margin: { md: 0, sm: "0 80px 0 80px", xs: 0 },
            }}
          >
            {/* <DashboardProjectStatusImage /> */}
            <img src={img} alt="field" width="100%" />
          </Grid>
        </Grid>
      </div>

      <Row style={{ paddingBottom: 28, width: 144, margin: "auto" }}>
        <DetailTypo style={{ cursor: "pointer" }}>&lt;</DetailTypo>
        <DetailTypo style={{ color: colors.purple._60, cursor: "pointer" }}>
          1
        </DetailTypo>
        <DetailTypo style={{ cursor: "pointer" }}>2</DetailTypo>
        <DetailTypo style={{ cursor: "pointer" }}>3</DetailTypo>
        <DetailTypo style={{ cursor: "pointer" }}>&gt;</DetailTypo>
      </Row>
    </Board>
  );
};

interface GridItemsProps {
  title: string;
  content: React.ReactNode;
}

function GridItems(gridItemProps: GridItemsProps) {
  const { title, content } = gridItemProps;
  return (
    <>
      <Grid item sx={{ paddingBottom: { xs: 0, sm: 2 } }} sm={3} xs={12}>
        <BodyTypo style={{ color: colors.grey._60 }}>{title}</BodyTypo>
      </Grid>
      <Grid item sm={9} xs={12} sx={{ paddingBottom: { xs: 2, sm: 0 } }}>
        {content}
      </Grid>
    </>
  );
}
