import React from "react";
import {
  Wrap,
  Title,
  PageContainer,
  CompletePercentPaper,
  SubTitle,
  FB,
} from "./Styles";
import { Grid } from "@mui/material";
import {
  Modeling,
  ConsCalendar,
  BudgetGraph,
  AccumGraph,
  DailySiteReport,
  ProgressBall,
  ArrowHeadDown,
  LastTutorialMaster,
} from "Components";
import { Tutorial } from "./Tutorial";
import moment from "moment";

export function ConstructionStatus() {
  const [open, setOpen] = React.useState(false);
  const handleTutorialOpen = () => {
    setOpen(true);
  };
  const handleTutorialClose = () => {
    setOpen(false);
  };
  const completeDay = moment("2023-08-28", "YYYY-MM-DD");
  const [now, setNow] = React.useState("");
  const [dday, setDday] = React.useState(0);
  const handleSetNow = React.useCallback(() => {
    moment.locale("kr", {
      weekdays: ["일", "월", "화", "수", "목", "금", "토"],
      meridiem: (hours) => (hours < 12 ? "오전" : "오후"),
    });
    const nowString = moment().format("YYYY.MM.DD(dddd) A hh:mm");
    const days = moment().diff(completeDay, "days");
    setNow(nowString);
    setDday(days);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    handleSetNow();
  }, [handleSetNow]);
  return (
    <React.Suspense>
      <Wrap>
        <Tutorial open={open} handleTutorialClose={handleTutorialClose} />
        <FB onClick={handleTutorialOpen}>
          <LastTutorialMaster />
        </FB>
        <PageContainer container spacing={2}>
          <Grid container item lg={8.1} xs={12} spacing={2}>
            <Grid item lg={12} xs={12} sx={{ minHeight: "48px" }}>
              <div
                style={{
                  height: "100%",
                  maxHeight: 48,
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span>
                    <Title>창소프트 재건축 프로젝트</Title>
                  </span>
                  <span style={{ paddingBottom: 10, paddingLeft: 6.7 }}>
                    <Title>
                      <ArrowHeadDown />
                    </Title>
                  </span>
                </div>
                <SubTitle>서울특별시 강남구 삼성동 169-5</SubTitle>
              </div>
            </Grid>
            <Grid lg={12} item xs={12}>
              <Modeling
                completeDay={completeDay.format("YYYY.MM.DD")}
                dday={Math.abs(dday)}
              />
            </Grid>
            <Grid item md={7.4} xs={12}>
              <BudgetGraph />
            </Grid>
            <Grid item md={4.6} xs={12}>
              <AccumGraph />
            </Grid>
          </Grid>
          <Grid container item lg={3.9} xs={12} rowSpacing={2}>
            <Grid item lg={12} xs={12}>
              <CompletePercentPaper sx={{ maxWidth: { lg: 456, xs: "100%" } }}>
                <div>
                  <p>현재까지 공사 진행률</p>
                  <p>{now} </p>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ProgressBall />
                </div>
              </CompletePercentPaper>
            </Grid>

            <Grid item lg={12} xs={12}>
              <ConsCalendar />
            </Grid>

            <Grid item lg={12} xs={12}>
              <DailySiteReport />
            </Grid>
          </Grid>
        </PageContainer>
      </Wrap>
    </React.Suspense>
  );
}
