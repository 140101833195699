import * as React from "react";
import { SVGProps } from "react";

export const Search = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.0831 13.3341H14.1614L13.8348 13.0191C15.2348 11.3857 15.9581 9.1574 15.5614 6.78906C15.0131 3.54573 12.3064 0.95573 9.03975 0.559063C4.10475 -0.0476034 -0.04858 4.10573 0.558087 9.04073C0.954753 12.3074 3.54475 15.0141 6.78809 15.5624C9.15642 15.9591 11.3848 15.2357 13.0181 13.8357L13.3331 14.1624V15.0841L18.2914 20.0424C18.7698 20.5207 19.5514 20.5207 20.0298 20.0424C20.5081 19.5641 20.5081 18.7824 20.0298 18.3041L15.0831 13.3341ZM8.08309 13.3341C5.17809 13.3341 2.83309 10.9891 2.83309 8.08406C2.83309 5.17906 5.17809 2.83406 8.08309 2.83406C10.9881 2.83406 13.3331 5.17906 13.3331 8.08406C13.3331 10.9891 10.9881 13.3341 8.08309 13.3341Z"
      fill="#A0A3BD"
    />
  </svg>
);
