import React from "react";
import { Grid } from "@mui/material";
import {
  Wrap,
  Left,
  DemoDiv,
  TypoDiv,
  BoxGrid,
  MainTypo,
  SubTypo,
  BoxExplainTypo,
  CharacterDiv,
  Right,
  Container,
  Title,
  ExampleDiv,
} from "./Styles";
import { example } from "./Types";
import { Character, SelectRoleBox, RoleClient, RolePartner } from "Components";
import { useNavigate } from "react-router-dom";

export const Main = () => {
  const navigate = useNavigate();
  return (
    <Wrap container>
      <Left container item md={7} xs={12}>
        <Grid item md={8} sx={{ mt: 12 }}>
          <DemoDiv>서비스 DEMO</DemoDiv>
          <TypoDiv>
            <MainTypo>빌더허브와 함께 안심건축을 시작해요.</MainTypo>
            <SubTypo>어떤 회원으로 로그인하시겠어요?</SubTypo>
          </TypoDiv>
          <Grid
            container
            item
            sx={{
              marginTop: 8,
              justifyContent: "center",
              alignItems: "center",
              flexDirection: {
                xs: "column",
                md: "row",
              },
            }}
            spacing={2}
          >
            <BoxGrid item onClick={() => navigate("/customer")}>
              <SelectRoleBox
                icon={<RoleClient />}
                title="클라이언트"
                sub="건축주, 설계사"
                onClick={() => {
                  navigate("/customer");
                }}
              />
              <BoxExplainTypo>
                빌더허브가 제공하고 있는
                <br />
                <span>서비스를 이용, 프로젝트를 의뢰</span>
                <br />
                하고 싶은 고객
              </BoxExplainTypo>
            </BoxGrid>
            <BoxGrid item sx={{ mt: { xs: 2, md: 0 } }}>
              <SelectRoleBox
                icon={<RolePartner />}
                title="파트너"
                sub="시공사, 시행사, 전문건설사"
                onClick={() => {
                  navigate("/partner");
                }}
              />
              <BoxExplainTypo>
                빌더허브가 제공하고 있는
                <br />
                <span>서비스에 참여, 프로젝트를 수주</span>
                <br />
                하고 싶은 전문가
              </BoxExplainTypo>
            </BoxGrid>
          </Grid>
        </Grid>
        <CharacterDiv>
          <Character />
        </CharacterDiv>
      </Left>
      <Right item md={5} xs={12}>
        <Container>
          <Title>
            이런 경험 있으신가요?
            <br />
            이럴 때 빌더허브에게 맡기세요!
          </Title>
          {example.map((item) => {
            return <ExampleDiv key={item}>{item}</ExampleDiv>;
          })}
        </Container>
      </Right>
    </Wrap>
  );
};
