import * as React from "react";
import {
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  Board,
  Row,
  TitleTypo,
  StyledTableCell,
  StyledTableRow,
  SubtitleTypo,
  DetailTypo,
} from "./Styles";
import {
  DashboardGraphPurple,
  DashboardGraphMint,
} from "Components/Icons/index";
import { PaymentProgressWithLabel } from "./PaymentProgress";
import DashboardSelectMonth from "Components/DashboardSelectMonth";
import { useNavigate } from "react-router-dom";
import { colors } from "Context";

function createData(
  name: string,
  billing: number,
  payment: number,
  balance: number
) {
  return { name, billing, payment, balance };
}

const rows = [
  createData("청구 금액", 24000000, 50000000, 74000000),
  createData("지급 금액(-)", 12000000, 25000000, 37000000),
  createData("청구 잔액", 12000000, 25000000, 37000000),
];

export const PaymentStatus = () => {
  const navigate = useNavigate();
  return (
    <Board
      sx={{
        pt: 0,
        "&:hover": {
          outline: `2px solid ${colors.purple._40}`,
          outlineOffset: 1,
          boxShadow: "4px 4px 8px rgba(60, 44, 135, 0.2)",
          cursor: "pointer",
        },
      }}
      onClick={() => navigate("/partner/manage-payments")}
    >
      <Row>
        <TitleTypo>기성금 지급 현황</TitleTypo>
        <DashboardSelectMonth />
      </Row>

      <TableContainer>
        <Table sx={{ minWidth: 408, mb: 5 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell align="right">거래처</StyledTableCell>
              <StyledTableCell align="right">현장</StyledTableCell>
              <StyledTableCell align="right">합계</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  {row.name}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.billing.toLocaleString()}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.payment.toLocaleString()}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.balance.toLocaleString()}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid container style={{ width: "100%" }}>
        <Grid item lg={7} md={9} sm={8} xs={12}>
          <SubtitleTypo
            style={{
              width: "100%",
            }}
          >
            10월 누적 기성금 지급율
          </SubtitleTypo>
        </Grid>
        <Grid item lg={5} md={3} sm={4} xs={12} container spacing={3}>
          <Grid item lg={2}>
            <DashboardGraphPurple />
          </Grid>
          <Grid item lg={4}>
            <DetailTypo style={{ fontWeight: 700 }}>거래처</DetailTypo>
          </Grid>
          <Grid item lg={2}>
            <DashboardGraphMint />
          </Grid>
          <Grid item lg={4}>
            <DetailTypo style={{ fontWeight: 700 }}>현장</DetailTypo>
          </Grid>
        </Grid>
      </Grid>
      <PaymentProgressWithLabel value={40} />
    </Board>
  );
};
