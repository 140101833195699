import { Grid } from "@mui/material";
import {
  DashboardWeather,
  DashboardWeatherRefresh,
} from "Components/Icons/index";
import { colors } from "Context";
import { DetailTypo, Temperature, WeatherWrap } from "./Styles";

export const Weather = () => {
  return (
    <WeatherWrap
      container
      alignItems="center"
      sx={{
        padding: { sm: "16px", xs: "10px" },
        position: "relative",
      }}
    >
      <Grid
        item
        sm={3}
        xs={4}
        container
        alignItems="center"
        sx={{ minWidth: 90 }}
      >
        <DashboardWeather />
        <Temperature sx={{ ml: { lg: 0, md: 1 } }}>10</Temperature>
        <DetailTypo sx={{ color: colors.grey._100, placeSelf: "end", pb: 1 }}>
          °C
        </DetailTypo>
      </Grid>

      <Grid item sm={8} xs={7} container>
        <Grid item lg={8} sm={6} xs={12} container sx={{ minWidth: 134 }}>
          <Grid
            item
            xs={5}
            container
            sx={{ justifyContent: { lg: "left", xs: "space-around" } }}
          >
            <DetailTypo sx={{ color: colors.grey._40 }}>습도&nbsp;</DetailTypo>
            <DetailTypo>48%</DetailTypo>
          </Grid>
          <Grid
            item
            xs={7}
            container
            sx={{ justifyContent: { lg: "left", xs: "space-around" } }}
          >
            <DetailTypo sx={{ color: colors.grey._40 }}>
              강수확률&nbsp;
            </DetailTypo>
            <DetailTypo>0%</DetailTypo>
          </Grid>
          <Grid
            item
            xs={5}
            container
            sx={{ justifyContent: { lg: "left", xs: "space-around" } }}
          >
            <DetailTypo sx={{ color: colors.grey._40 }}>풍속&nbsp;</DetailTypo>
            <DetailTypo>2m/s</DetailTypo>
          </Grid>
          <Grid
            item
            xs={7}
            container
            sx={{ justifyContent: { lg: "left", xs: "space-around" } }}
          >
            <DetailTypo sx={{ color: colors.grey._40 }}>
              미세먼지&nbsp;
            </DetailTypo>
            <DetailTypo>좋음</DetailTypo>
          </Grid>
        </Grid>

        <Grid item lg={4} sm={4} xs={12} container>
          <Grid
            item
            sm={12}
            xs={6}
            sx={{ textAlign: { sm: "center", xs: "right" } }}
          >
            <DetailTypo sx={{ color: colors.grey._100 }}>
              서울특별시&nbsp;
            </DetailTypo>
          </Grid>
          <Grid
            item
            sm={12}
            xs={6}
            sx={{ textAlign: { sm: "center", xs: "left" } }}
          >
            <DetailTypo sx={{ color: colors.grey._100 }}>
              강남구 삼성동
            </DetailTypo>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={1}>
        <DashboardWeatherRefresh
          style={{
            cursor: "pointer",
            position: "absolute",
            right: 10,
            top: 25,
          }}
        />
      </Grid>
    </WeatherWrap>
  );
};
