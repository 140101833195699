import React from "react";
import { Box } from "@mui/material";
import { MetaField } from "./MetaField";
import { Quotation } from "./Quotation";
import { Materials } from "./Materials";

export function Content() {
  const [material, setMaterial] = React.useState(false);
  const handleClickMaterial = () => {
    setMaterial(!material);
  };
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <MetaField />
      <Quotation material={material} />
      <Materials
        material={material}
        handleClickMaterial={handleClickMaterial}
      />
    </Box>
  );
}
