import { Paper, Button, styled } from "@mui/material";
import {
  ProjOne,
  ProjTwo,
  ProjThree,
  ProjFour,
  FormIcon,
  AreaIcon,
  BudgetIcon,
  BookMarkActive,
  BookMarkDe,
} from "Components";
import { colors } from "Context";

const StyledPaper = styled(Paper)((props: { which: number }) => ({
  height: "100%",
  width: "100%",
  background: "#FFFFFF",
  "@keyframes blink": {
    "0%": { filter: "drop-shadow(0px 0px 20px rgba(102, 74, 227, 0.7))" },
    "50%": { filter: "none" },
    "100%": { filter: "drop-shadow(0px 0px 20px rgba(102, 74, 227, 0.7))" },
  },
  animation: props.which === 2 ? "blink 1.5s linear infinite" : "none",

  "&:hover":
    props.which === 2
      ? {
          border: "2px solid #664ae3",
          boxShadow: "0px 0px 16px rgba(102, 74, 227, 0.24)",
          animation: "none",
        }
      : {},
  border: "1px solid #DBDBDB",
  boxShadow: "4px 4px 12px rgba(0, 0, 0, 0.05)",
  borderRadius: "12px",
  paddingBottom: 24,
}));

const CardHeader = styled(`div`)((props: { isValid: boolean }) => ({
  display: "flex",
  justifyContent: "space-between",

  padding: "24px 0 20px 0",
  marginLeft: 16,
  marginRight: 16,
  borderBottom: "1px solid #DBDBDB",

  "div: first-of-type": {
    width: "68px",
    background: props.isValid === true ? "#EBE7FD" : "#f2f2f2",
    borderRadius: "4px",
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    span: {
      fontSize: 12,
      fontWeight: 500,
      color: props.isValid === true ? "#664ae3" : "#a8a7af",
      lineHeight: "16px",
    },
  },
  "div: last-of-type": {
    span: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "16px",
    },
  },
}));

const CardBody = styled(`div`)(() => ({
  margin: 16,
  paddingBottom: 21,
  borderBottom: "1px solid #DBDBDB",
  "div: first-of-type": {
    marginBottom: 20,
    span: {
      fontWeight: 500,
      fontSize: 20,
      lineHeight: "24px",
    },
    p: {
      margin: 0,
      fontWeight: 400,
      fontSize: 12,
      lineHeight: "20px",
      color: "#7d7b88",
    },
  },
}));

const ProjDescBox = styled(`div`)(() => ({
  marginTop: 21,
  table: {
    tr: {
      "td:first-of-type": {
        display: "flex",
        alignItems: "center",
        span: {
          marginLeft: 9,
          fontSize: 14,
          lineHeight: "22px",
          fontWeight: 400,
          letterSpacing: -0.3,
          color: "#a8a7af",
        },
      },
      "td:last-of-type": {
        paddingLeft: 19,
        span: {
          fontWeight: 400,
          fontSize: 14,
          lineHeight: "22px",
          letterSpacing: -0.3,
        },
      },
    },
  },
}));

const CardFooter = styled(`div`)(() => ({
  display: "flex",
  alignItems: "center",
  marginLeft: 24,
}));

const FooterButton = styled(Button)(() => ({
  width: "75%",
  height: "40px",
  // background: props.isValid === true ? "#664ae3" : "#f2f2f2",
  borderRadius: "8px",
  border: "none",
  fontWeight: 500,
  fontSize: 16,
  lineHeight: "20px",
  // color: props.isValid === true ? "#ffffff" : "#a8a7af",
  letterSpacing: -0.3,
  marginLeft: 25,
  ":disabled": {
    background: colors.grey._10,
    color: colors.grey._40,
  },
}));

interface Props {
  id: number;
  title: string;
  location: string;
  form: string;
  area: string;
  budget: string;
  isValid: boolean;
  handleParticipate(id: number): void;
}
export const ProjectCard = ({
  id,
  title,
  location,
  form,
  area,
  budget,
  isValid,
  handleParticipate,
}: Props) => {
  return (
    <StyledPaper
      sx={{
        maxWidth: { lg: "338px", sx: "100%" },
        // minWidth: { lg: "338px", sx: "100%" },
        // maxWidth: "338px",
        width: "100%",

        minHeight: { lg: "546px", xs: "100%" },
        maxHeight: { lg: "546px", xs: "100%" },
      }}
      which={id}
    >
      <CardHeader isValid={isValid}>
        <div>
          <span>참여{isValid === true ? "가능" : "불가"}</span>
        </div>
        <div>
          {id === 1 && (
            <span style={{ color: colors.grey._40 }}>
              현재 <span style={{ color: colors.purple._60 }}>3개 </span>시공사
              참여 희망
            </span>
          )}
          {id === 2 && (
            <span style={{ color: colors.grey._40 }}>
              현재 <span style={{ color: colors.purple._60 }}>24개 </span>시공사
              참여 희망
            </span>
          )}
          {id === 3 && (
            <span style={{ color: colors.grey._40 }}>파트너 선택 완료</span>
          )}
          {id === 4 && (
            <span style={{ color: colors.grey._40 }}>
              <span style={{ color: colors.purple._60 }}>21개 </span>시공사 참여
              완료
            </span>
          )}
        </div>
      </CardHeader>
      <CardBody>
        <div>
          <span>{title}</span>
          <p>{location}</p>
        </div>
        <div>
          {id === 1 && <ProjOne />}
          {id === 2 && <ProjTwo />}
          {id === 3 && <ProjThree />}
          {id === 4 && <ProjFour />}
        </div>
        <ProjDescBox>
          <table>
            <tbody>
              <tr>
                <td>
                  <FormIcon />
                  <span>유형</span>
                </td>
                <td>{form}</td>
              </tr>
              <tr>
                <td>
                  <AreaIcon />
                  <span>공사면적</span>
                </td>
                <td>{area}m²</td>
              </tr>
              <tr>
                <td>
                  <BudgetIcon />
                  <span>예산</span>
                </td>
                <td>{budget}</td>
              </tr>
            </tbody>
          </table>
        </ProjDescBox>
      </CardBody>
      <CardFooter>
        {isValid === true ? <BookMarkActive /> : <BookMarkDe />}
        <FooterButton
          variant="contained"
          color="primary"
          disabled={!isValid}
          onClick={() => handleParticipate(id)}
          // isValid={isValid}
        >
          참여 신청하기
        </FooterButton>
      </CardFooter>
    </StyledPaper>
  );
};
