import * as React from "react";
import { SVGProps } from "react";

export const HomeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={46}
    height={46}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.2}
      d="M0 4a4 4 0 0 1 4-4h38a4 4 0 0 1 4 4v38a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4Z"
      fill="#FFB7B7"
    />
    <g clipPath="url(#aHomeIcon)">
      <path
        d="M19.478 32.12h6.044v-7.698c0-.486-.315-.803-.796-.803h-4.441c-.492 0-.807.317-.807.803v7.699Zm-4.095 1.09h14.17c1.488 0 2.357-.856 2.357-2.337V19.188l-1.686-1.164V30.44c0 .688-.366 1.069-1.026 1.069H15.739c-.67 0-1.036-.381-1.036-1.069V18.035l-1.687 1.153v11.685c0 1.48.87 2.337 2.367 2.337ZM10 21.44c0 .434.335.846.9.846.294 0 .535-.158.755-.338l10.484-8.883c.23-.212.513-.212.743 0l10.484 8.883c.21.18.45.338.744.338.492 0 .89-.306.89-.814a.868.868 0 0 0-.325-.72L23.773 11.5c-.776-.667-1.74-.667-2.525 0l-10.913 9.253a.888.888 0 0 0-.335.687Zm19.282-4.77 2.628 2.243v-4.886c0-.465-.293-.761-.754-.761h-1.12c-.45 0-.755.296-.755.761v2.644Z"
        fill="#FF5036"
      />
    </g>
    <defs>
      <clipPath id="aHomeIcon">
        <path fill="#fff" transform="translate(10 11)" d="M0 0h25v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
