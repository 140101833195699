import { createAsyncThunk } from "@reduxjs/toolkit";
import "./extensions";

const uri = "https://api.demo.platform.builderhub.io/forge_token";

async function api<T>(url: string): Promise<T> {
  return fetch(url).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json() as Promise<T>;
  });
}
export const forgeViewerInitializeThunk = createAsyncThunk(
  "forge/viewerInit",
  forgeInitialize
);

export const forgeViewerPnPInitThunk = createAsyncThunk(
  "forge/viewerPnPInit",
  forgePnPInit
);

type ForgeTokenPayload = {
  access_token: string;
  token_type: "Bearer";
  expires_in: number;
  expires_at: number;
};

/**
 * It gets the current user's session, gets the JWT token from the session, creates a GraphQL client
 * with the JWT token as the authorization header, gets the Forge SDK from the client, and then gets
 * the Forge token from the SDK
 * @returns The token is being returned.
 */
async function getForgeTokenData() {
  const token = await api<ForgeTokenPayload>(uri);
  return token;
}

interface ForgeInitializerParams {
  el: HTMLDivElement;
  urn: string;
}

interface ForgeIntializerResolvedValue {
  /**
   * The Autodesk.Viewing.Document loaded document
   */
  doc: Autodesk.Viewing.Document;
  /**
   * The Forge token data
   */
  tokenData: {
    __typename?: "ForgeToken" | undefined;
    access_token: string;
    token_type: string;
    expires_in: number;
  };
  /**
   * Forge Viewer
   */
  viewer: Autodesk.Viewing.GuiViewer3D;
}

/**
 * It initializes the Forge viewer, loads the model, and returns the viewer, the model, and the Forge
 * token data
 * @param {ForgeInitializerParams}  - `el` - the DOM element where the viewer will be rendered
 * @returns An object with the following properties:
 * - doc: The loaded document
 * - tokenData: The token data
 * - viewer: The viewer
 */
async function forgeInitialize({
  el,
  urn,
}: ForgeInitializerParams): Promise<ForgeIntializerResolvedValue> {
  const tokenData = await getForgeTokenData();
  const options: Autodesk.Viewing.InitializerOptions = {
    env: "AutodeskProduction",
    api: "derivativeV2",
    getAccessToken(callback?) {
      getForgeTokenData().then((token) => {
        const { access_token, expires_in } = token;
        if (callback) {
          callback(access_token, expires_in);
        }
      });
    },
  };
  const viewer = await forgeViewerInit(options, el, urn);
  viewer.start();
  const doc = await forgeViewrLoad(urn);
  const defaultModel = doc.getRoot().getDefaultGeometry();
  viewer.loadDocumentNode(doc, defaultModel);
  return { doc, tokenData, viewer };
}

/**
 * It returns a promise that resolves to a viewer object
 * @param options - Autodesk.Viewing.InitializerOptions
 * @param {HTMLDivElement} el - HTMLDivElement - the div element that will contain the viewer
 * @param {string} urn - The URN of the model you want to load.
 * @returns A promise that resolves to a viewer object.
 */
async function forgeViewerInit(
  options: Autodesk.Viewing.InitializerOptions,
  el: HTMLDivElement,
  urn: string
): Promise<Autodesk.Viewing.GuiViewer3D> {
  return new Promise((resolve, reject) => {
    Autodesk.Viewing.Initializer(options, async function onInitialized() {
      const viewer = new Autodesk.Viewing.GuiViewer3D(el);
      await viewer.loadExtension("Autodesk.Viewing.Popout");
      await viewer.loadExtension("Autodesk.ModelStructure");
      resolve(viewer);
    });
  });
}

interface ForgePnPIntializerResolvedValue {
  three: ForgeIntializerResolvedValue;
  two: ForgeIntializerResolvedValue;
}

async function forgePnPInit(initProps: {
  el3D: HTMLDivElement;
  el2D: HTMLDivElement;
  urn3D: string;
  urn2D: string;
}): Promise<ForgePnPIntializerResolvedValue> {
  const { el3D, el2D, urn3D, urn2D } = initProps;
  const tokenData = await getForgeTokenData();
  const options: Autodesk.Viewing.InitializerOptions = {
    env: "AutodeskProduction",
    api: "derivativeV2",
    getAccessToken(callback?) {
      getForgeTokenData().then((token) => {
        const { access_token, expires_in } = token;
        if (callback) {
          callback(access_token, expires_in);
        }
      });
    },
  };
  const { viewer2D, viewer3D } = await forgeViewerPnPInit(options, el3D, el2D);
  viewer2D.start();
  viewer3D.start();
  const doc3D = await forgeViewrLoad(urn3D);
  const model3D = doc3D.getRoot().getDefaultGeometry();
  viewer3D.loadDocumentNode(doc3D, model3D);
  const doc2D = await forgeViewrLoad(urn2D);
  const model2D = doc2D.getRoot().getDefaultGeometry();
  viewer2D.loadDocumentNode(doc2D, model2D);
  return {
    three: { doc: doc3D, tokenData, viewer: viewer3D },
    two: { doc: doc2D, tokenData, viewer: viewer2D },
  };
}

async function forgeViewerPnPInit(
  options: Autodesk.Viewing.InitializerOptions,
  el3D: HTMLDivElement,
  el2D: HTMLDivElement
): Promise<{
  viewer3D: Autodesk.Viewing.GuiViewer3D;
  viewer2D: Autodesk.Viewing.GuiViewer3D;
}> {
  return new Promise((resolve, reject) => {
    Autodesk.Viewing.Initializer(options, async function onInitialized() {
      const viewer3D = new Autodesk.Viewing.GuiViewer3D(el3D);
      const viewer2D = new Autodesk.Viewing.GuiViewer3D(el2D);
      // await viewer3D.loadExtension("MyAwesomeExtension");
      await viewer3D.loadExtension("Autodesk.Viewing.Popout");
      await viewer3D.loadExtension("Autodesk.ModelStructure");
      const extensions =
        Autodesk.Viewing.theExtensionManager.getRegisteredExtensions();
      console.log({ extensions });
      viewer2D.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, () => {
        viewer2D.toolbar.setVisible(false);
      });
      resolve({ viewer2D, viewer3D });
    });
  });
}

/**
 * It loads a Forge model from a URN and returns a promise that resolves to a Forge document
 * @param {string} urn - The URN of the model you want to load.
 * @returns A promise that resolves to a document object.
 */
async function forgeViewrLoad(urn: string): Promise<Autodesk.Viewing.Document> {
  return new Promise((resolve, reject) => {
    Autodesk.Viewing.Document.load(
      urn,
      function onDocumentLoadSuccess(doc) {
        resolve(doc);
      },
      function onDocumentLoadFailure(error) {
        console.log({ error });
        reject(error);
      }
    );
  });
}
