import { configureStore, Middleware } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { forgeSlice } from "./features";
import logger from "redux-logger";
export * from "./features";

const middlewares = (): Middleware[] => {
  if (process.env.NODE_ENV !== "production") {
    return [];
  } else {
    return [logger];
  }
};

export const store = configureStore({
  reducer: {
    forge: forgeSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middlewares()),
  devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

/* Exporting the useDispatch hook from react-redux and giving it a type. */
export const useAppDispatch: () => AppDispatch = useDispatch;
/* Exporting the useSelector hook from react-redux and giving it a type. */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
