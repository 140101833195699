import {
  Body,
  Head,
  StyledMenuItem,
  StyledTab,
  StyledTabPanel,
  StyledTabsList,
  Title,
  Wrap,
} from "../Styles";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import { colors } from "Context";
import * as React from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

export const Address = () => {
  const [comment, setComment] = React.useState("");
  const handleChange = (event: SelectChangeEvent) => {
    setComment(event.target.value as string);
  };

  return (
    <Wrap>
      <Head sx={{ pb: 2 }}>배송지 (공사 현장 주소)</Head>
      <TabsUnstyled defaultValue={0}>
        <StyledTabsList>
          <StyledTab>기존 배송지</StyledTab>
          <StyledTab>신규 입력</StyledTab>
        </StyledTabsList>
        <StyledTabPanel value={0}>
          <Title sx={{ mb: 1 }}>창소프트 사옥 건설현장</Title>
          <Body>서울 강남구 테헤란로 103길 8-7, 삼성동 169-5</Body>
          <Body sx={{ color: colors.grey._40 }}>왕방울 010-4484-4929</Body>
          <Box sx={{ minWidth: 120, mt: 3, mb: 2 }}>
            <FormControl fullWidth>
              <Select
                value={comment}
                onChange={handleChange}
                displayEmpty
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return "배송 전 연락 부탁드립니다.";
                  }
                  return selected;
                }}
                sx={{
                  maxWidth: 567,
                  height: 46,
                  fontSize: 14,
                  fontWeight: 400,
                  color: colors.grey._60,
                  borderRadius: "8px",
                  "&:focus": {
                    outline: "none",
                  },
                }}
              >
                <StyledMenuItem value="배송 전 연락 부탁드립니다.">
                  배송 전 연락 부탁드립니다.
                </StyledMenuItem>
                <StyledMenuItem value="배송 메시지 1">
                  배송 메시지 1
                </StyledMenuItem>
                <StyledMenuItem value="배송 메시지 2">
                  배송 메시지 2
                </StyledMenuItem>
                <StyledMenuItem value="배송 메시지 3">
                  배송 메시지 3
                </StyledMenuItem>
              </Select>
            </FormControl>
          </Box>
        </StyledTabPanel>
        <StyledTabPanel value={1}></StyledTabPanel>
      </TabsUnstyled>
    </Wrap>
  );
};
