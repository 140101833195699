import { Box, styled, Grid, Typography, GridProps } from "@mui/material";
import { colors } from "Context";

export const Container = styled(Box)(() => ({
  width: "100vw",
  minHeight: "calc(100vh - 64px)",
  height: "auto",
  display: "flex",
  justifyContent: "center",
}));

export const Wrapper = styled(Grid)(() => ({
  maxWidth: 1400,
  paddingLeft: "1rem",
  paddingRight: "1rem",
  marginTop: 37,
  width: "100%",
  height: "auto",
}));

export const LeftContainer = styled(Grid)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
}));

interface RightContainerProps extends GridProps {
  scrolly: number;
  activefloat: string;
}

export const RightContainer = styled(Grid)((props: RightContainerProps) => ({
  width: "100%",
  marginTop:
    props.activefloat === "true"
      ? props.scrolly > 42
        ? props.scrolly - 42
        : 140
      : 140,
  transition: "margin-top 0.5s ease",
}));

export const TitleContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
}));

export const BadgeContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  marginBottom: "1rem",
}));

export const Title = styled(Typography)(() => ({
  fontSize: 20,
  fontWeight: 500,
  color: colors.grey._100,
}));

export const AddressContainer = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  marginTop: 4,
}));
