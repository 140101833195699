import { styled } from "@mui/material";
import { colors } from "Context";
import { SVGProps } from "react";

const StyledPath = styled("path")(() => ({
  fill: colors.purple._60,
  "&:hover": {
    fill: colors.purple._80,
    backgroundColor: " #f5f5f5",
  },
  "&:active": {
    fill: colors.purple._10,
  },
}));

export const DashboardWeatherRefresh = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#a)">
      <rect
        x={2.5}
        y={2.5}
        width={23}
        height={23}
        rx={3.5}
        fill="#fff"
        stroke="#F2F2F2"
      />
      <StyledPath
        fillRule="evenodd"
        clipRule="evenodd"
        d="m14.126 11.36-3.021-1.74a.473.473 0 0 1 0-.814l3.02-1.74c.31-.187.702.047.702.402v.603a5.911 5.911 0 1 1-6.602 4.22 1 1 0 1 1 1.924.545 3.91 3.91 0 1 0 7.672 1.075 3.911 3.911 0 0 0-2.994-3.803v.85a.47.47 0 0 1-.701.402Z"
        // fill="#A8A7AF"
      />
    </g>
    <defs>
      <filter
        id="a"
        x={0}
        y={0}
        width={32}
        height={32}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={2} dy={2} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_653_11631"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_653_11631"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
