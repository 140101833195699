import * as React from "react";
import { SVGProps } from "react";

export const BurgerDots = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={4}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 1.985C0 3.107.89 4 2 4c1.11 0 2-.893 2-2.015C4 .893 3.11 0 2 0 .89 0 0 .893 0 1.985ZM0 7.995C0 9.108.89 10 2 10c1.11 0 2-.892 2-2.005C4 6.892 3.11 6 2 6 .89 6 0 6.892 0 7.995ZM0 14.01C0 15.114.89 16 2 16c1.11 0 2-.886 2-1.99C4 12.886 3.11 12 2 12c-1.11 0-2 .886-2 2.01Z"
      fill="#DBDBDB"
    />
  </svg>
);
