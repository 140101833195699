import React from "react";
import { Grid, Paper, styled } from "@mui/material";
import {
  CalendarCheck,
  CalendarWithArrow,
  CraneIcon,
  LogIcon,
  BuildingIndicator,
} from "Components";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import {
  Canvas,
  useLoader,
  useFrame,
  useThree,
  ThreeElements,
} from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { colors } from "Context";

const StyledPaper = styled(Paper)(() => ({
  width: "100%",
  height: "100%",
  minHeight: "532px",
  border: "1px solid #F2F2F2",
  boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.08)",
  borderRadius: "8px",
  paddingTop: 23,
  paddingLeft: 24,
  paddingRight: 24,

  paddingBottom: 29,
}));

const ModelPaper = styled(`div`)(() => ({
  padding: 24,
  minHeight: 436,
  height: "10%",
  border: "1px solid #dbdbdb",
  borderRadius: 8,
}));

const ModelWrapper = styled("div")(() => ({
  "@keyframes blink": {
    "0%": { filter: "drop-shadow(0px 0px 20px rgba(102, 74, 227, 0.7))" },
    "50%": { filter: "none" },
    "100%": { filter: "drop-shadow(0px 0px 20px rgba(102, 74, 227, 0.7))" },
  },
  animation: "blink 1.5s linear infinite",
  "&:hover": {
    animation: "none",
    border: `2px solid ${colors.purple._60}`,
  },
  height: "90%",
  cursor: "pointer",
  borderRadius: 12,
  position: "relative",
  svg: {
    width: "100%",
    height: "100%",
  },
}));

const ModelHeader = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  marginBottom: 12,

  div: {
    width: 46,
    height: 24,
    background: "#eff8f7",
    borderRadius: 4,
    textAlign: "center",
    marginLeft: 8,
    marginRight: 8,
    span: {
      fontWeight: 500,
      fontSize: 12,
      lineHeight: "16px",
      letterSpacing: -0.3,
      color: "#00a185",
    },
  },
}));

const HoverIcon = styled("div")(() => ({
  position: "absolute",
  zIndex: 100,
  top: "45%",
  left: "15%",
}));

const CameraController = () => {
  const { camera, gl } = useThree();
  React.useEffect(() => {
    //@ts-ignore
    const controls = new OrbitControls(camera, gl.domElement);
    controls.minDistance = 10;
    controls.maxDistance = 55;
    return () => {
      controls.dispose();
    };
  }, [camera, gl]);
  return null;
};

export const ModelStatus = () => {
  const [hover, setHover] = React.useState(false);
  const boxRef = React.useRef<ThreeElements["primitive"]>();
  // @ts-ignore
  let gltf = useLoader(GLTFLoader, "/sample.glb");

  const Frame = () => {
    useFrame(() => {
      if (hover === false && boxRef) {
        const { current } = boxRef;
        if (!current) return;
        current.rotation.y -= 0.009;
      } else {
        const { current } = boxRef;
        if (!current) return;
        current.rotation.y -= 0;
      }
    });
    return <></>;
  };
  React.useEffect(() => {
    //@ts-ignore
    gltf?.scene?.children[0].children[0].children?.map(
      (item: {
        material: any;
        visible: boolean;
        position: { z: number; y: number };
      }) => {
        if (item.position.z >= 49) {
          return (
            (item.position.z = item.position.z + 2),
            (item.position.y = item.position.y - 3)
          );
        } else {
          return void 0;
        }
      }
    );
  }, [gltf]);

  React.useEffect(() => {
    //@ts-ignore
    gltf?.scene?.children[0].children[0].children?.map(
      (item: { material: any; position: { z: number; y: number } }) => {
        if (item.position.z < 49 && item.position.z >= 45.5) {
          let temp1 = item.material.clone();
          temp1.color.setHex(0x664ae3);
          item.material = temp1;
        }
        return void 0;
      }
    );
  }, [gltf]);

  return (
    <StyledPaper>
      <span
        style={{
          fontWeight: 500,
          fontSize: 16,
          lineHeight: "24px",
          letterSpacing: -0.3,
          marginBottom: 20,
        }}
      >
        작업현황
      </span>
      <Grid
        container
        item
        xl={12}
        lg={12}
        md={12}
        xs={12}
        sx={{
          marginTop: "20px",
        }}
      >
        <Grid item xl={7} lg={7} md={12} xs={12}>
          <ModelPaper>
            <ModelHeader>
              <span
                style={{
                  fontWeight: 500,
                  fontSize: 16,
                  lineHeight: "24px",
                  letterSpacing: -0.3,
                }}
              >
                모델에 직접 표시
              </span>
              <div>
                <span>작성</span>
              </div>
              <span
                style={{
                  fontWeight: 400,
                  fontSize: 10,
                  lineHeight: "14px",
                  letterSpacing: -0.3,
                  color: "#a8a7af",
                }}
              >
                *작업이 완료된 부분을 선택해주세요.
              </span>
            </ModelHeader>
            <ModelWrapper
              onMouseOver={() => setHover(true)}
              onMouseOut={() => setHover(false)}
            >
              {hover ? (
                <HoverIcon>
                  <BuildingIndicator />
                </HoverIcon>
              ) : null}
              <Canvas
                camera={{ fov: 25, position: [20, 20, 50] }}
                style={{
                  background: "#F9FBFD",
                  borderRadius: 12,
                }}
              >
                <CameraController />
                <ambientLight intensity={0.5} />
                <pointLight power={100} position={[0, 100, 0]} />
                <React.Suspense fallback={null}>
                  <group>
                    <primitive
                      ref={boxRef}
                      //@ts-ignore
                      object={gltf?.scene}
                      scale={[0.35, 0.35, 0.35]}
                      position={[0, -2, 0]}
                    ></primitive>
                  </group>
                </React.Suspense>
                <Frame />
              </Canvas>
            </ModelWrapper>
          </ModelPaper>
        </Grid>
        <Grid item container xl={5} lg={5} md={12} xs={12} spacing={0}>
          <Grid
            item
            container
            xl={12}
            lg={6}
            md={6}
            xs={6}
            sx={{
              // border: "0.5px solid #e5e5e5",
              display: "flex",
              justifyContent: "space-evenly",
              svg: {
                width: "100%",
                height: "100%",
              },
              marginTop: { xl: 0, lg: "0px", md: "20px", xs: "20px" },
            }}
          >
            <div>
              <CalendarCheck />
            </div>
            <div>
              <CalendarWithArrow />
            </div>
          </Grid>
          <Grid
            item
            container
            xl={12}
            lg={6}
            md={6}
            xs={6}
            sx={{
              // border: "0.5px solid #e5e5e5",
              svg: {
                width: "100%",
                height: "100%",
              },
              display: "flex",
              justifyContent: "space-evenly",
              // display: { lg: "flex", md: "flex", xs: "flex" },
              // justifyContent: {
              //   xl: "flex-start",
              //   lg: "flex-start",
              //   md: "space-evenly",
              //   xs: "space-evenly",
              // },
              marginTop: { xl: "0", lg: "0px", md: "20px", xs: "20px" },
            }}
          >
            <div>
              <CraneIcon />
            </div>
            <div>
              <LogIcon />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </StyledPaper>
  );
};
