import * as React from "react";
import { SVGProps } from "react";

export const GarbageBin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={19}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.83 2.73C6.195 1.147 7.69 0 9.405 0c1.713 0 3.213 1.136 3.576 2.73h5.079a.75.75 0 0 1 .75.75v3.06a.75.75 0 0 1-.75.75h-.885V22.5a.75.75 0 0 1-.75.75H2.385a.75.75 0 0 1-.75-.75V7.29H.75A.75.75 0 0 1 0 6.54V3.48a.75.75 0 0 1 .75-.75h5.08Zm1.579 0h3.995c-.316-.707-1.068-1.23-1.999-1.23-.926 0-1.678.527-1.996 1.23ZM3.135 7.305V21.75h12.54V7.305H3.135Zm4.965 3.18a.75.75 0 0 0-1.5 0v8.084a.75.75 0 0 0 1.5 0v-8.085Zm3.36-.75a.75.75 0 0 1 .75.75v8.084a.75.75 0 0 1-1.5 0v-8.085a.75.75 0 0 1 .75-.75ZM1.5 5.79V4.23h15.81v1.56H1.5Z"
      fill="#93919C"
    />
  </svg>
);
