import * as React from "react";
import { SVGProps } from "react";

export const Icon11 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <circle
      cx="28"
      cy="28"
      r="27.5"
      fill="url(#pattern0Icon11)"
      stroke="#F2F2F2"
    />
    <defs>
      <pattern
        id="pattern0Icon11"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use
          xlinkHref="#image0_737_7651Icon11"
          transform="translate(-0.0882353) scale(0.0294118)"
        />
      </pattern>
      <image
        id="image0_737_7651Icon11"
        width="40"
        height="34"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAiCAYAAAAtZZsLAAAAAXNSR0IArs4c6QAABCZJREFUWEfN2FtM21UcwPEvpaUFymWFtqzIbYVRGLByB2GDCTrmNp1mC8uSJdOoiboHjfqwLOqL2dyD0Qcdi4luqHFsMd5iqGRx2WIojCmZ7gIPSwAReWF0cYPS69+cP1mysJWW0iIn+b/0nJ7zOb9zzv9/zomRJOkmsIYAyevzoVAoUMTEBCoSzd8dMZIk/QskBWplaHKS1IQE1qakRBMSqO47AngL0AUqMTg2xvTMLK3FRf8HcDoo8Mr4OL+NjLKvrpaEuLiVRgYHTt29y5f2PurM66g3m1cfcMJxm65LAyRp1OyurkKXmLiSyOARFMAzA5e5MzdHVV4uLUUWNCrVSiGDA4XkVK+d639PkJWmY0uRhdLMzNUFtF29xne/D2JMSaYsK4s9VZWrCzj0zyRHu22olUpydDpaNxRRtzILJrQhdnm9vP3tD1waHaVAn44pNYUXNm8mOy3g6zNSEQ4NKFoTK/njCxdRKGIp1KdTYDDQXltNTlpapDAPqyd04OjULXYdP8GIw4HVZCJFo6ZtQzEvNTehVCiihQwdKAQHv+6io9cubxxKjEYs+nSetlrZbi0jSaOJBnJpwMGxv2j88COcbg+KWAVaVRwN2dlU52Tz8pZmMlIjvqFYGlCEqP2zk5zt6wcRMUkiXqVCCTyal8vhJ7exqSA/kpFcOnBkaoqKI8e47XRCbKyMlB+/X8YeanuCQ21bUYq85aelA0WbJ+19PP/5qfko3p/8fphz0VpWwuFtbTQXrl8uMTygaPXAF1/ReeEiJCQ8iHC55Og+VVbKweYmWiyF8q48jBQ+0Ol2s+P4Cc5fvQbx8Q+2LYbd5Zahlbk5PGstY2txESWmTNQqMWtDSuEDRfXTMzPs6viUX4eGHo4UhQTU65WfGI2GAr0eS4aRXJ0OrUZNjF/iuYZ6zHr98l7UgfrrmJ1l5ycd9A4NzyMXO1wJrM83/wi004khK4vet94g3xAloIDPut288+NPfHDuF5D8sNjRQCwklwutVsv+mmre3bkdY3JyoP4vb4gX1tpz/QZHbD3YR0fxLsz0+sDjlmH7qqt4veUxLGszgk3EyAJFa532fl453cWsiJQYbo8HfH4e0afTXlHOi5saKcwwBoPdy48cUCyYV0+foWvg8vwLXMwxSWK9ycSbj7ewu6KcNUs/z0QG2NnXz3vdNm6OjYNSiTY5iaZ8M/tra9hRVkqiWh1qxBaWWx7wxuQkr539hnMiahoNG9flsbeygj0V5ZgNhnBR9/8vPOCcx8NRWw/Hfu4hJTGRvVWVPGPdSGO+OVLf4PDn4PdX/uD9bhvp2iQONNTLm1ZtdPaC8rcg6NXHva54fX7ODw/z58QEmwsKqMnLjcQQBqsjdKBfknB5PMSv7P2MDFz0+i1YF6OcL1+/LXqBGWVAsOod/wFz/MguyQrH3QAAAABJRU5ErkJggg=="
      />
    </defs>
  </svg>
);
