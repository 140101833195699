import { styled, Box, Typography, Button } from "@mui/material";
import { colors } from "Context";

export const FilterDiv = styled(`div`)(
  (props: { active: string; blinkActive: string }) => ({
    filter: props.active === "true" ? "blur(5px)" : "none",
    "@keyframes blink": {
      "0%": { filter: "drop-shadow(0px 0px 20px rgba(102, 74, 227, 0.7))" },
      "50%": { filter: "none" },
      "100%": { filter: "drop-shadow(0px 0px 20px rgba(102, 74, 227, 0.7))" },
    },
    animation:
      props.blinkActive === "true" ? `blink 2s linear infinite` : "none",
    background: colors.white,
    borderRadius: 12,
  })
);

export const CustomBox = styled(Box)(() => ({
  width: "100%",
  maxWidth: 338,
  minWidth: 280,
  border: "1px solid #DBDBDB",
  borderRadius: 12,
  cursor: "pointer",
  position: "relative",
}));
export const ImageDiv = styled(`div`)(() => ({
  height: "auto",
}));
export const InfoDiv = styled(`div`)(() => ({
  position: "relative",
  padding: "40px 24px 25px 24px",
}));
export const IconDiv = styled(`div`)(() => ({
  position: "absolute",
  top: -28,
  left: 24,
}));
export const NameTypo = styled(Typography)(() => ({
  fontSize: 18,
  fontWeight: 500,
  letterSpacing: -0.3,
  color: colors.grey._100,
}));
export const TagTypo = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 500,
  letterSpacing: -0.3,
  color: colors.purple._60,
  span: {
    marginRight: 14,
  },
}));
export const ReviewDiv = styled(`div`)(() => ({
  display: "flex",
  marginTop: 14,
  p: {
    fontSize: 16,
    fontWeight: 500,
    color: colors.grey._60,
  },
}));
export const Footer = styled(`div`)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));
export const TitleTypo = styled(Typography)(() => ({
  height: 16,
  fontSize: 16,
  fontWeight: 500,
  color: colors.grey._40,
}));
export const AmountTypo = styled(Typography)(() => ({
  display: "flex",
  alignItems: "center",
  fontSize: 20,
  fontWeight: 500,
  color: colors.grey._100,
  height: 20,
  marginTop: 8,
  letterSpacing: -0.3,
  span: {
    fontSize: 14,
    fontWeight: 400,
    marginLeft: 2,
    letterSpacing: -0.3,
  },
}));
export const Btn = styled(Button)(() => ({
  minWidth: 98,
  height: 44,
  background: colors.purple._60,
  fontSize: 16,
  fontWeight: 700,
  letterSpacing: -0.3,
  color: colors.white,
  borderRadius: 8,
  padding: "12px 20px",
}));

export const HoverDiv = styled(`div`)(() => ({
  position: "absolute",
  left: 0,
  top: 0,
  display: "flex",
  flexDirection: "column",
  background: "rgba(37, 37, 37, 0.5)",
  borderRadius: 12,
  height: "100%",
  width: "100%",
  color: colors.white,
  cursor: "auto",
  justifyContent: "space-between",
}));

export const FavoriteBox = styled(Box)(() => ({
  padding: 32,
  textAlign: "end",
}));
export const PartnerInfoBox = styled(Box)(() => ({
  textAlign: "center",
  marginBottom: 42,
  marginTop: 32,
  p: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: -0.3,
  },
}));
export const InfoBox = styled(Box)(() => ({
  padding: "0 10%",
  fontSize: 14,
  fontWeight: 500,
  letterSpacing: -0.3,
}));
export const HoverBtn = styled(Button)(() => ({
  fontSize: 16,
  fontWeight: 700,
  margin: "10px 27px 24px 27px",
  padding: 14,
  color: colors.white,
  background: "rgba(255, 255, 255, 0.5)",
  borderRadius: 8,
  height: 48,
}));

export const ItemWrap = styled(`div`)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 14,
}));

export const ItemDiv = styled(`div`)(() => ({
  display: "flex",
  alignItems: "center",
  span: {
    paddingLeft: 8,
  },
}));
