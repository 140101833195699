import * as React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { styled } from "@mui/material";
import { colors } from "Context";

const StyledMenuItem = styled(MenuItem)(() => ({
  fontSize: 14,
  fontWeight: 400,
  color: "#6E7191",
}));

export default function OrderSelectCard() {
  const [card, setCard] = React.useState("");
  const handleChange = (event: SelectChangeEvent) => {
    setCard(event.target.value as string);
  };

  return (
    <Box
      sx={{
        maxWidth: 492,
        mt: 3,
        mb: 2,
      }}
    >
      <FormControl fullWidth>
        <Select
          id="card-select"
          value={card}
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return "현대카드";
            }
            return selected;
          }}
          sx={{
            height: 46,
            fontSize: 14,
            fontWeight: 400,
            color: colors.grey._60,
            borderRadius: "8px",
          }}
        >
          <StyledMenuItem value="현대카드">현대카드</StyledMenuItem>
          <StyledMenuItem value="국민카드">국민카드</StyledMenuItem>
          <StyledMenuItem value="우리카드">우리카드</StyledMenuItem>
          <StyledMenuItem value="삼성카드">삼성카드</StyledMenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
