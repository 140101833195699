import React from "react";
import {
  useAppDispatch,
  forgeViewerInitializeThunk,
  forgeViewerPnPInitThunk,
  useAppSelector,
} from "store";

export function useForgeViewer() {
  const { viewer } = useAppSelector((state) => state.forge);
  const dispatch = useAppDispatch();
  const viewerRenderer = React.useCallback(
    (ref: React.RefObject<HTMLDivElement>, urn: string) => {
      const el = ref.current;
      if (el) {
        dispatch(forgeViewerInitializeThunk({ el, urn }));
      }
    },
    [dispatch]
  );
  return { viewer, viewerRenderer };
}

export function useForgePnPViewer() {
  const { viewer2D, viewer3D } = useAppSelector((state) => state.forge);
  const dispatch = useAppDispatch();
  const viewerRenderer = React.useCallback(
    (
      ref3D: React.RefObject<HTMLDivElement>,
      ref2D: React.RefObject<HTMLDivElement>,
      urn3D: string,
      urn2D: string
    ) => {
      const el3D = ref3D.current;
      const el2D = ref2D.current;
      if (el3D && el2D) {
        dispatch(forgeViewerPnPInitThunk({ el2D, el3D, urn2D, urn3D }));
      }
    },
    [dispatch]
  );
  return { viewer2D, viewer3D, viewerRenderer };
}
