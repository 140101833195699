import { styled } from "@mui/material";
import { colors } from "Context";
import { SVGProps } from "react";

const StyledPath = styled("path")(() => ({
  fill: colors.purple._60,
  "&:hover": {
    fill: colors.purple._80,
    backgroundColor: " #f5f5f5",
  },
  "&:active": {
    fill: colors.purple._10,
  },
}));

export const DashboardRefresh = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_653_11375)">
      <rect
        x="2.5"
        y="2.5"
        width="23"
        height="23"
        rx="3.5"
        fill="white"
        stroke="#F2F2F2"
      />
      <StyledPath
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1258 11.3595L11.1046 9.61967C10.7959 9.43259 10.7959 8.98361 11.1046 8.80589L14.1258 7.06611C14.4345 6.87903 14.8273 7.11288 14.8273 7.46832V8.07062C17.6567 8.51097 19.8214 10.9576 19.8214 13.9107C19.8214 17.1755 17.1755 19.8214 13.9107 19.8214C10.6458 19.8214 8 17.1755 8 13.9107C8 13.3484 8.07889 12.808 8.22476 12.2918C8.37496 11.7603 8.92756 11.4512 9.45903 11.6014C9.9905 11.7516 10.2996 12.3042 10.1494 12.8357C10.0521 13.1801 10 13.5376 10 13.9107C10 16.071 11.7504 17.8214 13.9107 17.8214C16.071 17.8214 17.8214 16.071 17.8214 13.9107C17.8214 12.0661 16.5452 10.5203 14.8273 10.108V10.9572C14.8273 11.3127 14.4345 11.5372 14.1258 11.3595Z"
        // fill="#664AE3"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_653_11375"
        x="0"
        y="0"
        width="32"
        height="32"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_653_11375"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_653_11375"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
