import * as React from "react";
import { SVGProps } from "react";

export const OrderPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99847 19.99C15.4808 19.99 20 15.4656 20 9.99503C20 4.51761 15.4739 0 9.99158 0C4.51917 0 0 4.51761 0 9.99503C0 15.4656 4.52604 19.99 9.99847 19.99ZM9.99847 18.6975C5.17561 18.6975 1.29987 14.8162 1.29987 9.99503C1.29987 5.17383 5.16877 1.28945 9.99158 1.28945C14.8144 1.28945 18.707 5.17383 18.707 9.99503C18.707 14.8162 14.8213 18.6975 9.99847 18.6975Z"
      fill="#DBDBDB"
    />
    <path
      d="M5.46484 9.99446C5.46484 10.3592 5.73271 10.6201 6.11751 10.6201H9.34818V13.8565C9.34818 14.2343 9.61605 14.509 9.98095 14.509C10.3619 14.509 10.6367 14.2443 10.6367 13.8565V10.6201H13.8742C14.2521 10.6201 14.5238 10.3592 14.5238 9.99446C14.5238 9.61356 14.2553 9.3389 13.8742 9.3389H10.6367V6.10934C10.6367 5.72161 10.3619 5.45312 9.98095 5.45312C9.61605 5.45312 9.34818 5.72467 9.34818 6.10934V9.3389H6.11751C5.72965 9.3389 5.46484 9.61356 5.46484 9.99446Z"
      fill="#DBDBDB"
    />
  </svg>
);
