import React from "react";
import {
  Paper,
  styled,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import {
  Canvas,
  useLoader,
  useFrame,
  useThree,
  ThreeElements,
} from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { HammerIcon, BurgerDots } from "Components/Icons";
import { colors } from "Context";

const ModelPaper = styled(Paper)(() => ({
  height: "100%",
  paddingTop: 25,
  paddingBottom: 24,
  paddingLeft: 28,
  paddingRight: 32,
  borderRadius: 8,
  boxShadow:
    "2px 2px 6px rgba(102, 73, 226, 0.1), 0px 0px 2px rgba(102, 73, 226, 0.1)",
}));

const ModelingWrapper = styled(`div`)(() => ({
  borderRadius: 8,
  minHeight: 368,
  height: "100%",
  cursor: "pointer",
}));

const StyledTableRow = styled(TableRow)(() => ({
  border: 0,
}));

const StyledTableCell = styled(TableCell)(() => ({
  // [`&.${tableCellClasses.head}`]: {
  //   fontSize: 14,
  //   fontWeight: 400,
  //   letterSpacing: -0.3,
  //   color: colors.grey._60,
  // },
  // [`&.${tableCellClasses.body}`]: {
  //   paddingLeft: 12,
  //   backgroundColor: colors.white,
  //   color: colors.grey._100,
  //   // backgroundColor: "red",
  // },
}));

const StyledTable = styled(Table)(() => ({
  borderSpacing: 12,
  tbody: {
    tr: {
      td: {
        fontSize: 14,
        fontWeight: 400,
        letterSpacing: -0.3,
        color: colors.grey._60,
        border: 0,
        paddingTop: 6,
        paddingBottom: 6,
        minWidth: 150,
      },
      "td:last-of-type": {
        paddingLeft: 12,
        backgroundColor: colors.white,
        color: colors.grey._100,
      },
    },
  },
  border: 0,
}));
const ContentWrapper = styled(Grid)(() => ({}));

const CameraController = () => {
  const { camera, gl } = useThree();
  React.useEffect(() => {
    //@ts-ignore
    const controls = new OrbitControls(camera, gl.domElement);

    controls.minDistance = 10;
    controls.maxDistance = 55;
    return () => {
      controls.dispose();
    };
  }, [camera, gl]);
  return null;
};

interface ModelingProps {
  completeDay?: string;
  dday?: number;
}

export const Modeling = (props: ModelingProps) => {
  const [hover, setHover] = React.useState(false);
  const boxRef = React.useRef<ThreeElements["primitive"]>();
  // @ts-ignore
  let gltf = useLoader(GLTFLoader, "/sample.glb");

  const Frame = () => {
    useFrame(() => {
      if (hover === false && boxRef) {
        const { current } = boxRef;
        if (!current) return;
        current.rotation.y -= 0.009;
      } else {
        const { current } = boxRef;
        if (!current) return;
        current.rotation.y -= 0;
      }
    });
    return <></>;
  };
  React.useEffect(() => {
    //@ts-ignore
    gltf?.scene?.children[0].children[0].children?.map(
      (item: {
        material: any;
        visible: boolean;
        position: { z: number; y: number };
      }) => {
        let temp;
        if (item.position.z < 49 && item.position.z >= 45.5) {
          temp = item.material.clone();
          temp.color.setHex(0x664ae3);
          item.material = temp;
        }
        if (item.position.z >= 49) {
          return (
            (item.position.z = item.position.z + 2),
            (item.position.y = item.position.y - 3)
          );
        } else {
          return <></>;
        }
      }
    );
  }, [gltf]);
  return (
    <ModelPaper elevation={2}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: 500,
            fontSize: "18px",
          }}
        >
          <HammerIcon />
          <span style={{ marginLeft: 2 }}>모델로 살펴보는 공사 진행률</span>
        </div>
        <BurgerDots />
      </div>
      <ContentWrapper container spacing={2} mt={2}>
        <Grid item md={6} xs={12}>
          <ModelingWrapper
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
          >
            <Canvas
              camera={{ fov: 25, position: [20, 20, 50] }}
              style={{ background: "#F9FBFD" }}
            >
              <CameraController />
              <ambientLight intensity={0.5} />
              <pointLight power={100} position={[0, 100, 0]} />
              <React.Suspense fallback={null}>
                <group>
                  <primitive
                    ref={boxRef}
                    //@ts-ignore
                    object={gltf?.scene}
                    scale={[0.35, 0.35, 0.35]}
                    position={[0, -2, 0]}
                    castShadow={true}
                    receiveShadow={true}
                  ></primitive>
                </group>
              </React.Suspense>
              <Frame />
            </Canvas>
          </ModelingWrapper>
        </Grid>
        <Grid item md={6} xs={12} mt={2} sx={{ width: "100%", height: "100%" }}>
          <div style={{ paddingLeft: 10, marginBottom: 10 }}>
            <span
              style={{
                fontWeight: 500,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              공사 개요
            </span>
          </div>
          <StyledTable
          // sx={{ whiteSpace: { md: "nowrap", xs: "none" } }}
          >
            <TableBody>
              <StyledTableRow>
                <StyledTableCell>프로젝트 이름</StyledTableCell>
                <StyledTableCell>창소프트 재건축 프로젝트</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>공사 위치</StyledTableCell>
                <StyledTableCell>
                  서울시 강남구 테헤란로 103길 8-7 (삼성동)
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>공사 면적</StyledTableCell>
                <StyledTableCell>273m^2</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>건물 유형</StyledTableCell>
                <StyledTableCell>4층 상가주택</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>공사 시작일</StyledTableCell>
                <StyledTableCell>2022.09.02</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>공사 종료일(예정일)</StyledTableCell>
                <StyledTableCell>
                  {props.completeDay || "2023.02.28"}
                  <span style={{ color: "#664ae3", fontWeight: 700 }}>
                    {`[D-${props.dday || 238}]`}
                  </span>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>현장 소장</StyledTableCell>
                <StyledTableCell>조정현 | 010 1020 3040</StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </StyledTable>
        </Grid>
      </ContentWrapper>
    </ModelPaper>
  );
};
