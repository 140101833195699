import { styled, TableCell, TableRow } from "@mui/material";

export const StyledTableCell = styled(TableCell)(() => ({
  fontWeight: 400,
  fontSize: 14,
  textAlign: "center",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  border: 0,
  borderRadius: 4,
}));

export const StyledTableRow = styled(TableRow)(() => ({
  border: 0,
  borderRadius: 4,
  "&:nth-of-type(odd)": {
    backgroundColor: "#FAFAFC",
  },
  "&:last-child td, &:last-child th": {},
  "&:hover": {
    backgroundColor: "rgba(235, 231, 253, 0.3)",
  },
}));
