import {
  DashboardNoticeDelivery,
  DashboardNoticePhoto,
  DashboardNoticeWrite,
} from "Components/Icons/index";
import { colors } from "Context";
import {
  Board,
  BodyTypo,
  DetailTypo,
  NoticeColumn,
  NoticeRow,
  NoticeTypo,
  Row,
  TitleTypo,
} from "./Styles";

export const Notice = () => {
  return (
    <Board>
      <Row
        sx={{
          borderBottom: "1px solid #DBDBDB",
          paddingBottom: "20px",
          marginBottom: "32px",
        }}
      >
        <TitleTypo>알림 (12건)</TitleTypo>
        <DetailTypo
          sx={{
            fontWeight: 700,
            cursor: "pointer",
            color: colors.purple._60,
            "&:hover": {
              color: colors.purple._10,
            },
          }}
        >
          + 더보기
        </DetailTypo>
      </Row>

      <NoticeRow>
        <div style={{ paddingRight: 12 }}>
          <DashboardNoticePhoto />
        </div>
        <NoticeColumn>
          <NoticeTypo>
            참여 신청하신 프로젝트의 입찰 결과가 발표되었습니다.
          </NoticeTypo>
          <BodyTypo style={{ color: colors.grey._60 }}>
            2022-10-18 16:03
          </BodyTypo>
        </NoticeColumn>
      </NoticeRow>

      <NoticeRow>
        <div style={{ paddingRight: 12 }}>
          <DashboardNoticeDelivery />
        </div>
        <NoticeColumn>
          <NoticeTypo>주문하신 자재가 발송되었습니다.</NoticeTypo>
          <BodyTypo style={{ color: colors.grey._60 }}>
            2022-10-18 16:03
          </BodyTypo>
        </NoticeColumn>
      </NoticeRow>

      <NoticeRow>
        <div style={{ paddingRight: 12 }}>
          <DashboardNoticeWrite />
        </div>
        <NoticeColumn>
          <NoticeTypo>데일리 현장 리포트가 입력되지 않았습니다.</NoticeTypo>
          <BodyTypo style={{ color: colors.grey._60 }}>
            2022-10-18 16:03
          </BodyTypo>
        </NoticeColumn>
      </NoticeRow>

      <NoticeRow sx={{ display: { lg: "flex", xs: "none" } }}>
        <div style={{ paddingRight: 12 }}>
          <DashboardNoticePhoto />
        </div>
        <NoticeColumn>
          <NoticeTypo>참여 신청이 정상적으로 접수되었습니다.</NoticeTypo>
          <BodyTypo style={{ color: colors.grey._60 }}>
            2022-10-18 16:03
          </BodyTypo>
        </NoticeColumn>
      </NoticeRow>

      <NoticeRow sx={{ display: { lg: "flex", xs: "none" } }}>
        <div style={{ paddingRight: 12 }}>
          <DashboardNoticeDelivery />
        </div>
        <NoticeColumn>
          <NoticeTypo>주문하신 자재가 발송되었습니다.</NoticeTypo>
          <BodyTypo style={{ color: colors.grey._60 }}>
            2022-10-18 16:03
          </BodyTypo>
        </NoticeColumn>
      </NoticeRow>
    </Board>
  );
};
