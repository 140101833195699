import * as React from "react";
export const DashboardNoticeWrite = () => (
  <svg
    width="58"
    height="58"
    viewBox="0 0 58 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M57.5 29C57.5 44.7401 44.7401 57.5 29 57.5C13.2599 57.5 0.5 44.7401 0.5 29C0.5 13.2599 13.2599 0.5 29 0.5C44.7401 0.5 57.5 13.2599 57.5 29Z"
      fill="#664AE3"
      stroke="#664AE3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.71 25.04C49.89 25.23 50 25.48 50 25.75C50 26.02 49.9 26.27 49.71 26.46L41.18 34.99V43.63C41.18 44.18 40.73 44.63 40.18 44.63H17C16.45 44.63 16 44.18 16 43.63V15C16 14.45 16.45 14 17 14H40.18C40.73 14 41.18 14.45 41.18 15V25.92L45.18 21.92C45.57 21.53 46.2 21.53 46.59 21.92L49.71 25.04ZM37.64 35.69L39.47 33.86L39.48 33.84L45.03 28.29L43.32 26.59L35.93 33.98L34.53 37.09L37.64 35.69ZM18 42.62H39.18V36.99L38.93 37.24C38.84 37.32 38.74 37.39 38.63 37.44L32.95 40C32.82 40.06 32.68 40.09 32.54 40.09C32.28 40.09 32.02 39.99 31.83 39.8C31.54 39.5 31.46 39.06 31.63 38.68L34.19 33C34.24 32.89 34.3 32.78 34.39 32.7L39.18 27.92V16H18V42.62ZM44.73 25.19L46.44 26.89L47.59 25.74L45.88 24.04L44.73 25.19ZM36.35 22.1992H21.7C21.15 22.1992 20.7 21.7492 20.7 21.1992C20.7 20.6492 21.15 20.1992 21.7 20.1992H36.35C36.9 20.1992 37.35 20.6492 37.35 21.1992C37.35 21.7492 36.9 22.1992 36.35 22.1992ZM33.16 27.7109H21.7C21.15 27.7109 20.7 27.2609 20.7 26.7109C20.7 26.1609 21.15 25.7109 21.7 25.7109H33.16C33.71 25.7109 34.16 26.1609 34.16 26.7109C34.16 27.2609 33.71 27.7109 33.16 27.7109ZM29.97 31.2207H21.7C21.15 31.2207 20.7 31.6707 20.7 32.2207C20.7 32.7707 21.15 33.2207 21.7 33.2207H29.97C30.52 33.2207 30.97 32.7707 30.97 32.2207C30.97 31.6707 30.52 31.2207 29.97 31.2207Z"
      fill="white"
    />
  </svg>
);
