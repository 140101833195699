import React from "react";
import {
  Container,
  Wrapper,
  StyledTabs,
  StyledTab,
  GridContainer,
  FB,
} from "./Styles";
import { Typography, Box } from "@mui/material";
import { Notification } from "./Notification";
import { PaymentRequest } from "./PaymentRequest";
import { MonthlyPaymentStatus } from "./MonthlyPaymentStatus";
import { AppDownload } from "./AppDownload";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Tutorial } from "./Tutorial";
import { LastTutorialMaster } from "Components";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`payments-mgmt-tabpanel-${index}`}
      aria-labelledby={`payments-mgmt-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `payments-mgmt-tab-${index}`,
    "aria-controls": `payments-mgmt-tabpanel-${index}`,
  };
}

export function ManagePayments() {
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const handleTutorialOpen = () => {
    setOpen(true);
  };
  const handleTutorialClose = () => {
    setOpen(false);
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Container>
      <Tutorial open={open} handleTutorialClose={handleTutorialClose} />
      <FB onClick={handleTutorialOpen}>
        <LastTutorialMaster />
      </FB>
      <Wrapper>
        <Typography sx={{ fontSize: "24px", fontWeight: 700 }}>
          기성금 관리
        </Typography>
        <Box sx={{ width: "100%", mt: "38px" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <StyledTabs
              value={value}
              onChange={handleChange}
              aria-label="payments-mgmt-tabs"
              variant={matches ? "standard" : "scrollable"}
              scrollButtons={!matches}
              allowScrollButtonsMobile={!matches}
            >
              <StyledTab
                label="기성금 현황"
                {...a11yProps(0)}
                sx={{ fontSize: "1rem" }}
              />
              <StyledTab
                label="청구 관리"
                {...a11yProps(1)}
                sx={{ fontSize: "1rem" }}
              />
              <StyledTab
                label="지급 관리"
                {...a11yProps(2)}
                sx={{ fontSize: "1rem" }}
              />
              <StyledTab
                label="거래처(현장) 관리"
                {...a11yProps(3)}
                sx={{ fontSize: "1rem" }}
              />
            </StyledTabs>
          </Box>
          <TabPanel value={value} index={0}>
            <GridContainer>
              <Notification />
              <MonthlyPaymentStatus />
              <PaymentRequest />
              <AppDownload />
            </GridContainer>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <GridContainer>
              <Notification />
              <MonthlyPaymentStatus />
              <PaymentRequest />
              <AppDownload />
            </GridContainer>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <GridContainer>
              <Notification />
              <MonthlyPaymentStatus />
              <PaymentRequest />
              <AppDownload />
            </GridContainer>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <GridContainer>
              <Notification />
              <MonthlyPaymentStatus />
              <PaymentRequest />
              <AppDownload />
            </GridContainer>
          </TabPanel>
        </Box>
      </Wrapper>
    </Container>
  );
}
