import { Checkbox, Grid } from "@mui/material";
import { colors } from "Context";
import { Detail, Head, StyledFormControlLabel, Title, Wrap } from "../Styles";
import * as React from "react";
import Dayjs from "@date-io/dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export const DeliveryDate = () => {
  const [value, setValue] = React.useState<Dayjs | null>(null);

  return (
    <Wrap sx={{ mt: 5 }}>
      <Head sx={{ pb: 2, borderBottom: "1px solid #D9D9D9" }}>
        배송일자 지정
      </Head>

      <Grid
        container
        sx={{
          mt: 2,
          mb: 3,
          alignItems: "center",
          minWidth: 430,
        }}
        rowSpacing={1}
        columnSpacing={{ lg: -25 }}
      >
        <Grid item lg={5} md={6} xs={12}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Title sx={{ minWidth: 90 }}>희망 배송일자</Title>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="날짜를 선택해주세요"
                value={value}
                onChange={(newValue) => {
                  setValue(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    sx={{
                      minWidth: 160,
                      maxWidth: 212,
                      margin: "0 16px",
                      fontSize: 14,
                      fontWeight: 400,
                      color: "#6E7191",
                      borderRadius: 8,
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
        </Grid>

        <Grid item lg={7} md={6} xs={12}>
          <StyledFormControlLabel
            control={
              <Checkbox
                sx={{
                  color: colors.grey._20,
                  "&.Mui-checked": {
                    color: colors.purple._60,
                  },
                }}
              />
            }
            label="분할배송 설정"
          />
        </Grid>
      </Grid>

      <Detail>
        • 희망 배송일은 대략적인 날짜를 지정하므로 실제 배송일과 일치하지 않을
        수 있습니다.
      </Detail>
      <Detail>• 자재 수량에 따라 배송일이 딜레이될 수 있습니다.</Detail>
    </Wrap>
  );
};
