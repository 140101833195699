import React from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import { colors } from "Context";
import { FrameConstruction } from "./Frame";
import { Finishing } from "./Finishing";
import { useAppSelector } from "store";

function a11yProps(index: number) {
  return {
    id: `quotation-tab-${index}`,
    "aria-controls": `quotation-tabpanel-${index}`,
  };
}

interface QuotationProps {
  material: boolean;
  // handleClickMaterial(): void;
}

export function Quotation(props: QuotationProps) {
  const { material } = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { viewer3D } = useAppSelector((state) => state.forge);
  const handleReset = () => {
    if (!viewer3D) return;
    viewer3D.showAll();
    viewer3D.clearSelection();
  };
  React.useEffect(() => {
    if (material) {
      setValue(1);
    }
  }, [material]);

  return (
    <Box
      sx={{
        pl: "2rem",
        pt: "24px",
        pb: "20px",
        borderBottom: "solid 1px #f2f2f2",
      }}
    >
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="quotation"
          // sx={{ position: "static" }}
        >
          <Tab
            label="골조 공사"
            {...a11yProps(0)}
            sx={{ fontSize: "20px", zIndex: 0 }}
            onClick={handleReset}
          />
          <Tab
            label="마감 공사"
            {...a11yProps(1)}
            sx={{ fontSize: "20px", zIndex: 0 }}
            onClick={handleReset}
          />
        </Tabs>
        <Typography
          sx={{
            mt: "20px",
            lineHeight: "1rem",
            fontSize: "12px",
            fontWeight: 300,
            color: colors.grey._40,
          }}
        >
          원하는 자재를 직접 선택하여 나만의 견적서를 완성할 수 있습니다.
          <br /> 직접 선택하는 자재가 많아질수록, 고객님이 원하는 견적서에
          가까워질 수 있어요.
        </Typography>
      </Box>
      <TabPanel value={value} index={0}>
        <FrameConstruction />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Finishing material={material} />
      </TabPanel>
    </Box>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`quatation-tabpanel-${index}`}
      aria-labelledby={`quatation-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pl: 0, pr: "1rem", py: "1rem" }}>{children}</Box>
      )}
    </div>
  );
}
