import { styled, Grid, Typography, Paper } from "@mui/material";

export const Wrap = styled(`div`)(() => ({
  minWidth: "100vw",
  minHeight: "100vh",
  paddingTop: 24,
  paddingBottom: 44,
  display: "flex",
  justifyContent: "center",
  background: "#fafafc",
}));

export const PageContainer = styled(Grid)(() => ({
  width: "80%",
  height: "100%",
  maxWidth: 1400,
}));

export const FileReportCard = styled(Paper)(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  border: "1px solid #F2F2F2",
  boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.08)",
  borderRadius: "8px",
  padding: "16px 24px",
  span: {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: "24px",
  },
}));

export const TitleDiv = styled(`div`)(() => ({}));

export const FilterContainer = styled(`div`)(() => ({
  height: 150,
  width: "100%",
  background: "lightgrey",
  marginTop: "66px",
  marginBottom: "33px",
}));

export const CardContainer = styled(Grid)(() => ({
  width: "100%",
  marginBottom: "106px",

  // border: "1px solid blue",
}));

export const SingleCard = styled(Grid)(() => ({
  width: "100%",
  // border: "1px solid purple",
  display: "flex",
  justifyContent: "center",
}));

export const MainTypo = styled(Typography)(() => ({
  fontSize: 24,
  fontWeight: 700,
  letterSpacing: -0.3,
  color: "#262338",
  lineHeight: "28px",
}));
export const SubTypo = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 400,
  letterSpacing: -0.3,
  color: "#7d7b88",
  lineHeight: "20px",
}));

export const ButtonWrapper = styled(`div`)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  marginTop: 24,
  // position: "absolute",
  right: 0,
  bottom: 0,
  button: {
    cursor: "pointer",
    "@keyframes blink": {
      "0%": { filter: "drop-shadow(0px 0px 20px rgba(102, 74, 227, 0.7))" },
      "50%": { filter: "none" },
      "100%": { filter: "drop-shadow(0px 0px 20px rgba(102, 74, 227, 0.7))" },
    },
    animation: "blink 1.5s linear infinite",
    "&:hover": {
      animation: "none",
    },
    width: 218,
    height: 56,
    background: "#664ae3",
    borderRadius: 8,
    border: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    span: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: "20px",
      color: "#ffffff",
    },
  },
}));

export const PageHeader = styled(Paper)(() => ({
  width: "100%",
  minHeight: 72,
  border: "1px solid #F2F2F2",
  boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.08)",
  borderRadius: "8px",
  display: "flex",
  alignItems: "center",
  paddingLeft: 12,

  div: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 16,
    paddingRight: 21,
    justifyContent: "space-between",
    width: "38%",
    height: "40px",
    border: "1px solid #DBDBDB",
    filter: "drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1))",
    borderRadius: "4px",
    span: {
      width: "40%",
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "22px",
      color: "#262338",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
}));
