import React from "react";
import { Chip, Box, styled, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { colors } from "Context";

const data = [
  { key: 0, label: "서울 전체" },
  { key: 2, label: "강원 동해시" },
  { key: 3, label: "50억 미만" },
  { key: 4, label: "상가 건물" },
];

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export function Selected() {
  return (
    <Box
      component="ul"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        listStyle: "none",
        p: 0.5,
        m: 0,
        mb: "48px",
      }}
    >
      <Typography
        sx={{
          color: colors.purple._60,
          fontSize: 14,
          fontWeight: 700,
          mr: "2rem",
        }}
      >
        총 12건
      </Typography>
      {data.map((item) => (
        <ListItem key={item.key}>
          <Chip
            variant="outlined"
            color="primary"
            deleteIcon={<ClearIcon sx={{ width: 15, height: 15 }} />}
            onDelete={() => {}}
            label={item.label}
            sx={{ borderRadius: 2 }}
          />
        </ListItem>
      ))}
    </Box>
  );
}
