import React from "react";
import { Chip, Button } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { ImageContainer, GlowingDiv } from "./Styles";

interface ImageItemProps {
  src: string;
  glowing?: boolean;
  onClick?(): void;
}

export function ImageItem(props: ImageItemProps) {
  const { src, glowing, onClick } = props;
  return (
    <ImageContainer style={{ width: "100%" }}>
      {glowing && (
        <GlowingDiv>
          <Button className="container" onClick={onClick} disableRipple>
            <img src={src} alt={src} style={{ width: "100%" }} />
            <div className="middle">
              {/* <div className="text">자세히</div> */}
              <Chip
                icon={<AddCircleOutlineIcon />}
                size="small"
                label="자세히"
                sx={{
                  color: "#514f60",
                  background: "rgba(255, 255, 255, 0.7)",
                  padding: "2px",
                  cursor: "pointer",
                }}
              />
            </div>
          </Button>
        </GlowingDiv>
      )}
      {!glowing && (
        <Button className="container" disableRipple>
          <img src={src} alt={src} style={{ width: "100%" }} />
          <div className="middle">
            {/* <div className="text">자세히</div> */}
            <Chip
              icon={<AddCircleOutlineIcon />}
              size="small"
              label="자세히"
              sx={{
                color: "#514f60",
                background: "rgba(255, 255, 255, 0.7)",
                padding: "2px",
                cursor: "pointer",
              }}
            />
          </div>
        </Button>
      )}
    </ImageContainer>
  );
}
