import { Paper, styled } from "@mui/material";
import { ReportIcon, BurgerDots, RandomPerson } from "Components/Icons";
import {
  ExPhotoOne,
  ExPhotoTwo,
  ExPhotoThree,
  ExPhotoFour,
} from "Components/Images";
const StyledPaper = styled(Paper)(() => ({
  padding: "24px",
  width: "100%",
  height: "100%",
  border: "1px solid #F2F2F2",
  boxShadow:
    "2px 2px 6px rgba(102, 73, 226, 0.1), 0px 0px 2px rgba(102, 73, 226, 0.1)",
  borderRadius: "8px",
}));

const StyledReportBox = styled(`div`)(() => ({
  background: "#fcfcfc",
  maxWidth: "100%",
  maxHeight: 168,
  marginTop: 20,
  paddingLeft: 13,
  paddingTop: 13,
  paddingRight: 45,
  marginBottom: 15,
  display: "flex",
  borderRadius: 4,
  "div:first-of-type": {
    marginRight: 8,
  },

  p: {
    color: "#7d7b88",
    fontWeight: 400,
    fontSize: 14,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLinkClamp: 5,
    WebkeyBoxOrient: "vertical",
  },
}));

const PhotoBoxWrapper = styled(`div`)(() => ({
  marginTop: "16px",
  p: {
    fontWeight: 500,
    fontSize: 14,
  },
  div: {
    display: "flex",
    justifyContent: "space-evenly",
    svg: {
      marginRight: 8,
    },
  },
}));

export const DailySiteReport = () => {
  return (
    <StyledPaper
      sx={{
        maxWidth: { lg: "467px", sx: "100%" },
        minHeight: { lg: "397px", xs: "100%" },
        maxHeight: { lg: "397px", xs: "100%" },
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: 500,
            fontSize: "18px",
            maxWidth: "90%",
          }}
        >
          <ReportIcon />
          <span
            style={{
              marginLeft: 2,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            데일리 현장 리포트
          </span>
        </div>
        <BurgerDots />
      </div>
      <StyledReportBox>
        <div>
          <RandomPerson />
        </div>
        <div>
          To. 왕방울 건축주님
          <p>
            안녕하세요. 현장소장 조정현입니다. 지난 주말동안 비가 와서
            지연되었던 콘크리트타설 작업이 진행중에 있습니다. 현재 진행이
            늦어지고 있지만, 예정 시간내에 공사는 마무리가 될것같습니다!
            요청사항은 다시 한 번 확인해주시고, 현장에서도 더욱 더 신경써서
            작업을 진행하겠습니다.
          </p>
        </div>
      </StyledReportBox>
      <PhotoBoxWrapper>
        <p>사진첩</p>
        <div>
          <ExPhotoOne />
          <ExPhotoTwo />
          <ExPhotoThree />
          <ExPhotoFour />
        </div>
      </PhotoBoxWrapper>
    </StyledPaper>
  );
};
