import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

export function LocaleSelect() {
  const [area, setArea] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setArea(event.target.value);
  };
  return (
    <FormControl sx={{ width: "100%", mb: { xs: "1rem", sm: 0 } }}>
      <Select
        value={area}
        onChange={handleChange}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        sx={{
          height: "38px",
          mr: "1rem",
          border: 0,
          borderRadius: "8px",
          color: "#6E7191",
        }}
      >
        <MenuItem value="">지역</MenuItem>
        <MenuItem value={1}>서울특별시</MenuItem>
        <MenuItem value={2}>경기도</MenuItem>
        <MenuItem value={3}>부산광역시</MenuItem>
      </Select>
    </FormControl>
  );
}

export function BudgetSelect() {
  const [budget, setBudget] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setBudget(event.target.value);
  };
  return (
    <FormControl sx={{ width: "100%" }}>
      <Select
        value={budget}
        onChange={handleChange}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        sx={{
          height: 38,
          mr: "1rem",
          border: 0,
          borderRadius: "8px",
          color: "#6E7191",
        }}
      >
        <MenuItem value="">예산</MenuItem>
        <MenuItem value={1}>10억 이하</MenuItem>
        <MenuItem value={2}>10억 이상</MenuItem>
        <MenuItem value={3}>30억 이상</MenuItem>
        <MenuItem value={3}>50억 미만</MenuItem>
      </Select>
    </FormControl>
  );
}

export function BuildingTypeSelect() {
  const [buildingType, setBuildingType] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setBuildingType(event.target.value);
  };
  return (
    <FormControl sx={{ width: "100%" }}>
      <Select
        value={buildingType}
        onChange={handleChange}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        sx={{
          height: "38px",
          mr: "1rem",
          border: 0,
          borderRadius: "8px",
          color: "#6E7191",
        }}
      >
        <MenuItem value="">건물 유형</MenuItem>
        <MenuItem value={1}>단독 주택</MenuItem>
        <MenuItem value={2}>다세대 주택</MenuItem>
        <MenuItem value={3}>상가 건물</MenuItem>
      </Select>
    </FormControl>
  );
}

export function AvailablitySelect() {
  const [available, setAvailable] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setAvailable(event.target.value);
  };
  return (
    <FormControl sx={{ width: "100%" }}>
      <Select
        value={available}
        onChange={handleChange}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        sx={{
          height: "38px",
          mr: "1rem",
          border: 0,
          borderRadius: "8px",
          color: "#6E7191",
        }}
      >
        <MenuItem value="">참여 가능 여부</MenuItem>
        <MenuItem value={1}>참여 가능</MenuItem>
        <MenuItem value={2}>참여 제한</MenuItem>
      </Select>
    </FormControl>
  );
}
