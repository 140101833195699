import React from "react";
import { Routes, Route } from "react-router-dom";
import { Dashboard } from "./Dashboard";
import { ProjectList } from "./ProjectList";
import { ManagePayments } from "./ManagePayments";
import { ManageConstruction } from "./ManageConstruction";
import { ConstructorBidding } from "./ConstructorBidding";

export function Partner() {
  return (
    <Routes>
      <Route path="*" element={<Dashboard />} />
      <Route path="project-list" element={<ProjectList />} />
      <Route path="manage-construction" element={<ManageConstruction />} />
      <Route path="manage-payments" element={<ManagePayments />} />
      <Route path="constructor-bidding/:id" element={<ConstructorBidding />} />
    </Routes>
  );
}
