import MenuItem from "@mui/material/MenuItem";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import {
  Accordion,
  Button,
  FormControlLabel,
  Grid,
  styled,
  Typography,
} from "@mui/material";
import { colors } from "Context";

export const Container = styled("div")(() => ({
  width: "100vw",
  height: "100%",
}));

export const OrderWrap = styled(Grid)(() => ({
  padding: "99px 2rem 50px 1rem",
  margin: "auto",
  maxWidth: 1400,
  width: "100%",
}));

export const RightSide = styled("div")(() => ({
  position: "sticky",
  top: 70,
}));

export const Wrap = styled(Grid)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "left",
}));

export const Row = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  alingItems: "center",
  justifyContent: "space-between",
}));

export const Column = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const OrderHead = styled(Typography)(() => ({
  fontSize: 24,
  fontWeight: 500,
  letterSpacing: -0.3,
  color: colors.grey._100,
  paddingBottom: 69,
}));

export const Head = styled(Typography)(() => ({
  fontSize: 20,
  fontWeight: 700,
  letterSpacing: -0.3,
  color: colors.grey._60,
}));

export const PaymentHead = styled(Typography)(() => ({
  fontSize: 18,
  fontWeight: 700,
  letterSpacing: -0.3,
  color: colors.grey._100,
}));

export const Title = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 500,
  letterSpacing: -0.3,
  color: colors.grey._100,
}));

export const Body = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 400,
  letterSpacing: -0.3,
  color: colors.grey._100,
}));

export const Detail = styled(Typography)(() => ({
  fontSize: 12,
  fontWeight: 400,
  color: colors.grey._40,
}));

export const StyledTabsList = styled(TabsListUnstyled)(() => ({
  marginBottom: 16,
  borderBottom: "1px solid #D9D9D9",
  display: "flex",
  button: {
    whiteSpace: "nowrap",
  },
}));

export const StyledTab = styled(TabUnstyled)`
  max-width: 130px;
  width: 100%;
  height: 40px;
  padding: 11px 30px;
  display: flex;
  justify-content: center;
  border: none;
  border-radius: 8px 8px 0px 0px;
  background-color: #f2f2f2;
  color: rgba(168, 167, 175, 1);
  font-size: 14px;
  font-weight: 400px;
  cursor: pointer;
  &:focus {
    border: 1px solid #dbdbdb;
    border-bottom: none;
    color: rgba(38, 35, 56, 1);
    background-color: #ffffff;
    outline: none;
  }
  &.${tabUnstyledClasses.selected} {
    border: 1px solid #dbdbdb;
    border-bottom: none;
    color: rgba(38, 35, 56, 1);
    background-color: #ffffff;
  }
`;

export const StyledTabPanel = styled(TabPanelUnstyled)(() => ({
  width: "100%",
  paddingTop: 24,
  display: "flex",
  flexDirection: "column",
  alignItems: "left",
  justifyContent: "space-between",
}));

export const StyledMenuItem = styled(MenuItem)(() => ({
  fontSize: 14,
  fontWeight: 400,
  color: colors.grey._100,
}));

export const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  fontSize: 12,
  fontWeight: 400,
  color: colors.grey._40,
  alignSelf: "left",
}));

export const StyledAccordian = styled(Accordion)(() => ({
  borderRadius: 8,
  marginBottom: 16,
  backgroundColor: "#FCFCFC",
  border: "1px solid #DBDBDB",
  boxShadow: "none",
  "&:before": {
    display: "none",
  },
}));

export const StyledBoxShadowAccordian = styled(StyledAccordian)(() => ({
  "@keyframes blink": {
    "0%": { filter: "drop-shadow(0px 0px 20px rgba(102, 74, 227, 0.7))" },
    "50%": { filter: "none" },
    "100%": { filter: "drop-shadow(0px 0px 20px rgba(102, 74, 227, 0.7))" },
  },
  animation: "blink 1.5s linear infinite",
  "&:hover": {
    animation: "none",
  },
}));

export const StyledPaymentInfoButton = styled(Button)(() => ({
  width: "90%",
  padding: "28px 0 20px 0",
  margin: "0 12px 0 0",
  color: colors.grey._60,
  border: "2px solid #DBDBDB",
  backgroundColor: "#FCFCFC",
  "&:hover": {
    border: "2px solid #DBDBDB",
    backgroundColor: "#FCFCFC",
  },
  "&:focus": {
    color: colors.purple._60,
    background: "rgba(235, 231, 253, 0.4)",
    border: "2px solid #664AE3",
  },
}));

export const UnselectedPaymentInfoTitle = styled(Title)(() => ({
  color: colors.grey._60,
  marginTop: 20,
}));

export const SelectedPaymentInfoTitle = styled(Title)(() => ({
  color: colors.purple._60,
  marginTop: 20,
}));

export const StyledPayButton = styled(Button)(() => ({
  height: 52,
  lineHeight: 1,
  fontSize: 18,
  fontWeight: 500,
  color: colors.white,
  backgroundColor: colors.purple._60,
  borderRadius: 8,
}));

export const StyledBackButton = styled(StyledPayButton)(() => ({
  margin: "12px 24px 0 24px",
  backgroundColor: colors.grey._30,
  "@keyframes blink": {
    "0%": { filter: "drop-shadow(0px 0px 20px rgba(102, 74, 227, 0.7))" },
    "50%": { filter: "none" },
    "100%": { filter: "drop-shadow(0px 0px 20px rgba(102, 74, 227, 0.7))" },
  },
  animation: "blink 1.5s linear infinite",
  "&:hover": {
    animation: "none",
    backgroundColor: colors.grey._30,
    boxShadow: "none",
  },
}));
