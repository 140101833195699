import * as React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { styled } from "@mui/material";

const StyledMenuItem = styled(MenuItem)(() => ({
  fontSize: 14,
  fontWeight: 400,
  color: "#6E7191",
}));

export default function DashboardSelectMonth() {
  const [date, setDate] = React.useState("");
  const handleChange = (event: SelectChangeEvent) => {
    setDate(event.target.value as string);
  };

  const nowYear = new Date().getFullYear();
  const nowMonth = new Date().getMonth() + 1;
  const now = nowYear + "." + ("00" + nowMonth.toString()).slice(-2) + ".";

  return (
    <Box
      sx={{
        minWidth: 99,
        mt: 3,
        mb: 2,
        ":&hover": {
          border: "2px solid #836DE9",
        },
      }}
    >
      <FormControl fullWidth>
        <Select
          id="month-select"
          value={date}
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return now;
            }
            return selected;
          }}
          sx={{
            height: 43,
            fontSize: 14,
            fontWeight: 400,
            color: "#6E7191",
            borderRadius: "8px",
          }}
        >
          <StyledMenuItem value={now}>{now}</StyledMenuItem>
          <StyledMenuItem value="2022.09.">2022.09.</StyledMenuItem>
          <StyledMenuItem value="2022.08.">2022.08.</StyledMenuItem>
          <StyledMenuItem value="2022.07.">2022.07.</StyledMenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
