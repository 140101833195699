import * as React from "react";
import { SVGProps } from "react";

export const CurationCalendarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M18 3.8h-1v-.9c0-.495-.45-.9-1-.9s-1 .405-1 .9v.9H7v-.9c0-.495-.45-.9-1-.9s-1 .405-1 .9v.9H4c-1.11 0-1.99.81-1.99 1.8L2 18.2c0 .99.89 1.8 2 1.8h14c1.1 0 2-.81 2-1.8V5.6c0-.99-.9-1.8-2-1.8Zm0 13.5c0 .495-.45.9-1 .9H5c-.55 0-1-.405-1-.9v-9h14v9ZM6 10.1h2v1.8H6v-1.8Zm4 0h2v1.8h-2v-1.8Zm4 0h2v1.8h-2v-1.8Z"
        fill="#DBDBDB"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h22v22H0z" />
      </clipPath>
    </defs>
  </svg>
);
