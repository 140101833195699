import { styled } from "@mui/material";

export const Wrap = styled(`div`)({
  width: "100%",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignContent: "center",
});
export const Container = styled(`div`)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignContent: "center",
});
