import { styled, Paper, Grid } from "@mui/material";
import {
  GarbageBin,
  PlusHighlight,
  MinusHighlight,
  PlusDimmed,
  MinusDimmed,
} from "Components/Icons";
import { colors } from "Context";

const StyledPaper = styled(Paper)(() => ({
  width: "100%",
  height: "100%",
  border: "1px solid #F2F2F2",
  boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.08))",
  borderRadius: "8px",
  paddingTop: 31,
  paddingLeft: 24,
  paddingRight: 24,
  paddingBottom: 24,
}));

const SectionHeader = styled(`div`)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: "1px solid #dbdbdb",
  paddingBottom: 20,
  "span:first-of-type": {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "24px",
    color: "#262338",
  },
  "div:first-of-type": {
    width: 147,
    height: 38,
    border: "1px solid #dbdbdb",
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    span: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "22px",
      color: "#6e7191",
    },
  },
}));

const SectionBody = styled(Grid)(() => ({
  width: "100%",
  paddingTop: 20,
}));

const BodyTitle = styled(`span`)(() => ({
  fontWeight: 700,
  fontSize: 14,
  lineHeight: "22px",
  color: "#7d7b88",

  // textAlign: "middle",
}));

const CategoryTitle = styled(`div`)((props: { empty: boolean }) => ({
  maxWidth: 298,
  maxHeight: 48,
  border: "1px solid #dbdbdb",
  borderRadius: 4,
  padding: "13px 12px 13px 12px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  span: {
    width: "10%",
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "22px",
    color: props.empty === false ? "#262338" : "#a8a7af",
  },
}));

const ContentBox = styled(`div`)(() => ({
  width: "100%",
  maxWidth: "95%",
  maxHeight: 48,
  border: "1px solid #dbdbdb",
  borderRadius: 4,
  padding: "13px 12px 13px 12px",
  span: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "22px",
    color: "#a8a7af",
  },
}));

const NumberPersonnel = styled(`div`)(() => ({
  maxWidth: 72,
  minWidth: 65,
  width: "100%",
  maxHeight: 48,
  border: "1px solid #dbdbdb",
  borderRadius: 4,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "13px 12px 13px 12px",
  span: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "22px",
    color: "#262338",
  },
}));

export const PersonnelStatus = () => {
  return (
    <StyledPaper>
      <SectionHeader>
        <span>투입 인력 현황</span>
        <div>
          <span>이전 내역 불러오기</span>
        </div>
      </SectionHeader>
      <SectionBody container item lg={12} spacing={2}>
        <Grid item container lg={3} md={6} xs={6} spacing={2}>
          <Grid item lg={12} md={12} xs={12}>
            <BodyTitle>작업 분류</BodyTitle>
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <CategoryTitle empty={false}>
              <span>마감 공사</span>
            </CategoryTitle>
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <CategoryTitle empty={false}>
              <span style={{ color: colors.grey._40 }}>
                작업분류를 입력해주세요.
              </span>
            </CategoryTitle>
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <CategoryTitle empty={false}>
              <span style={{ color: colors.grey._40 }}>
                작업분류를 입력해주세요.
              </span>
            </CategoryTitle>
          </Grid>
        </Grid>
        <Grid
          item
          container
          lg={2}
          md={6}
          xs={6}
          spacing={2}
          sx={{ maxWidth: 200 }}
        >
          <Grid item lg={12} md={12} xs={12} sx={{ textAlign: "center" }}>
            <BodyTitle>인원</BodyTitle>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            sx={{
              svg: {
                width: "100%",
                height: "100%",
                maxWidth: 32,
                maxHeight: 32,
              },
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <MinusHighlight />
              <NumberPersonnel>
                <span>999명</span>
              </NumberPersonnel>
              <PlusDimmed />
            </div>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            sx={{
              svg: {
                width: "100%",
                height: "100%",
                maxWidth: 32,
                maxHeight: 32,
              },
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <MinusHighlight />
              <NumberPersonnel>
                <span>13명</span>
              </NumberPersonnel>
              <PlusHighlight />
            </div>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            sx={{
              svg: {
                width: "100%",
                height: "100%",
                maxWidth: 32,
                maxHeight: 32,
              },
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <MinusDimmed />
              <NumberPersonnel>
                <span style={{ color: colors.grey._40 }}>0명</span>
              </NumberPersonnel>
              <PlusHighlight />
            </div>
          </Grid>
        </Grid>
        <Grid item container lg={7} md={12} xs={12} spacing={2}>
          <Grid item lg={12} md={12} xs={12}>
            <BodyTitle>비고</BodyTitle>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              svg: {
                marginLeft: "15px",
              },
            }}
          >
            <ContentBox>
              <span style={{ color: colors.grey._40 }}>비고</span>
            </ContentBox>
            <GarbageBin />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              svg: {
                marginLeft: "15px",
              },
            }}
          >
            <ContentBox>
              <span style={{ color: colors.grey._40 }}>비고</span>
            </ContentBox>
            <GarbageBin />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              svg: {
                marginLeft: "15px",
              },
            }}
          >
            <ContentBox>
              <span style={{ color: colors.grey._40 }}>비고</span>
            </ContentBox>
            <GarbageBin />
          </Grid>
        </Grid>
      </SectionBody>
    </StyledPaper>
  );
};
