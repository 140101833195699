import { Grid } from "@mui/material";
import { OrderProjectImage } from "Components/Icons";
import { colors } from "Context";
import { Body, Title, Wrap } from "../Styles";

export const ProjectSummary = () => {
  return (
    <Wrap
      sx={{
        borderRadius: { sm: "12px", xs: "14px 14px 12px 12px" },
        border: "1px solid #DBDBDB",
        mt: 1,
      }}
    >
      <OrderProjectImage />
      <div style={{ padding: "20px 24px" }}>
        <Title sx={{ pb: 2 }}>창소프트 사옥</Title>
        <Grid container rowSpacing={{ md: 1, sm: 0, xs: 1 }}>
          <Grid item md={5} sm={12} xs={5}>
            <Body sx={{ color: colors.grey._60 }}>공사 위치</Body>
          </Grid>
          <Grid item md={7} sm={12} xs={7} sx={{ pb: { md: 0, sm: 1 } }}>
            <Body>서울시 강남구 테헤란로</Body>
            <Body>103길 8-7 (삼성동)</Body>
          </Grid>
          <Grid item md={5} sm={12} xs={5}>
            <Body sx={{ color: colors.grey._60 }}>공사 면적</Body>
          </Grid>
          <Grid item md={7} sm={12} xs={7} sx={{ pb: { md: 0, sm: 1 } }}>
            <Body>273㎡</Body>
          </Grid>
          <Grid item md={5} sm={12} xs={5}>
            <Body sx={{ color: colors.grey._60 }}>건물 유형</Body>
          </Grid>
          <Grid item md={7} sm={12} xs={7} sx={{ pb: { md: 0, sm: 1 } }}>
            <Body>4층 상가주택</Body>
          </Grid>
          <Grid item md={5} sm={12} xs={5}>
            <Body sx={{ color: colors.grey._60 }}>공사 시작 예정일</Body>
          </Grid>
          <Grid item md={7} sm={12} xs={7} sx={{ pb: { md: 0, sm: 1 } }}>
            <Body>22.09.02</Body>
          </Grid>
          <Grid item md={5} sm={12} xs={5}>
            <Body sx={{ color: colors.grey._60 }}>공사 종료 예정일</Body>
          </Grid>
          <Grid item md={7} sm={12} xs={7} sx={{ pb: { md: 0, sm: 1 } }}>
            <Body>23.02.28</Body>
          </Grid>
          <Grid item md={5} sm={12} xs={5}>
            <Body sx={{ color: colors.grey._60 }}>현장 소장</Body>
          </Grid>
          <Grid item md={7} sm={12} xs={7} sx={{ pb: { md: 0, sm: 1 } }}>
            <Body>왕왕방울</Body>
          </Grid>
        </Grid>
      </div>
    </Wrap>
  );
};
