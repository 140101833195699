import React from "react";
import { MachtingPartner } from "./MatchingPartner";
import { Tutorial } from "./Tutorial";
import { useNavigate } from "react-router-dom";

export const MachtingPartnerContainer = () => {
  const navigate = useNavigate();
  const [tutorialOpen, setTutorial] = React.useState(false);
  const handleTutorialOpen = () => {
    setTutorial(true);
  };
  const handleTutorialClose = () => {
    setTutorial(false);
  };
  const handleNext = () => {
    navigate("/customer/construction-status");
  };
  return (
    <>
      <Tutorial
        open={tutorialOpen}
        handleTutorialClose={handleTutorialClose}
        handleNext={handleNext}
      />
      <MachtingPartner handleTutorialOpen={handleTutorialOpen} />
    </>
  );
};
