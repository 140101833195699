import React from "react";
import { Box, styled, Typography } from "@mui/material";
import { colors } from "Context";
import { ChartBox } from "./ChartBox";

const Container = styled(Box)(() => ({
  width: "100%",
  marginTop: 52,
}));

const TitleContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  marginBottom: 20,
}));

interface PricingProps {
  active?: string;
  handleActive?: () => void;
}

export function Pricing(props: PricingProps) {
  return (
    <Container>
      <TitleContainer>
        <Typography
          sx={{ color: colors.grey._100, fontSize: "18px", fontWeight: 500 }}
        >
          평균 입찰 가격
          <Typography
            component="span"
            sx={{ color: colors.purple._60, fontSize: "18px", ml: "1rem" }}
          >
            {(11120000000).toLocaleString()}
            <span style={{ color: colors.purple._60, fontSize: 14 }}>원</span>
          </Typography>
        </Typography>
        <Typography sx={{ color: colors.grey._60, fontSize: "14px" }}>
          시공 파트너들의 평균 입찰가격을 참고하여 참여 신청 해보세요!
        </Typography>
      </TitleContainer>
      <ChartBox {...props} />
    </Container>
  );
}
