import React from "react";
import { ForgePnPViewer } from "Components";
import { useForgePnPViewer } from "hooks";

const urn3D =
  "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6YmhwbGYtYnVja2V0LWRldi8lRTElODQlOEIlRTElODUlQTclRTElODYlQTglRTElODQlODklRTElODUlQTElRTElODYlQjclRTElODQlODMlRTElODUlQTklRTElODYlQkMlMjA3MjEtMzAlMjAlRTElODQlODAlRTElODUlQjMlRTElODYlQUIlRTElODQlODUlRTElODUlQjUlRTElODYlQUIlRTElODQlODklRTElODUlQTIlRTElODYlQkMlRTElODQlOTIlRTElODUlQUElRTElODYlQUYlRTElODQlODklRTElODUlQjUlRTElODQlODklRTElODUlQTUlRTElODYlQUYtVGQ5ak02QU56Lm53Yw";
const urn2D =
  "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6YmhwbGYtYnVja2V0LWRldi9BLTIwMV8yMTUlMjAlRTElODQlOTElRTElODUlQTclRTElODYlQkMlRTElODQlODYlRTElODUlQTclRTElODYlQUIlRTElODQlODMlRTElODUlQTktU2tKTWZseTZnLmR3Zw";

export function Viewer() {
  const { viewer2D, viewer3D, viewerRenderer } = useForgePnPViewer();
  return (
    <ForgePnPViewer
      urn2D={urn2D}
      urn3D={urn3D}
      viewer2D={viewer2D}
      viewer3D={viewer3D}
      viewerRenderer={viewerRenderer}
      style={{
        position: "relative",
        top: 0,
        width: "100%",
        height: "100%",
        zIndex: 1,
      }}
    />
  );
}
