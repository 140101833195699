import * as React from "react";
import { SVGProps } from "react";

export const ArrowHeadDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={9}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m14.299 1.3-6.3 5.4-6.3-5.4"
      stroke="#DBDBDB"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
);
