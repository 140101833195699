import React from "react";
import { Box, Typography, Button } from "@mui/material";
import {
  MaterialCategory01,
  MaterialCategory02,
  MaterialCategory03,
} from "Components";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import img1 from "./1.png";
import img2 from "./2.png";
import img3 from "./3.png";
import img4 from "./4.png";
import img5 from "./5.png";
import img6 from "./6.png";
import img7 from "./7.png";
import img8 from "./8.png";
import img9 from "./9.png";
import img10 from "./10.png";
import img11 from "./11.png";
import { colors } from "Context";
import Slider, { Settings } from "react-slick";
import { StyledSlider } from "./Styles";
import { ImageItem } from "./ImageItem";

const settings: Settings = {
  dots: false,
  infinite: true,
  slidesToShow: 6,
  initialSlide: 0,
  swipeToSlide: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 6,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 6,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3,
      },
    },
  ],
};

interface MaterialSlideProps {
  handleClickMaterial(): void;
}

export function MaterialSlide(props: MaterialSlideProps) {
  const { handleClickMaterial } = props;
  const ref = React.useRef<Slider>(null);
  const handleNext = () => {
    ref.current?.slickNext();
  };

  return (
    <Box
      sx={{
        pl: "2rem",
        pt: "2rem",
        pb: "20px",
        borderBottom: "solid 1px #f2f2f2",
        overflow: "hidden",
        zIndex: 0,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          component="span"
          variant="subtitle1"
          sx={{
            fontWeight: 500,
            fontSize: { xs: 16, md: 20 },
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          이런 자재는 어떠세요?
        </Typography>
        <Button sx={{ minWidth: "30px" }} onClick={handleNext}>
          <ArrowForwardIosIcon sx={{ color: colors.grey._20, mr: "58px" }} />
        </Button>
      </div>
      <div style={{ marginTop: "0.5rem", marginBottom: "1rem" }}>
        <Button
          startIcon={<MaterialCategory01 />}
          variant="outlined"
          sx={{ mr: "12px", borderRadius: 10 }}
        >
          외장재
        </Button>
        <Button
          startIcon={<MaterialCategory02 />}
          variant="outlined"
          sx={{ mr: "12px", borderRadius: 10 }}
        >
          내장재
        </Button>
        <Button
          startIcon={<MaterialCategory03 />}
          variant="outlined"
          sx={{ mr: "12px", borderRadius: 10 }}
        >
          조명
        </Button>
      </div>
      <StyledSlider
        ref={ref}
        {...settings}
        sx={{ mb: { lg: "129px", xs: "260px" } }}
      >
        <div>
          <ImageItem src={img1} />
        </div>
        <div>
          <ImageItem src={img2} />
        </div>
        <div>
          <ImageItem src={img3} glowing onClick={handleClickMaterial} />
        </div>
        <div>
          <ImageItem src={img4} />
        </div>
        <div>
          <ImageItem src={img5} />
        </div>
        <div>
          <ImageItem src={img6} />
        </div>
        <div>
          <ImageItem src={img7} />
        </div>
        <div>
          <ImageItem src={img8} />
        </div>
        <div>
          <ImageItem src={img9} />
        </div>
        <div>
          <ImageItem src={img10} />
        </div>
        <div>
          <ImageItem src={img11} />
        </div>
      </StyledSlider>
    </Box>
  );
}
