import React from "react";
import { Typography } from "@mui/material";
import { colors } from "Context";

interface StatusProps {
  status: StatusType;
}

export enum StatusType {
  accepted = "접수 완료",
  paid = "지급 완료",
  onApprovement = "승인 중",
  rejected = "승인 반려",
  approved = "승인 완료",
}

function getStatusColors(status: StatusType) {
  switch (status) {
    case StatusType.accepted: {
      return { background: "#EFF8F7", color: "#00A185" };
    }
    case StatusType.paid: {
      return { background: colors.grey._10, color: colors.grey._60 };
    }
    case StatusType.onApprovement: {
      return { background: "#EFF8F7", color: "#00A185" };
    }
    case StatusType.rejected: {
      return { background: "#FFF5F5", color: "#FF5036" };
    }
    case StatusType.approved: {
      return { background: "#F2FAFF", color: "#008FE2" };
    }
    default: {
      return { background: "#EFF8F7", color: "#00A185" };
    }
  }
}

export function Status(props: StatusProps) {
  const { status } = props;
  const { background, color } = getStatusColors(status);
  return (
    <Typography
      sx={{
        display: "flex",
        justifyContent: "center",
        maxWidth: "70px",
        height: "24px",
        background,
        p: "4px 12px",
        fontSize: "12px",
        color,
        borderRadius: "4px",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      }}
    >
      {props.status}
    </Typography>
  );
}
