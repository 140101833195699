import * as React from "react";
export const DashboardNoticeDelivery = () => (
  <svg
    width="58"
    height="58"
    viewBox="0 0 58 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="29" cy="29" r="28.5" fill="#664AE3" stroke="#664AE3" />
    <path
      d="M45.56 18.6102C45.48 18.5602 45.4 18.5202 45.32 18.4902L30.31 12.0802C30.07 11.9802 29.8 11.9702 29.55 12.0702L12.72 18.6702C12.72 18.6702 12.67 18.7002 12.65 18.7202C12.58 18.7502 12.51 18.7802 12.45 18.8202C12.17 19.0002 12 19.3202 12 19.6502V39.1102C12 39.5102 12.24 39.8702 12.61 40.0302L27.67 46.4602C27.67 46.4602 27.7 46.4602 27.71 46.4602C27.82 46.5002 27.94 46.5302 28.06 46.5302C28.17 46.5302 28.28 46.5102 28.39 46.4702C28.4 46.4702 28.41 46.4702 28.42 46.4702L45.36 39.8302C45.74 39.6802 45.99 39.3102 45.99 38.9002V19.4302C45.99 19.1002 45.83 18.7902 45.55 18.6002L45.56 18.6102ZM29.9 14.0802L42.35 19.4002L39.38 20.5602C39.38 20.5602 39.36 20.5402 39.34 20.5302L31.79 17.3102C31.55 17.2102 31.28 17.2002 31.03 17.3002L26.06 19.2502C25.66 19.4102 25.42 19.8002 25.44 20.2102C25.42 20.6202 25.64 21.0202 26.04 21.1902L31.65 23.5902L28.07 24.9902L15.62 19.6802L29.9 14.0802ZM34.31 22.5502L28.96 20.2602L31.39 19.3102L36.74 21.6002L34.31 22.5502ZM35.32 24.3002L38.06 23.2302V28.3902L35.32 29.4602V24.3002ZM14 21.1602L27.06 26.7302V44.0202L14 38.4502V21.1602ZM44 38.2202L29.06 44.0702V26.7502L33.32 25.0802V30.9202C33.32 31.2502 33.48 31.5602 33.76 31.7502C33.93 31.8702 34.13 31.9202 34.32 31.9202C34.44 31.9202 34.57 31.9002 34.68 31.8502L39.42 30.0002C39.8 29.8502 40.06 29.4802 40.06 29.0702V22.4502L44.01 20.9002V38.2202H44Z"
      fill="white"
    />
  </svg>
);
