import { Detail, Head, PaymentHead, Row, Title, Wrap } from "../Styles";
import { Grid } from "@mui/material";
import { QuotationAccordian } from "./QuotationAccordian";
import { colors } from "Context";
import { OrderEqual, OrderPlus } from "Components";

export const QuotationDetail = () => {
  return (
    <Wrap container sx={{ mt: 9 }}>
      <Head sx={{ mb: 4, pb: 2, borderBottom: "1px solid #D9D9D9" }}>
        견적서 내역 상세보기
      </Head>
      <QuotationAccordian />
      <Grid
        container
        columnSpacing={2}
        rowSpacing={1}
        sx={{
          width: "100%",
          margin: "28px auto 68px auto",
          padding: "23px 12px 23px 4px",
          backgroundColor: "#FCFCFC",
          borderTop: "1px solid #D9D9D9",
          alignItems: "center",
        }}
      >
        <Grid item lg={4} xs={12}>
          <Row sx={{ justifyContent: "space-between" }}>
            <Title>총 자재비</Title>
            <Row sx={{ justifyContent: "right" }}>
              <Title sx={{ color: colors.grey._60 }}>소계&nbsp;</Title>
              <Title>9,340,000</Title>
              <Detail
                sx={{
                  color: colors.grey._60,
                  fontWeight: 500,
                  lineHeight: 2,
                }}
              >
                &nbsp;원
              </Detail>
            </Row>
          </Row>
        </Grid>

        <Grid item lg={4} xs={12}>
          <Row sx={{ justifyContent: "space-between" }}>
            <Row sx={{ justifyContent: "left" }}>
              <OrderPlus />
              <Title sx={{ ml: 1 }}>총 배송비</Title>
            </Row>
            <Row sx={{ justifyContent: "right" }}>
              <Title sx={{ color: colors.grey._60 }}>소계&nbsp;</Title>
              <Title>500,000</Title>
              <Detail
                sx={{
                  color: colors.grey._60,
                  fontWeight: 500,
                  lineHeight: 2,
                }}
              >
                &nbsp;원
              </Detail>
            </Row>
          </Row>
        </Grid>

        <Grid item lg={4} xs={12}>
          <Row sx={{ justifyContent: "space-between", alignItems: "center" }}>
            <Row sx={{ justifyContent: "left" }}>
              <OrderEqual />
              <Title sx={{ ml: 1 }}>총 금액</Title>
            </Row>
            <PaymentHead
              sx={{
                color: colors.purple._60,
                fontWeight: 500,
                lineHeight: 2,
              }}
            >
              20,905,000원
            </PaymentHead>
          </Row>
        </Grid>
      </Grid>
    </Wrap>
  );
};
