import { Paper, styled, Container, Grid } from "@mui/material";
import "react-calendar/dist/Calendar.css";
import {
  CalendarIcon,
  RealCalendar,
  HomeIcon,
  PlayIcon,
} from "Components/Icons";

const StyledCalendar = styled(`div`)(() => ({
  display: "flex",
  justifyContent: "center",
  paddingBottom: "24px",
  width: "100%",
  height: "100%",
  minHeight: "306px",
  borderBottom: "1px solid #e0e0e0",

  svg: {
    width: "100%",
    height: "100%",
  },
}));

const StyledPaper = styled(Paper)(() => ({
  maxHeight: "524px",
  width: "100%",
  height: "100%",
  border: "1px solid #F2F2F2",
  boxShadow:
    "2px 2px 6px rgba(102, 73, 226, 0.1), 0px 0px 2px rgba(102, 73, 226, 0.1)",
  borderRadius: "8px",
}));

const BottomDivTitle = styled(`p`)(() => ({
  fontSize: "16px",
  fontWeight: 500,
  marginTop: 25,
  marginBottom: 8,
}));

const BottomDiv = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "space-evenly",
  marginBottom: 8,
}));

const IconTextWrapper = styled(Grid)(() => ({
  display: "flex",
  alignItems: "center",
  height: 46,
  div: {
    p: {
      margin: 0,
      marginLeft: 8,
      fontSize: 14,
      letterSpacing: -0.3,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: "90%",
    },
    "p:first-of-type": {
      fontWeight: 400,
      color: "#7d7b88",
    },
  },
}));

export const ConsCalendar = () => {
  return (
    <StyledPaper
      sx={{
        maxWidth: { lg: "456px", xs: "100%" },
        minHeight: { lg: "524px", xs: "100%" },
        maxHeight: { lg: "524px", xs: "100%" },
      }}
    >
      <Container>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: 500,
            fontSize: "18px",
            paddingTop: "26.5px",
            marginBottom: "22.5px",
          }}
        >
          <CalendarIcon />
          <span style={{ marginLeft: 2 }}>캘린더</span>
        </div>
        <StyledCalendar>
          <RealCalendar />
        </StyledCalendar>
        <BottomDivTitle>주요 스케줄</BottomDivTitle>
        <BottomDiv container>
          <IconTextWrapper item lg={6} md={6}>
            <PlayIcon />
            <div>
              <p>공사 시작일</p>
              <p>9월 31일</p>
            </div>
          </IconTextWrapper>
          <IconTextWrapper item lg={6} md={6}>
            <HomeIcon />
            <div>
              <p>공사 시작일</p>
              <p>9월 31일</p>
            </div>
          </IconTextWrapper>
        </BottomDiv>
      </Container>
    </StyledPaper>
  );
};
