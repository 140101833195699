import React from "react";
import { PaidContainer } from "../Styles";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
} from "@mui/material";
import { colors } from "Context";
import { rows } from "./createData";
import { StyledTableCell, StyledTableRow } from "./Styles";

export function PaymentRequest() {
  return (
    <PaidContainer>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          mb: "24px",
        }}
      >
        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: 700,
            minWidth: "2rem",
          }}
        >
          청구/지급내역
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 700,
            minWidth: "2rem",
            color: colors.purple._60,
          }}
        >
          +더보기
        </Typography>
      </Box>
      <TableContainer sx={{ overflowX: "auto", mx: "auto" }}>
        <Table sx={{ minWidth: 0 }} aria-label="payment request table">
          <TableHead
            sx={{
              maxWidth: 125,
              minWidth: 125,
              overflowX: "auto",
              whiteSpace: "nowrap",
            }}
          >
            <TableRow>
              <StyledTableCell>구분</StyledTableCell>
              <StyledTableCell>유형</StyledTableCell>
              <StyledTableCell>요청자</StyledTableCell>
              <StyledTableCell>금액</StyledTableCell>
              <StyledTableCell>상태</StyledTableCell>
              <StyledTableCell>요청날짜</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell component="th" scope="row">
                  {row.name}
                </StyledTableCell>
                <StyledTableCell>{row.type}</StyledTableCell>
                <StyledTableCell>{row.requestor}</StyledTableCell>
                <StyledTableCell>{row.amount.toLocaleString()}</StyledTableCell>
                <StyledTableCell
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  {row.status}
                </StyledTableCell>
                <StyledTableCell>{row.issueDate}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </PaidContainer>
  );
}
