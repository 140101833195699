import React from "react";
import { Grid } from "@mui/material";
import { DashboardRefresh } from "Components/Icons/DashboardRefresh";
import moment from "moment";
import { DailyReport } from "./DailyReport";
import { Notice } from "./Notice";
import { PaymentStatus } from "./PaymentStatus";
import { Possible } from "./Possible";
import { ProjectStatus } from "./ProjectStatus";
import {
  DetailTypo,
  HeadTypo,
  Wrap,
  Container,
  StyledHeadRefreshButton,
} from "./Styles";
import { Weather } from "./Weather";
import { WorkStatus } from "./WorkStatus";

export const Dashboard = () => {
  const [now, setNow] = React.useState("");
  const handleSetNow = React.useCallback(() => {
    moment.locale("kr", {
      weekdays: ["일", "월", "화", "수", "목", "금", "토"],
      meridiem: (hours) => (hours < 12 ? "오전" : "오후"),
    });
    const nowString = moment().format("YYYY.MM.DD(dddd) A hh:mm");
    setNow(nowString);
  }, []);
  React.useEffect(() => {
    handleSetNow();
  }, [handleSetNow]);
  return (
    <Container>
      <Wrap container spacing={2}>
        <Grid
          item
          lg={8}
          sm={4}
          xs={12}
          container
          columnSpacing={2}
          sx={{ alignItems: "center", position: "relative" }}
        >
          <Grid item lg={2} sm={12} xs={5}>
            <HeadTypo>대시보드</HeadTypo>
            <StyledHeadRefreshButton
              sx={{ top: { lg: 43, sm: 32, xs: 27 } }}
              onClick={handleSetNow}
            >
              <DashboardRefresh />
            </StyledHeadRefreshButton>
          </Grid>
          <Grid item lg={10} sm={12} xs={7}>
            <DetailTypo>{now}</DetailTypo>
          </Grid>
        </Grid>
        <Grid item lg={4} sm={8} xs={12}>
          <Weather />
        </Grid>

        <Grid item lg={8} md={12} xs={12}>
          <ProjectStatus />
        </Grid>
        <Grid item lg={4} md={12} xs={12}>
          <Notice />
        </Grid>

        <Grid item lg={3} md={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={12} sm={6} xs={12}>
              <Possible />
            </Grid>
            <Grid item lg={12} sm={6} xs={12}>
              <DailyReport />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={5} md={12} xs={12}>
          <WorkStatus />
        </Grid>
        <Grid item lg={4} md={12} xs={12}>
          <PaymentStatus />
        </Grid>
      </Wrap>
    </Container>
  );
};
