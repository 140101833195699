import React from "react";
import { colors } from "Context";
import {
  Container,
  MonthContainer,
  ChartContainer,
  Bar,
  CustomTooltip,
  ChartZeroTypo,
} from "./Styles";

interface GroupedBarChartProps {
  month: string;
  partnerValue: number;
  fieldValue: number;
}

export function GroupedBarChart(props: GroupedBarChartProps) {
  const { month, partnerValue, fieldValue } = props;
  return (
    <Container>
      <MonthContainer>{month}</MonthContainer>
      <ChartContainer>
        {partnerValue === 0 && fieldValue === 0 ? (
          <ChartZeroTypo>-</ChartZeroTypo>
        ) : (
          <>
            <CustomTooltip
              textColor={colors.purple._80}
              borderColor={colors.purple._80}
              arrow
              placement="right"
              title={`${(partnerValue * 123121).toLocaleString()}원`}
            >
              <Bar
                sx={{
                  background: colors.purple._40,
                  width: `${partnerValue}%`,
                  "&:hover": { background: "#513BB5" },
                }}
              />
            </CustomTooltip>
            <CustomTooltip
              textColor="#1C6C85"
              borderColor="#1C6C85"
              arrow
              placement="right"
              title={`${(fieldValue * 123121).toLocaleString()}원`}
            >
              <Bar
                sx={{
                  background: "#84EDE0",
                  width: `${fieldValue}%`,
                  "&:hover": { background: "#209FB0" },
                }}
              />
            </CustomTooltip>
          </>
        )}
      </ChartContainer>
    </Container>
  );
}
