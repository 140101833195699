import { Grid, Select, styled, TextField, Typography } from "@mui/material";
import { colors } from "Context";

export const ListDiv = styled(`div`)(() => ({
  display: "flex",
  justifyContent: "center",
  marginBottom: 117,
}));

export const ListTitileDiv = styled(`div`)(() => ({
  width: "100%",
  padding: 16,
}));
export const ListSearchGrid = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "space-between",
}));
export const SearchInput = styled(TextField)(() => ({
  width: "75%",
  maxWidth: 560,
  "& .MuiOutlinedInput-root": {
    height: 42,
    fontSize: 14,
    fontWeight: 400,
    color: "#A0A3BD",
  },
  ".MuiOutlinedInput-notchedOutline": {
    border: " 1px solid #D9DBE9",
    borderRadius: 999,
  },
}));
export const SearchSortSelect = styled(Select)(() => ({
  fontSize: 14,
  fontWeight: 400,
  color: "#A0A3BD",
  letterSpacing: -0.3,
  borderRadius: 8,
  height: 42,
  ".MuiOutlinedInput-notchedOutline": {
    border: " 1px solid #D9DBE9",
  },
}));
export const ListMainTypo = styled(Typography)(() => ({
  fontWeight: 500,
  fontSize: 24,
  letterSpacing: -0.3,
  color: colors.grey._100,
}));
export const ListSubTypo = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: 18,
  letterSpacing: -0.3,
  color: colors.grey._60,
  marginBottom: 60,
}));
export const ListCardGrid = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "center",
}));
