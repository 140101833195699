import {
  Detail,
  Row,
  StyledAccordian,
  StyledBoxShadowAccordian,
  Title,
} from "../Styles";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { colors } from "Context";
import { QuotationAccordianContent } from "./QuotationAccordianContent";

export const QuotationAccordian = () => {
  return (
    <div>
      <StyledBoxShadowAccordian>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Row sx={{ width: "100%", justifyContent: "space-between" }}>
            <Title>바닥 자재</Title>
          </Row>
          <Row sx={{ width: "100%", justifyContent: "right" }}>
            <Title sx={{ color: colors.grey._60 }}>소계&nbsp;</Title>
            <Title>9,340,000</Title>
            <Detail
              sx={{
                color: colors.grey._60,
                fontWeight: 500,
                lineHeight: 2,
              }}
            >
              &nbsp;원
            </Detail>
          </Row>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: { lg: "20px 32px", md: "0 0 0 30px", xs: 1 },
            backgroundColor: colors.white,
            borderRadius: "0px 0px 8px 8px",
          }}
        >
          <QuotationAccordianContent id="floor" />
        </AccordionDetails>
      </StyledBoxShadowAccordian>

      <StyledAccordian>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Row sx={{ width: "100%", justifyContent: "space-between" }}>
            <Title>벽지</Title>
          </Row>
          <Row sx={{ width: "100%", justifyContent: "right" }}>
            <Title sx={{ color: colors.grey._60 }}>소계&nbsp;</Title>
            <Title>2,100,000</Title>
            <Detail
              sx={{
                color: colors.grey._60,
                fontWeight: 500,
                lineHeight: 2,
              }}
            >
              &nbsp;원
            </Detail>
          </Row>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: { lg: "20px 32px", md: "0 0 0 30px", xs: 1 },
            backgroundColor: colors.white,
            borderRadius: "0px 0px 8px 8px",
          }}
        >
          <QuotationAccordianContent id="wallpaper" />
        </AccordionDetails>
      </StyledAccordian>

      <StyledAccordian>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Row sx={{ width: "100%", justifyContent: "space-between" }}>
            <Title>조명</Title>
          </Row>
          <Row sx={{ width: "100%", justifyContent: "right" }}>
            <Title sx={{ color: colors.grey._60 }}>소계&nbsp;</Title>
            <Title>830,000</Title>
            <Detail
              sx={{
                color: colors.grey._60,
                fontWeight: 500,
                lineHeight: 2,
              }}
            >
              &nbsp;원
            </Detail>
          </Row>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: { lg: "20px 32px", md: "0 0 0 30px", xs: 1 },
            backgroundColor: colors.white,
            borderRadius: "0px 0px 8px 8px",
          }}
        >
          <QuotationAccordianContent id="light" />
        </AccordionDetails>
      </StyledAccordian>

      <StyledAccordian>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Row sx={{ width: "100%", justifyContent: "space-between" }}>
            <Title>벽타일</Title>
          </Row>
          <Row sx={{ width: "100%", justifyContent: "right" }}>
            <Title sx={{ color: colors.grey._60 }}>소계&nbsp;</Title>
            <Title>6,735,000</Title>
            <Detail
              sx={{
                color: colors.grey._60,
                fontWeight: 500,
                lineHeight: 2,
              }}
            >
              &nbsp;원
            </Detail>
          </Row>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: { lg: "20px 32px", md: "0 0 0 30px", xs: 1 },
            backgroundColor: colors.white,
            borderRadius: "0px 0px 8px 8px",
          }}
        >
          <QuotationAccordianContent id="tile" />
        </AccordionDetails>
      </StyledAccordian>

      <StyledAccordian>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Row sx={{ width: "100%", justifyContent: "space-between" }}>
            <Title>페인트</Title>
          </Row>
          <Row sx={{ width: "100%", justifyContent: "right" }}>
            <Title sx={{ color: colors.grey._60 }}>소계&nbsp;</Title>
            <Title>1,400,500</Title>
            <Detail
              sx={{
                color: colors.grey._60,
                fontWeight: 500,
                lineHeight: 2,
              }}
            >
              &nbsp;원
            </Detail>
          </Row>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: { lg: "20px 32px", md: "0 0 0 30px", xs: 1 },
            backgroundColor: colors.white,
            borderRadius: "0px 0px 8px 8px",
          }}
        >
          <QuotationAccordianContent id="paint" />
        </AccordionDetails>
      </StyledAccordian>
    </div>
  );
};
