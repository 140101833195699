import { Paper, styled, Grid } from "@mui/material";

export const ModelPaper = styled(Paper)(() => ({
  width: "100%",
  paddingTop: 24,
  paddingBottom: 35,
  paddingLeft: 24,
  paddingRight: 24,
  border: "1px solid #F2F2F2",
  boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.08)",
  borderRadius: "8px",
}));

export const ImageWrapper = styled(`div`)(() => ({
  minWidth: 228,
  // minHeight: 228,
  borderRadius: 8,
  height: "100%",
  width: "100%",
  // display: "flex",
  // display: "block",
  // margin: "auto",
  // alignItems: "center",
  svg: {
    width: "100%",
    height: "100%",
  },
}));

export const TableWrapper = styled(`div`)(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "flex-start",
}));

export const StyledTable = styled(`table`)(() => ({
  width: "80%",
  height: "100%",
  borderSpacing: "12px",
  tbody: {
    "tr:first-of-type": {
      // borderSpacing: "none",
    },
    tr: {
      td: {
        fontSize: 14,
        fontWeight: 400,
        letterSpacing: -0.3,
        lineHeight: "22px",
      },
      "td:first-of-type": {
        // maxWidth: 120,
        color: "#7d7b88",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      "td:last-of-type": {
        // maxWidth: 220,
        // width: "20%",
        backgroundColor: "white",
        // wordBreak: "break-all",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
  },
}));
export const ContentWrapper = styled(Grid)(() => ({
  height: "100%",
  width: "100%",
}));
