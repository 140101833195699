import * as React from "react";
import { SVGProps } from "react";

export const OrderBack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.617188 12.0042C0.617188 17.3063 5.08998 21.6793 10.498 21.6793C15.9159 21.6793 20.3819 17.3063 20.3819 12.0042C20.3819 7.30897 16.8787 3.34316 12.3139 2.49455V1.03502C12.3139 0.475709 11.9197 0.313357 11.4641 0.632756L8.39771 2.73012C8.01477 2.99395 8.00796 3.37625 8.39771 3.64375L11.4573 5.74779C11.9197 6.07382 12.3139 5.91147 12.3139 5.3485V3.77533C16.2028 4.58102 19.0973 7.95085 19.0973 12.0042C19.0973 16.6711 15.2641 20.4281 10.498 20.4281C5.73191 20.4281 1.88816 16.6711 1.89496 12.0042C1.90176 9.07622 3.42317 6.50539 5.73539 5.01222C6.04464 4.79093 6.14214 4.4422 5.95692 4.13274C5.7717 3.83292 5.36426 3.74475 5.03156 3.99199C2.38056 5.73608 0.617188 8.68826 0.617188 12.0042Z"
      fill="white"
    />
  </svg>
);
