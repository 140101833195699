import * as React from "react";
import { SVGProps } from "react";

export const FavoriteIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="22"
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.25816 21C2.83976 21 3.18398 20.7241 4.25223 19.8665L8.83388 16.6751C8.89319 16.6258 9.00003 16.6258 9.04749 16.6751L13.7478 19.8665C14.8161 20.7241 15.1603 21 15.7418 21C16.5371 21 17 20.5664 17 19.7975V3.8288C17 1.95607 15.8724 1 13.641 1H4.35905C2.1276 1 1 1.95607 1 3.8288V19.7975C1 20.5664 1.46291 21 2.25816 21Z"
      stroke="white"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
);
