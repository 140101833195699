import React from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { createData } from "./createData";
import { StyledTableRow, CustomHeadTableCell, CustomTableCell } from "./Styles";
import { useAppSelector } from "store";

const rows = [
  createData("콘크리트", "100*100/콘크리트", "EAUIED", 15000, 1200000, "Wall"),
  createData(
    "목조조천틀/기준층",
    "80*100/콘크리트입니다킄",
    "EA",
    4200,
    560000,
    "Beam"
  ),
  createData("몰딩/콘크리트", "40*6600/알루미늄", "EA", 84, 1560000, "Slab"),
  createData(
    "외장용 고밀도 목재 패널",
    "100*100/콘크리트",
    "EA",
    32,
    890000,
    "BW"
  ),
  createData(
    "층간소음재 설치 다시해",
    "1000*100/철근",
    "EA",
    8777,
    1560000,
    "Slab"
  ),
  createData(
    "외장용 고밀도 목재 패널",
    "40*6600/알루미늄",
    "EA",
    100,
    743000,
    "Slab"
  ),
  createData("몰딩", "1000*100/철근", "EA", 9000, 43000, "Slab"),
];

export function FrameConstruction() {
  const { viewer3D } = useAppSelector((state) => state.forge);
  const handleClick = React.useCallback(
    (keyword: string) => {
      if (!viewer3D) return;
      viewer3D.search(
        keyword,
        function onSuccess(result: any) {
          // console.log({ result });
          viewer3D.hideAll();
          viewer3D.show([result]);
          viewer3D.select(result);
        },
        function onError(error: any) {
          console.log({ error });
        },
        []
      );
    },
    [viewer3D]
  );
  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow:
          "2px 2px 6px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.05);",
        borderRadius: "12px",
      }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <CustomHeadTableCell>품목</CustomHeadTableCell>
            <CustomHeadTableCell>규격</CustomHeadTableCell>
            <CustomHeadTableCell>단위</CustomHeadTableCell>
            <CustomHeadTableCell>수량</CustomHeadTableCell>
            <CustomHeadTableCell>단가</CustomHeadTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              onClick={() => handleClick(row.keyword)}
            >
              <CustomTableCell component="th" scope="row">
                {row.name}
              </CustomTableCell>
              <CustomTableCell>{row.standard}</CustomTableCell>
              <CustomTableCell>{row.unit}</CustomTableCell>
              <CustomTableCell>{row.amount.toLocaleString()}</CustomTableCell>
              <CustomTableCell align="right">
                ₩{row.price.toLocaleString()}
              </CustomTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
