import {
  Grid,
  LinearProgress,
  linearProgressClasses,
  styled,
  Tab,
  TableCell,
  tableCellClasses,
  TableRow,
  Typography,
  Button,
  MenuItem,
} from "@mui/material";
import { colors } from "Context";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";

export const Container = styled("div")(() => ({
  backgroundColor: "#F5F5F5",
  width: "100vw",
  // height: "100vh",
  height: "100%",
  overflowX: "hidden",
}));

export const Wrap = styled(Grid)(() => ({
  // width: "100vw",
  // height: "100vh",
  margin: "auto",
  padding: "15px 2rem 15px 1rem",
  maxWidth: 1400,
  width: "100%",
}));

export const HeadWrap = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "20px 0",
}));

export const WeatherWrap = styled(Grid)(() => ({
  width: "100%",
  height: "100%",
  backgroundColor: colors.white,
  borderRadius: 8,
  boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.08)",
}));

export const Board = styled("div")(() => ({
  width: "100%",
  height: "100%",
  padding: 24,
  backgroundColor: colors.white,
  borderRadius: 8,
  boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.08)",
  display: "flex",
  flexDirection: "column",
}));

export const PossibleBoard = styled(Board)(() => ({
  // border: "transparent",
  "@keyframes blink": {
    "0%": { filter: "drop-shadow(0px 0px 20px rgba(102, 74, 227, 0.7))" },
    "50%": { filter: "none" },
    "100%": { filter: "drop-shadow(0px 0px 20px rgba(102, 74, 227, 0.7))" },
  },
  animation: "blink 1.5s linear infinite",
  "&:hover": {
    animation: "none",
    // border: "2px solid #664AE3",
    outline: "2px solid #664AE3",
    outlineOffset: "1px",
  },
}));

export const HeadTypo = styled(Button)(() => ({
  fontSize: 24,
  fontWeight: 700,
  letterSpacing: -0.3,
  color: colors.black,
  paddingLeft: 0,
}));

export const ProjectPercentTypo = styled(Typography)(() => ({
  fontSize: 24,
  fontWeight: 700,
  letterSpacing: -0.3,
  color: colors.black,
  paddingLeft: 0,
}));

export const TitleTypo = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 700,
  letterSpacing: -0.3,
  color: colors.grey._100,
}));

export const SubtitleTypo = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 500,
  letterSpacing: -(0.3).toExponential,
  color: colors.grey._100,
}));

export const BodyTypo = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 400,
  letterSpacing: -0.3,
  color: colors.grey._100,
}));

export const DetailTypo = styled(Typography)(() => ({
  fontSize: 12,
  fontWeight: 400,
  letterSpacing: -0.3,
  color: colors.grey._60,
}));

export const SmallestTypo = styled(Typography)(() => ({
  fontSize: 10,
  fontWeight: 400,
  letterSpacing: -0.3,
  color: colors.grey._60,
}));

export const NoticeTypo = styled("div")(() => ({
  fontSize: 16,
  fontWeight: 500,
  letterSpacing: "-0.3px",
  color: colors.grey._100,
  display: "block",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

export const PossibleTypo = styled(BodyTypo)(() => ({
  fontWeight: 700,
  color: colors.purple._60,
  display: "block",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

export const Column = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const NoticeColumn = styled(Column)(() => ({
  alignItems: "start",
  width: "100%",
  display: "block",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

export const Row = styled("div")(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
}));

export const NoticeRow = styled(Row)(() => ({
  padding: "0px 4px",
  cursor: "pointer",
  height: "82px",
  alignItems: "center",
  "&:hover": {
    backgroundColor: "#FAFAFC",
    borderRadius: "8px",
  },
}));

export const StyledHeadRefreshButton = styled(ButtonUnstyled)`
  min-width: 32px;
  position: absolute;
  left: 130;
  margin: auto;
  padding: 0;
  border: none;
  background-color: #f5f5f5;
  cursor: pointer;
`;

export const Temperature = styled(Typography)(() => ({
  fontSize: 36,
  fontWeight: 700,
  letterSpacing: -0.3,
  color: colors.grey._100,
}));

export const ProjectStatusTab = styled(Tab)(() => ({
  fontSize: 16,
  fontWeight: 500,
  letterSpacing: -(0.3).toExponential,
  color: colors.grey._40,
}));

export const ProjectStatusProgress = styled(LinearProgress)(() => ({
  height: 12,
  borderRadius: 8,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: colors.grey._10,
  },
  [`& .${linearProgressClasses.bar}`]: {
    background: "linear-gradient(90deg, #A291EE 0%, #664AE3 100%)",
    borderRadius: 8,
  },
}));

export const StyledMenuItemWrap = styled(MenuItem)(() => ({
  backgroundColor: colors.white,
  "&:hover": {
    backgroundColor: "#FAFAFC",
  },
  "&:focus": {
    backgroundColor: colors.white,
    color: colors.grey._40,
    "&:hover": {
      backgroundColor: colors.white,
      cursor: "default",
    },
  },
  padding: 0,
}));

export const StyledMenuItem = styled("div")((props: { el: string }) => ({
  padding: "12px 0",
  width: "100%",
  fontSize: 14,
  fontWeight: 400,
  margin: "0 16px",
  borderTop:
    props.el === "경기 화성시 동탄지성로 현장" ? "1px solid #DBDBDB" : "none",
  borderBottom:
    props.el === "경기 화성시 동탄지성로 현장" ? "1px solid #DBDBDB" : "none",
  "&:focus": {
    backgroundColor: colors.white,
    color: colors.grey._40,
    "&:hover": {
      backgroundColor: colors.white,
      cursor: "default",
    },
  },
}));

export const WorkStatusBox = styled("div")(
  (props: { selectedWorksite: string }) => ({
    width: 100,
    height: 40,
    padding: 8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "right",
    alignItems: "center",
    textAlign: "right",
    backgroundColor: colors.white,
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: 4,
    border:
      props.selectedWorksite === "" ? "1px solid #DBDBDB" : "1px solid #664AE3",
    "&:hover": {
      border: "1px solid #A8A7AF",
    },
  })
);

export const WorkStatusTypo = styled(BodyTypo)(
  (props: { selectedworksite: string }) => ({
    color: props.selectedworksite === "" ? colors.grey._40 : colors.grey._100,
  })
);

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FAFAFC",
    color: colors.grey._100,
    border: 0,
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    fontWeight: 400,
    textAlign: "center",
  },
}));

export const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: colors.white,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 28,
  borderRadius: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#84EDE0",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 4,
    backgroundColor: colors.purple._40,
  },
}));

export const MintBorderLinearProgress = styled(BorderLinearProgress)(() => ({
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 4,
    backgroundColor: "#84EDE0",
  },
}));
