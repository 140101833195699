import { createSlice } from "@reduxjs/toolkit";
import { forgeViewerInitializeThunk, forgeViewerPnPInitThunk } from "./thunks";
export * from "./thunks";

interface State {
  loading: boolean;
  accessToken: string;
  tokenType: string;
  expiresIn: number;
  error: boolean;
  message: string;
  doc: Autodesk.Viewing.Document | null;
  viewer: Autodesk.Viewing.GuiViewer3D | null;
  doc2D: Autodesk.Viewing.Document | null;
  doc3D: Autodesk.Viewing.Document | null;
  viewer2D: Autodesk.Viewing.GuiViewer3D | null;
  viewer3D: Autodesk.Viewing.GuiViewer3D | null;
}

const initialState: State = {
  loading: false,
  accessToken: "",
  tokenType: "",
  expiresIn: 0,
  error: false,
  message: "",
  doc: null,
  doc2D: null,
  doc3D: null,
  viewer: null,
  viewer2D: null,
  viewer3D: null,
};

export const forgeSlice = createSlice({
  name: "autudeskForge",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(forgeViewerInitializeThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(forgeViewerInitializeThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.accessToken = action.payload.tokenData.access_token;
        state.tokenType = action.payload.tokenData.token_type;
        state.expiresIn = action.payload.tokenData.expires_in;
        state.doc = action.payload.doc;
        // @ts-ignore
        state.viewer = action.payload.viewer;
      })
      .addCase(forgeViewerInitializeThunk.rejected, (state, response) => {
        state.loading = false;
        state.error = true;
        state.message = response.error.message || "";
      })
      .addCase(forgeViewerPnPInitThunk.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(forgeViewerPnPInitThunk.fulfilled, (state, action) => {
        const { three, two } = action.payload;
        // console.log({ viewer3D: three.viewer });
        state.loading = false;
        state.accessToken = three.tokenData.access_token;
        state.tokenType = three.tokenData.token_type;
        state.expiresIn = three.tokenData.expires_in;
        state.doc2D = two.doc;
        state.doc3D = three.doc;
        // @ts-ignore
        state.viewer2D = two.viewer;
        // @ts-ignore
        state.viewer3D = three.viewer;
      })
      .addCase(forgeViewerPnPInitThunk.rejected, (state, response) => {
        state.loading = false;
        state.error = true;
        state.message = response.error.message || "";
      });
  },
});
