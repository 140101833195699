import {
  Img1,
  Img2,
  Img3,
  Img4,
  Img5,
  Img6,
  Img7,
  Img8,
  Img9,
  Img10,
  Img11,
  Img12,
  Img13,
  Img14,
  Img15,
  Img16,
  Icon1,
  Icon2,
  Icon3,
  Icon4,
  Icon5,
  Icon6,
  Icon7,
  Icon8,
  Icon9,
  Icon10,
  Icon11,
  Icon12,
  Icon13,
  Icon14,
  Icon15,
  Icon16,
  Icon17,
  Icon18,
  Icon19,
  Icon20,
} from "Components/Partner";

export const defaultPartnerList = [
  {
    id: 1,
    image: <Img1 />,
    icon: <Icon1 />,
    name: "앤 건설",
    tag: ["#시공건수 하위", "#상가건출 전문"],
    rate: 3.1,
    review: 16,
    amount: 17861500000,
    abilityEvaluation: 51,
    completion: 4,
    period: "6개월(180일)",
  },
  {
    id: 2,
    image: <Img2 />,
    icon: <Icon2 />,
    name: "나선 종합건설",
    tag: ["#시공건수 중원", "#소형건축 전문"],
    rate: 3.7,
    review: 83,
    amount: 10017000000,
    abilityEvaluation: 51,
    completion: 4,
    period: "6개월(180일)",
  },
  {
    id: 3,
    image: <Img3 />,
    icon: <Icon3 />,
    name: "빌더허브 전문건설",
    tag: ["#시공건수 상위", "#상가건축 전문"],
    rate: 4.2,
    review: 210,
    amount: 11120000000,
    abilityEvaluation: 51,
    completion: 4,
    period: "6개월(180일)",
  },
  {
    id: 4,
    image: <Img4 />,
    icon: <Icon4 />,
    name: "페인트를 종합건설",
    tag: ["#시공건수 중위", "#상가건출 전문"],
    rate: 4.2,
    review: 119,
    amount: 13006000000,
    abilityEvaluation: 51,
    completion: 4,
    period: "6개월(180일)",
  },
  {
    id: 5,
    image: <Img5 />,
    icon: <Icon5 />,
    name: "(주)페인트 건설",
    tag: ["#시공건수 하위", "#소형건축 전문"],
    rate: 2.7,
    review: 3,
    amount: 9870000000,
    abilityEvaluation: 51,
    completion: 4,
    period: "6개월(180일)",
  },
  {
    id: 6,
    image: <Img6 />,
    icon: <Icon6 />,
    name: "마음이편안 건설",
    tag: ["#시공건수 상위", "#상가건출 전문"],
    rate: 4.4,
    review: 77,
    amount: 10000000000,
    abilityEvaluation: 51,
    completion: 4,
    period: "6개월(180일)",
  },
  {
    id: 7,
    image: <Img7 />,
    icon: <Icon7 />,
    name: "새야새 종합건설",
    tag: ["#시공건수 하위", "#상가건출 전문"],
    rate: 3.1,
    review: 16,
    amount: 17861500000,
    abilityEvaluation: 51,
    completion: 4,
    period: "6개월(180일)",
  },
  {
    id: 8,
    image: <Img8 />,
    icon: <Icon8 />,
    name: "엠스타 종합건설",
    tag: ["#시공건수 중위", "#중소형건축 전문"],
    rate: 4.6,
    review: 320,
    amount: 11120000000,
    abilityEvaluation: 51,
    completion: 4,
    period: "6개월(180일)",
  },
  {
    id: 9,
    image: <Img9 />,
    icon: <Icon9 />,
    name: "DDR 건설",
    tag: ["#시공건수 상위", "#중소형건축 전문"],
    rate: 3.5,
    review: 12,
    amount: 10575000000,
    abilityEvaluation: 51,
    completion: 4,
    period: "6개월(180일)",
  },
  {
    id: 10,
    image: <Img10 />,
    icon: <Icon10 />,
    name: "넷플렉스 엔지니어링",
    tag: ["#시공건수 상위", "#상가건출 전문"],
    rate: 3.5,
    review: 16,
    amount: 10575000000,
    abilityEvaluation: 51,
    completion: 4,
    period: "6개월(180일)",
  },
  {
    id: 11,
    image: <Img11 />,
    icon: <Icon11 />,
    name: "플라이 전문건설",
    tag: ["#시공건수 하위", "#소형건축 전문"],
    rate: 3.8,
    review: 33,
    amount: 889650000,
    abilityEvaluation: 51,
    completion: 4,
    period: "6개월(180일)",
  },
  {
    id: 12,
    image: <Img12 />,
    icon: <Icon12 />,
    name: "인앤아웃 종합건설",
    tag: ["#시공건수 중위", "#상가건출 전문"],
    rate: 4.1,
    review: 119,
    amount: 13000000000,
    abilityEvaluation: 51,
    completion: 4,
    period: "6개월(180일)",
  },
  {
    id: 13,
    image: <Img13 />,
    icon: <Icon13 />,
    name: "(주)타임아웃 건설",
    tag: ["#시공건수 중위", "#상가건출 전문"],
    rate: 4.4,
    review: 210,
    amount: 16600000000,
    abilityEvaluation: 51,
    completion: 4,
    period: "6개월(180일)",
  },
  {
    id: 14,
    image: <Img14 />,
    icon: <Icon14 />,
    name: "(주)엠건설",
    tag: ["#시공건수 하위", "#소형건축 전문"],
    rate: 3.0,
    review: 50,
    amount: 8500000000,
    abilityEvaluation: 51,
    completion: 4,
    period: "6개월(180일)",
  },
  {
    id: 15,
    image: <Img15 />,
    icon: <Icon15 />,
    name: "인모드 종합건설",
    tag: ["#시공건수 중위", "#중소형건축 전문"],
    rate: 3.9,
    review: 41,
    amount: 10000000000,
    abilityEvaluation: 51,
    completion: 4,
    period: "6개월(180일)",
  },
  {
    id: 16,
    image: <Img16 />,
    icon: <Icon16 />,
    name: "모든건설",
    tag: ["#시공건수 상위", "#상가건출 전문"],
    rate: 3.1,
    review: 176,
    amount: 15378000000,
    abilityEvaluation: 51,
    completion: 4,
    period: "6개월(180일)",
  },
];

export const defaultSortItem = [
  { id: 1, value: 1, content: "최신순" },
  { id: 2, value: 2, content: "입찰 가격이 높은 순" },
  { id: 3, value: 3, content: "입찰 가격이 낮은 순" },
  { id: 4, value: 4, content: "평가 등급이 높은 순" },
  { id: 5, value: 5, content: "평가 등급이 낮은 순" },
  { id: 6, value: 6, content: "시공 건수가 많은 순" },
];

export const bidAverageList = [
  {
    rank: 1,
    icon: <Icon3 style={{ width: 28, height: 28 }} />,
    name: "빌더허브 전문건설",
    info: "전문/경기도 평택시",
    content: 11120000000,
    unit: "원",
  },
  {
    rank: 2,
    icon: <Icon9 style={{ width: 28, height: 28 }} />,
    name: "DDR 건설",
    info: "종합/경기도 수원시",
    content: 10575000000,
    unit: "원",
  },
  {
    rank: 3,
    icon: <Icon10 style={{ width: 28, height: 28 }} />,
    name: "넷플렉스 엔지니어링",
    info: "종합/ 화성시 반송동",
    content: 10571000000,
    unit: "원",
  },
];

export const constructionCapabilityEvaluationList = [
  {
    rank: 1,
    icon: <Icon17 style={{ width: 28, height: 28 }} />,
    name: "희망찬 건설 ",
    info: "전문/ 인천시 미추홀구",
    beforeUnit: "상위",
    content: 16,
    unit: "%",
  },
  {
    rank: 2,
    icon: <Icon18 style={{ width: 28, height: 28 }} />,
    name: "힐탑 종합건설",
    info: "종합/ 대구시 수성구",
    beforeUnit: "상위",
    content: 32,
    unit: "%",
  },
  {
    rank: 3,
    icon: <Icon10 style={{ width: 28, height: 28 }} />,
    name: "넷플렉스 엔지니어링",
    info: "종합/ 화성시 반송동",
    beforeUnit: "상위",
    content: 56,
    unit: "%",
  },
];

export const gradeList = [
  {
    rank: 1,
    icon: <Icon19 style={{ width: 28, height: 28 }} />,
    name: "판다 종합건설",
    info: "종합/ 서울시 강남구",
    content: 4.9,
    unit: "점",
  },
  {
    rank: 2,
    icon: <Icon20 style={{ width: 28, height: 28 }} />,
    name: "자연은 전문건설",
    info: "전문/ 성남시 분당구 ",
    content: 4.6,
    unit: "점",
  },
  {
    rank: 3,
    icon: <Icon6 style={{ width: 28, height: 28 }} />,
    name: "마음이편안 건설",
    info: "종합/서울시 영등포구 ",
    content: 4.4,
    unit: "점",
  },
];
export interface TabPropType {
  info: TabInfo;
}
interface TabInfo {
  rank: number;
  icon: React.ReactNode;
  name: string;
  info: string;
  beforeUnit?: string;
  content: number;
  unit: string;
}
export const projectInfo = {
  title: "신사동 비트라 카페",
  address: "서울특별시 강남구 테헤란로103길 8-7",
  constructionType: "도시형 생활 주택",
  budget: "10억",
  desiredCompletionDate: "22년 상반기",
  status: "",
  requirement: "",
};
export interface PropType {
  handleTutorialOpen(): void;
}
