import * as React from "react";
import { SVGProps } from "react";

export function DashboardDailyInsertDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="66"
      // width="current"
      height="58"
      // height="current"
      // viewBox="0 0 58 58"
      // viewBox="0 0 66 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="29"
        cy="29"
        r="27.5"
        fill="#FAFAFC"
        stroke="#D4D3D7"
        strokeWidth="3"
      />
      <path
        d="M30.5 23C30.5 19.685 27.815 17 24.5 17C21.185 17 18.5 19.685 18.5 23C18.5 26.315 21.185 29 24.5 29C27.815 29 30.5 26.315 30.5 23ZM33.5 26V29H38V33.5H41V29H45.5V26H41V21.5H38V26H33.5ZM12.5 38V41H36.5V38C36.5 34.01 28.505 32 24.5 32C20.495 32 12.5 34.01 12.5 38Z"
        fill="#D4D3D7"
      />
    </svg>
  );
}
