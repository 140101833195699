import React from "react";
import { Routes, Route } from "react-router-dom";
import { Curation } from "./Curation";
import { MachtingPartnerContainer } from "./MatchingPartner";
import { Order } from "./Order";
import { ConstructionStatus } from "./ConstructionStatus";

export function Customer() {
  return (
    <Routes>
      <Route path="*" element={<Curation />} />
      <Route path="/order" element={<Order />} />
      <Route path="/matching" element={<MachtingPartnerContainer />} />
      <Route path="/construction-status" element={<ConstructionStatus />} />
    </Routes>
  );
}
