import { styled } from "@mui/material";
import { ProgPercent } from "Components/Icons";

const Wrap = styled(`div`)(() => ({
  marginTop: "30px",
  width: "50%",
  height: "100%",
}));

const StyledBar = styled("div")(() => ({
  borderLeft: "4px solid #836DE9",
  height: "124px",
  display: "flex",
  justifyContent: "space-between",
  position: "relative",
}));

const GreyBar = styled("div")(() => ({
  borderLeft: "4px solid #f2f2f2",
  height: "124px",
  display: "flex",
  justifyContent: "space-between",
  position: "relative",
  svg: {
    position: "absolute",
    left: -85,
    bottom: 105,
  },
}));

const PurpleDot = styled(`span`)(() => ({
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  left: -6,
  width: "8px",
  height: "8px",
  backgroundColor: "#836DE9",
  borderRadius: "4px",
}));

const HollowDot = styled(`span`)(() => ({
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  left: -9,
  width: "14px",
  height: "14px",
  backgroundColor: "#fafafc",
  borderRadius: "50%",
  boxShadow: "0 0 0 4px #836DE9",
}));

const GreyDot = styled(`span`)(() => ({
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  left: -6,
  bottom: 0,
  width: "8px",
  height: "8px",
  backgroundColor: "#d3d4d7",
  borderRadius: "4px",
}));

const OneSection = styled(`div`)(() => ({
  position: "absolute",
  top: -20,
  marginLeft: "28px",
  span: {
    fontWeight: 500,
    fontSize: 20,
    lineHeight: "24px",
    color: "#7d7b88",
    whiteSpace: "nowrap",
  },
  "div:first-of-type": {
    width: "46px",
    height: "24px",
    marginBottom: "8px",
    background: "#ebe7fd",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    span: {
      fontWeight: 500,
      fontSize: 12,
      lineHeight: "16px",
      color: "#664ae3",
    },
  },
}));

const LastSection = styled(`div`)(() => ({
  position: "absolute",
  marginLeft: "28px",
  bottom: -10,
  span: {
    fontWeight: 500,
    fontSize: 20,
    lineHeight: "24px",
    color: "#7d7b88",
  },
}));

export const ConsProgress = () => {
  return (
    <Wrap>
      <StyledBar>
        <PurpleDot />
        <OneSection>
          <div>
            <span>완료</span>
          </div>
          <span>가설 공사</span>
        </OneSection>
      </StyledBar>
      <StyledBar>
        <PurpleDot />
        <OneSection>
          <div>
            <span>완료</span>
          </div>
          <span>토공사</span>
        </OneSection>
      </StyledBar>
      <StyledBar>
        <PurpleDot />
        <OneSection>
          <div>
            <span>완료</span>
          </div>
          <span>기초 공사</span>
        </OneSection>
      </StyledBar>
      <StyledBar>
        <PurpleDot />
        <OneSection>
          <div>
            <span>완료</span>
          </div>
          <span>골조 공사</span>
        </OneSection>
      </StyledBar>

      <StyledBar>
        <PurpleDot />
        <OneSection>
          <div>
            <span>완료</span>
          </div>
          <span>전기 공사</span>
        </OneSection>
      </StyledBar>
      <StyledBar>
        <PurpleDot />
        <OneSection>
          <div>
            <span>완료</span>
          </div>
          <span>설비 공사</span>
        </OneSection>
      </StyledBar>
      <GreyBar>
        <ProgPercent />
        <HollowDot />
        <OneSection>
          <div
            style={{
              width: "46px",
              height: "24px",
              marginBottom: "8px",
              background: "#664ae3",
              borderRadius: "4px",
            }}
          >
            <span style={{ color: "white" }}>진행중</span>
          </div>
          <span style={{ color: "#262338" }}>마감 공사</span>
        </OneSection>
        <GreyDot />
        <LastSection>
          <span>준공</span>
        </LastSection>
      </GreyBar>
    </Wrap>
  );
};
