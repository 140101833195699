import { colors } from "Context";
interface DashboardPiechartProps {
  // workerCount?: number;
  // totalCount?: number;
  selectedWorker: number;
  selectedResting: number;
  selectedFinish: number;
}

export function DashboardPiechart(props: DashboardPiechartProps) {
  // const { workerCount, totalCount } = props;
  // const wholeCircumference = 565.2;
  // const workerCircumference = (wholeCircumference * (workerCount || 25)) / (totalCount || 35);
  const { selectedWorker, selectedResting, selectedFinish } = props;
  const selectedTotal = selectedWorker + selectedResting + selectedFinish;
  const wholeCircumference = 565.2;
  const workerCircumference =
    (wholeCircumference * selectedWorker) / selectedTotal;
  const restingCircumference =
    (wholeCircumference * (selectedTotal - selectedFinish)) / selectedTotal;
  return (
    <svg
      width="240"
      height="240"
      viewBox="0 0 240 240"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="120"
        cy="120"
        r="90"
        stroke={colors.grey._20}
        strokeWidth={60}
        strokeDasharray={wholeCircumference}
        strokeDashoffset="0"
      />
      <circle
        cx="120"
        cy="120"
        r="90"
        stroke="#84EDE0"
        strokeWidth={60}
        strokeDasharray={restingCircumference || 188.4}
        strokeDashoffset="0"
      />
      <circle
        cx="120"
        cy="120"
        r="90"
        stroke="#836DE9"
        strokeWidth={60}
        strokeDasharray={workerCircumference || 376.4}
        strokeDashoffset={selectedTotal === 0 ? "180" : "0"}
      />
      <text x="104" y="110" fontSize="12" fontWeight="400" fill="#7D7B88">
        총 인원
      </text>
      <text
        // x="100"
        x={selectedTotal === 0 ? "107" : "100"}
        y="135"
        fontSize="20"
        fontWeight="700"
        fill="#262338"
      >
        {selectedTotal}명
      </text>
    </svg>
  );
}
