import React from "react";
import {
  Box,
  Grid,
  GridProps,
  styled,
  Typography,
  Divider,
  LinearProgress,
  Button,
  IconButton,
} from "@mui/material";
import { colors } from "Context";
import {
  BldgTypeIcon,
  BldgScaleIcon,
  BldgBudgetIcon,
  CalendarIcon,
  BookmarkIcon,
  BookmarkUnselectedIcon,
} from "./Icons";

const Container = styled(Box)(() => ({
  borderRadius: "1rem",
  border: `1px solid ${colors.grey._20}`,
  width: "100%",
  minHeight: "500px",
  padding: 24,
}));

interface ParticipationProps {
  active?: boolean;
  handleActive?: () => void;
  handleTutorialOpen(): void;
  projectName?: string;
  projectType?: string;
  projectScale?: string;
  projectBudget?: string;
}

export function Participation(props: ParticipationProps) {
  const {
    active,
    handleActive,
    handleTutorialOpen,
    projectName,
    projectType,
    projectScale,
    projectBudget,
  } = props;
  const [checked, setChecked] = React.useState(false);
  const handleClick = () => {
    setChecked(!checked);
  };
  return (
    <Container>
      <Typography
        sx={{ color: colors.grey._100, fontSize: "18px", mb: "2rem" }}
      >
        {projectName || "창소프트 재건축 프로젝트"}
      </Typography>
      <TopMetaData
        icon={<BldgTypeIcon />}
        typeName="유형"
        sx={{ mb: "14px" }}
        content={
          <Typography sx={{ color: colors.grey._100, whiteSpace: "nowrap" }}>
            {projectType || "4층 상가주택"}
          </Typography>
        }
      />
      <TopMetaData
        icon={<BldgScaleIcon />}
        typeName="공사면적"
        sx={{ mb: "14px" }}
        content={
          <Typography sx={{ color: colors.grey._100, whiteSpace: "nowrap" }}>
            {projectScale || "621"}m<sup>2</sup>
          </Typography>
        }
      />
      <TopMetaData
        icon={<BldgBudgetIcon />}
        typeName="예산"
        sx={{ mb: "26px" }}
        content={
          <Typography sx={{ color: colors.grey._100, whiteSpace: "nowrap" }}>
            {projectBudget || "31억"}
          </Typography>
        }
      />
      <Divider />
      <Typography
        sx={{
          color: colors.grey._100,
          fontSize: "18px",
          mb: "24px",
          mt: "20px",
        }}
      >
        토지 분석 정보
      </Typography>
      <TopMetaData
        typeName="도시계획"
        sx={{ mb: "1rem" }}
        content={
          <Typography
            sx={{
              color: colors.grey._100,
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            지구단위계획구역
          </Typography>
        }
      />
      <Grid container sx={{ width: "100%", mb: "1rem" }}>
        <TopMetaData
          item
          xs={6}
          typeName="건물규모"
          content={
            <Typography sx={{ color: colors.grey._100 }}>지상 5층</Typography>
          }
        />
        <TopMetaData
          item
          xs={6}
          typeName="건폐율"
          content={
            <Typography sx={{ color: colors.grey._100 }}>49.8%</Typography>
          }
        />
      </Grid>
      <Grid container sx={{ width: "100%", mb: "1rem" }}>
        <TopMetaData
          item
          xs={6}
          typeName="용적률"
          content={
            <Typography sx={{ color: colors.grey._100 }}>200%</Typography>
          }
        />
        <TopMetaData
          item
          xs={6}
          typeName="전용률"
          content={
            <Typography sx={{ color: colors.grey._100 }}>80%</Typography>
          }
        />
      </Grid>
      <Grid container sx={{ mt: "52px", alignItems: "center" }}>
        <Grid item xs={8} sx={{ pr: "0.5rem" }}>
          <LinearProgress
            sx={{
              borderRadius: 5,
              height: "5px",
              color: colors.purple._60,
              backgroundColor: colors.grey._10,
              "& .MuiLinearProgress-bar": {
                backgroundColor: colors.purple._60,
              },
            }}
            value={80}
            variant="determinate"
          />
        </Grid>
        <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
          <CalendarIcon sx={{ mt: 1.2, ml: 1 }} />
          <Typography sx={{ fontSize: "12px", color: colors.purple._60 }}>
            <span style={{ fontWeight: 500 }}>12일</span> 남음
          </Typography>
        </Grid>
      </Grid>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", mt: "24px" }}
      >
        <IconButton onClick={handleClick} sx={{ mr: "18px" }}>
          {checked ? (
            <BookmarkIcon viewBox="0 0 27 32" />
          ) : (
            <BookmarkUnselectedIcon viewBox="0 0 27 32" />
          )}
        </IconButton>
        <Button
          variant="contained"
          fullWidth
          sx={{
            height: "44px",
            backgroundColor: active ? colors.grey._10 : colors.purple._60,
            fontSize: "1rem",
            color: active ? colors.grey._40 : "#ffffff",
            "@keyframes blink": {
              "0%": {
                filter: "drop-shadow(0px 0px 20px rgba(102, 74, 227, 0.7))",
              },
              "50%": { filter: "none" },
              "100%": {
                filter: "drop-shadow(0px 0px 20px rgba(102, 74, 227, 0.7))",
              },
            },
            animation: "blink 1.5s linear infinite",
            "&:hover": {
              animation: "none",
            },
          }}
          onClick={() => {
            handleActive && handleActive();
            active && handleTutorialOpen();
          }}
        >
          {active ? "입찰가 변경" : "참여 신청하기"}
        </Button>
      </Box>
    </Container>
  );
}

interface TopMetaDataProps extends GridProps {
  icon?: React.ReactNode;
  typeName: string;
  content: React.ReactNode;
}

function TopMetaData(props: TopMetaDataProps) {
  const { icon, typeName, content, ...rest } = props;
  return (
    <Grid container sx={{ width: "100%" }} {...rest}>
      <Grid
        item
        xs={6}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
        }}
      >
        {icon}
        <Typography sx={{ color: colors.grey._40 }}>{typeName}</Typography>
      </Grid>
      <Grid item xs={6} sx={{ width: "100%" }}>
        {content}
      </Grid>
    </Grid>
  );
}
