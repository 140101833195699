import React from "react";
import { styled, Box, Typography, Button } from "@mui/material";
import { colors } from "Context";
import { MyPrice } from "./MyPrice";
import { WriteIcon } from "./WriteIcon";

const BlurContainer = styled(Box)((props: ChartBoxProps) => ({
  backgroundColor: "#FCFCFC",
  borderRadius: "1rem",
  width: "100%",
  padding: "64px 28px 40px",
  position: "absolute",
  filter: props.active === "true" ? "blur(0px)" : "blur(10px)",
  WebkitBackdropFilter: "blur(10px)",
}));

const Container = styled("div")(() => ({
  position: "relative",
  width: "100%",
  height: 326,
  marginBottom: 80,
}));

const NonBlurContainer = styled("div")((props: ChartBoxProps) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  visibility: props.active === "true" ? "visible" : "hidden",
}));

interface ChartBoxProps {
  active?: string;
  handleActive?: () => void;
}

export function ChartBox(props: ChartBoxProps) {
  const { active, handleActive } = props;
  const [hover, setHover] = React.useState(false);
  return (
    <Container
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      <BlurContainer active={active}>
        <AverageLine />
        <MyBiddingPrice />
        <HorizontalBarChart name="1위" value={40} color="#836DE9" />
        <HorizontalBarChart name="2위" value={50} color="#58D9C9" />
        <HorizontalBarChart name="3위" value={60} color="#FF7764" />
        <ThreeDots />
        <HorizontalBarChart name="16위" value={90} color="#FFD964" />
      </BlurContainer>
      <NonBlurContainer>
        <Button
          startIcon={<WriteIcon />}
          variant="contained"
          sx={{
            boxShadow: "0px 0px 20px rgba(102, 74, 227, 0.7)",
            visibility: hover && !(active === "true") ? "visible" : "hidden",
            opacity: hover && !(active === "true") ? 1 : 0,
            transition: "opacity 1s ease, visivility 2s",
            width: 328,
            height: 56,
            fontSize: 18,
            fontWeight: 500,
          }}
          onClick={() => handleActive && handleActive()}
        >
          참여신청하고 입찰 현황보기
        </Button>
      </NonBlurContainer>
    </Container>
  );
}

interface HorizontalBarChartProp {
  name: string;
  value: number;
  color: string;
}

function HorizontalBarChart(props: HorizontalBarChartProp) {
  const { name, value, color } = props;
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "flex-start",
        alignItems: "center",
        mb: "20px",
      }}
    >
      <Typography
        sx={{
          flexGrow: 0,
          order: 0,
          fontColor: colors.grey._100,
          mr: "0.5rem",
          minWidth: "34px",
          whiteSpace: "nowrap",
        }}
      >
        {name}
      </Typography>
      <Box
        sx={{
          backgroundColor: color,
          flexGrow: 0,
          order: 0,
          width: `${value}%`,
          borderRadius: "20px",
          height: "15px",
        }}
      />
    </Box>
  );
}

function Dot() {
  return (
    <Box
      sx={{
        backgroundColor: "#D9D9D9",
        width: "5px",
        height: "5px",
        borderRadius: "5px",
        mb: "0.5rem",
      }}
    />
  );
}

function ThreeDots() {
  return (
    <Box sx={{ my: "25px", ml: "0.5rem" }}>
      <Dot />
      <Dot />
      <Dot />
    </Box>
  );
}

function AverageLine() {
  return (
    <Box
      sx={{
        position: "absolute",
        ml: "50%",
        height: "80%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        top: 24,
      }}
    >
      <Typography
        sx={{
          color: colors.grey._40,
          fontSize: "14px",
        }}
      >
        평균가
      </Typography>
      <Box
        sx={{
          width: "1px",
          // background: "red",
          height: "100%",
          border: "1px dashed #BEBDC3",
          opacity: 0.6,
        }}
      ></Box>
    </Box>
  );
}

function MyBiddingPrice() {
  return (
    <Box sx={{ position: "absolute", ml: "60%", bottom: "20%" }}>
      <MyPrice myprice={32000000} />
    </Box>
  );
}
