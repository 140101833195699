import { Box, styled, Tab, Tabs, TabsProps } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { colors } from "Context";

export const Container = styled(Box)(() => ({
  width: "100vw",
  minHeight: "calc(100vh - 64px)",
  height: "auto",
  background: "#FAFAFC",
  display: "flex",
  justifyContent: "center",
}));

export const Wrapper = styled(Box)(() => ({
  maxWidth: 1400,
  paddingLeft: "1rem",
  paddingRight: "1rem",
  marginTop: 37,
  width: "100%",
  height: "auto",
}));

interface StyledTabsProps extends TabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

export const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    sx={{ pl: 0 }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    height: 4,
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 60,
    width: "100%",
    backgroundColor: "#635ee7",
  },
});

export const StyledTab = styled(Tab)(() => ({
  fontSize: 16,
  fontWeight: 500,
  letterSpacing: -0.3,
  color: colors.grey._40,
  "&.Mui-selected": {
    color: colors.purple._60,
  },
}));

export const GridContainer = styled("div")(() => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  return {
    display: "grid",
    padding: 0,
    paddingBottom: "2rem",
    rowGap: "1rem",
    columnGap: "1rem",
    gridTemplateColumns: "repeast(3, 1fr)",
    gridAutoRows: "minmax(100px, auto)",
    gridTemplateAreas: matches
      ? `
    "noti noti monthly"
    "paid paid monthly"
    "app app monthly"
  `
      : `
    "noti noti noti"
    "paid paid paid"
    "monthly monthly monthly"
    "app app app"
  `,
    minHeight: 0,
    minWidth: 0,
  };
});

export const SubContainer = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  background: "#FFFFFF",
  border: "1px solid #F2F2F2",
  boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.08)",
  borderRadius: 8,
}));

export const NotiContainer = styled(SubContainer)(() => ({
  gridArea: "noti",
  padding: "26px 24px",
  flexDirection: "column",
}));

export const MonthlyContainer = styled(SubContainer)(() => ({
  gridArea: "monthly",
  padding: "26px 24px",
  minWidth: "333px",
}));

export const PaidContainer = styled(SubContainer)(() => ({
  gridArea: "paid",
  padding: "26px 24px",
  flexDirection: "column",
  minwidth: 0,
  overflowX: "scroll",
}));

export const AppContainer = styled(Box)(() => ({
  gridArea: "app",
  minHeight: 245,
  height: "auto",
  backgroundColor: "#F4F7FF",
  boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.08)",
  borderRadius: "0.5rem",
}));

export const FB = styled(`div`)(() => ({
  position: "fixed",
  bottom: 32,
  right: 16,
  cursor: "pointer",
}));
