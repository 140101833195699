import React from "react";
import { Grid, InputAdornment, MenuItem } from "@mui/material";
import {
  ListDiv,
  ListTitileDiv,
  ListMainTypo,
  ListSubTypo,
  ListSearchGrid,
  SearchInput,
  SearchSortSelect,
  ListCardGrid,
} from "./ListStyles";
import { PropType, defaultPartnerList, defaultSortItem } from "./Types";
import { PartnerCard, Search } from "Components";

export const PartnerList = (props: PropType) => {
  const { handleTutorialOpen } = props;
  return (
    <ListDiv>
      <Grid
        container
        spacing={2}
        sx={{
          maxWidth: 1400,
          padding: {
            lg: "0px 16px",
            md: "0px 16px",
            sm: "0px 24px",
            xs: "0px 32px",
          },
        }}
      >
        <ListTitileDiv>
          <ListMainTypo>나의 프로젝트에 참여를 희망하는 파트너</ListMainTypo>
          <ListSubTypo>
            파트너들이 제안한 공사비과 시공계획을 비교해 보세요.
          </ListSubTypo>
          <ListSearchGrid item md={6}>
            <SearchInput
              variant="outlined"
              placeholder="시공 파트너 이름을 검색하세요."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
            <SearchSortSelect value={1}>
              {defaultSortItem.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.value}>
                    {item.content}
                  </MenuItem>
                );
              })}
            </SearchSortSelect>
          </ListSearchGrid>
        </ListTitileDiv>
        {defaultPartnerList.map((item) => {
          const { id } = item;
          return (
            <ListCardGrid item key={id} lg={3} md={4} sm={6} xs={12}>
              <PartnerCard
                info={item}
                handleTutorialOpen={handleTutorialOpen}
              />
            </ListCardGrid>
          );
        })}
      </Grid>
    </ListDiv>
  );
};
