import * as React from "react";
import { SVGProps } from "react";

export const MapPinIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.99998 1.33301C5.41998 1.33301 3.33331 3.41967 3.33331 5.99967C3.33331 8.77967 6.27998 12.613 7.49331 14.073C7.75998 14.393 8.24665 14.393 8.51331 14.073C9.71998 12.613 12.6666 8.77967 12.6666 5.99967C12.6666 3.41967 10.58 1.33301 7.99998 1.33301ZM7.99998 7.66634C7.07998 7.66634 6.33331 6.91967 6.33331 5.99967C6.33331 5.07967 7.07998 4.33301 7.99998 4.33301C8.91998 4.33301 9.66665 5.07967 9.66665 5.99967C9.66665 6.91967 8.91998 7.66634 7.99998 7.66634Z"
      fill="#D9DBE9"
    />
  </svg>
);
