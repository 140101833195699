import { styled, Grid, Typography, Paper } from "@mui/material";
import { colors } from "Context";

export const Wrap = styled(`div`)(() => ({
  minWidth: "100vw",
  minHeight: "100vh",
  paddingTop: 44,
  paddingBottom: 44,
  // whiteSpace: "nowrap",
  // textOverflow: "ellipsis",
  // height: "100%",
  // width: "100%",

  // padding: "64px 0",
  // marginTop: "64px",
  // paddingTop: 64,
  display: "flex",
  background: "#fafafc",
  justifyContent: "center",
  alignItems: "center",
}));

export const PageContainer = styled(Grid)(() => ({
  // marginTop: "64px",
  maxWidth: "1400px",
  width: "100%",
  paddingLeft: "1rem",
  paddingRight: "1rem",
  height: "88.85%",
  // marginBottom: 64,
  display: "flex",
  justifyContent: "center",
  // minHeight: "80%",
  // maxHeight: "100%",
  // marginTop: "64px",
}));

export const CompletePercentBox = styled(Grid)(() => ({
  // width: "100%",
  // minHeight: "68px",
}));

export const CompletePercentPaper = styled(Paper)(() => ({
  minHeight: 68,
  height: "100%",
  width: "100%",
  paddingLeft: 16,
  paddingRight: 16,
  border: "1px solid #F2F2F2",

  boxShadow:
    "2px 2px 6px rgba(102, 73, 226, 0.1), 0px 0px 2px rgba(102, 73, 226, 0.1)",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  div: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    // alignItems: "center",
  },
  p: {
    margin: 0,
  },
  "p:first-of-type": {
    // marginTop:16,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: -0.3,
    lineHeight: "18px",
  },
  "p:last-of-type": {
    // marginBottom:16,
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: -0.3,
    lineHeight: "16px",
  },
}));

// export const HeaderTitle = styled(Grid)(() => ({
//     // background: "#FCFCFC",
//   // background: "#fafafc",
//   // paddingBottom:25
//   marginBottom: 10,
//   div: {
//     height: "100%",
//     // marginBottom:30,
//   }

//     // border: "1px solid black",
//     // minHeight:"68px"
// }))

export const Left = styled(Grid)(() => ({
  position: "relative",
  background: "#FCFCFC",
  display: "flex",
  justifyContent: "center",
  flexDirection: "row",
  paddingBottom: 400,
}));
export const DemoDiv = styled(`div`)(() => ({
  width: "fit-content",
  background: colors.grey._10,
  borderRadius: 8,
  fontSize: 14,
  color: colors.grey._60,
  height: 34,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: `6px 7px`,
  fontWeight: 400,
  letterSpacing: -0.3,
  boxShadow: "2px 2px 6px rgb(0 0 0 / 8%), 0px 0px 2px rgb(0 0 0 / 5%)",
}));

export const TypoDiv = styled(`div`)(() => ({
  marginTop: 131,
  textAlign: "center",
}));
export const BoxGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  maxWidth: 285,
  width: "100%",
}));
export const MainTypo = styled(Typography)(() => ({
  fontSize: 20,
  fontWeight: 400,
  letterSpacing: -0.3,
  color: colors.grey._60,
}));
export const SubTypo = styled(Typography)(() => ({
  fontSize: 24,
  fontWeight: 500,
  letterSpacing: -0.3,
  color: colors.grey._100,
}));

export const BoxExplainTypo = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 400,
  color: colors.grey._40,
  textAlign: "center",
  marginTop: 26,
  whiteSpace: "nowrap",
  span: {
    fontWeight: 700,
  },
}));

export const CharacterDiv = styled(`div`)(() => ({
  position: "absolute",
  right: 0,
  bottom: 0,
}));

export const Right = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "center",
  paddingTop: 149,
  paddingBottom: 260,
}));
// export const Container = styled(`div`)(() => ({
//   width: "100%",
//   maxWidth: 378,
//   minWidth: 378,
//   display: "flex",
//   flexDirection: "column",
// }))
export const Title = styled(Typography)(() => ({
  fontSize: 24,
  fontWeight: 500,
  letterSpacing: -0.3,
  color: colors.black,
  textAlign: "left",
  lineHeight: "28px",
  //   marginBottom: 92
}));
export const SubTitle = styled(Typography)(() => ({
  fontSize: 12,
  fontWeight: 400,
  letterSpacing: -0.3,
  color: "#7d7b88",
  textAlign: "left",
  lineHeight: "16px",
  // marginBottom: 92
}));

export const ExampleDiv = styled(`div`)(() => ({
  width: "100%",
  maxWidth: 378,
  minHeight: 52,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "#FAFAFA",
  borderRadius: 99,
  color: colors.grey._100,
  fontSize: 18,
  letterSpacing: -0.3,
  boxShadow: "2px 2px 6px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.05)",
  marginBottom: 44,
}));
export const FB = styled(`div`)(() => ({
  position: "fixed",
  bottom: 32,
  right: 16,
  cursor: "pointer",
}));
