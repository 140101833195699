import { Grid } from "@mui/material";
import { ProjImgSample } from "Components";
import {
  ModelPaper,
  ImageWrapper,
  StyledTable,
  ContentWrapper,
} from "./Styles";

export const ProjectInfoCard = () => {
  return (
    <ModelPaper>
      <div style={{ marginBottom: 20 }}>
        <span style={{ fontWeight: 700, fontSize: 16, lineHeight: "24px" }}>
          창소프트 재건축 프로젝트
        </span>
      </div>
      <ContentWrapper
        container
        // sx={{ width: "100%", height: "100%" }}
        spacing={1}
      >
        <Grid item lg={4.5} md={4.5} xs={12}>
          <ImageWrapper>
            <ProjImgSample />
          </ImageWrapper>
        </Grid>
        <Grid
          item
          lg={7.5}
          md={7.5}
          xs={12}
          sx={{
            width: "100%",
            height: "100%",
            maxWidth: { lg: 360, md: 300, xs: 300 },
          }}
        >
          <StyledTable
            sx={{
              whiteSpace: { lg: "nowrap", md: "nowrap", xs: "nowrap" },
              td: {
                // border: "0.5px solid black",
                maxWidth: { lg: 400, md: 300, xs: 200 },
                overflow: { lg: "visible", md: "hidden", xs: "hidden" },
                textOverflow: { lg: "none", md: "ellipsis", xs: "ellipsis" },
              },
            }}
          >
            <tbody>
              <tr>
                <td>공사 위치</td>
                <td>서울시 강남구 테헤란로 103길 8-7 (삼성동)</td>
              </tr>
              <tr>
                <td>공사 면적</td>
                <td>273m²</td>
              </tr>
              <tr>
                <td>건물 유형</td>
                <td>4층 상가주택</td>
              </tr>
              <tr>
                <td>공사 시작일</td>
                <td>2022.09.02</td>
              </tr>
              <tr>
                <td>공사 종료일(예정일)</td>
                <td>
                  2023.02.28{"  "}
                  <span style={{ color: "#664ae3", fontWeight: 700 }}>
                    [D-238]
                  </span>
                </td>
              </tr>
              <tr>
                <td>현장 소장</td>
                <td>조정현 | 010 1020 3040</td>
              </tr>
              <tr>
                <td>의뢰인</td>
                <td>왕방울 | 010 000 1344</td>
              </tr>
            </tbody>
          </StyledTable>
        </Grid>
      </ContentWrapper>
    </ModelPaper>
  );
};
