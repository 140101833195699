import React from "react";
import { Box, BoxProps } from "@mui/material";

export function ResponsiveTutorialBox(props: BoxProps) {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        // maxWidth: 900,
        justifyContent: "space-between",
        alignItems: "center",
        mb: "25px",
      }}
    >
      <Box
        sx={{
          width: 0,
          height: 0,
          borderTop: "18px solid transparent",
          borderBottom: "18px solid transparent",
          borderRight: "18px solid rgba(0, 0, 0, 0.5)",
          zIndex: 2,
        }}
      />
      <Box
        sx={{
          // maxWidth: "700px",
          width: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          minHeight: "115px",
          borderRadius: "8px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: "36px",
          boxShadow: "0 0 30px #333",
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
}
