import * as React from "react";
import { SVGProps } from "react";

export const KRWIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 19.99c5.47 0 10-4.537 10-9.995C20 4.527 15.46 0 9.99 0 4.53 0 0 4.527 0 9.995c0 5.458 4.54 9.995 10 9.995Zm0-1.666a8.291 8.291 0 0 1-8.324-8.329c0-4.625 3.687-8.33 8.314-8.33a8.308 8.308 0 0 1 8.343 8.33A8.3 8.3 0 0 1 10 18.325Z"
      fill="#DBDBDB"
    />
    <path
      d="M5.774 9.808c0 .265.216.49.48.49h.599l.637 3.959a.673.673 0 0 0 .696.598c.353 0 .637-.196.706-.588l.657-3.979h.882l.677 3.979c.068.392.353.588.706.588a.68.68 0 0 0 .696-.598l.617-3.969h.589c.274 0 .48-.225.48-.49a.463.463 0 0 0-.48-.48h-.432l.48-3.096c.02-.098.04-.177.04-.285 0-.343-.265-.548-.608-.548-.304 0-.569.176-.618.519l-.44 3.41h-.854l-.568-3.292c-.06-.402-.383-.628-.736-.628-.372 0-.666.226-.735.637l-.559 3.293h-.833l-.422-3.41c-.049-.343-.313-.52-.627-.52-.353 0-.628.216-.628.559 0 .118.03.216.04.304l.48 3.067h-.441a.469.469 0 0 0-.48.48Zm2.206.47h.569l-.333 2.303-.236-2.303Zm1.745-.95.265-1.92.284 1.92h-.549Zm1.697.95h.617l-.265 2.362-.352-2.362Z"
      fill="#DBDBDB"
    />
  </svg>
);
