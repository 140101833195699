import * as React from "react";
import { SVGProps } from "react";

export const Star = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.22103 20.4362C4.62719 20.747 5.14232 20.6402 5.75649 20.2031L10.997 16.4247L16.2473 20.2031C16.8615 20.6402 17.3667 20.747 17.7828 20.4362C18.1889 20.1351 18.2781 19.6397 18.0304 18.9307L15.96 12.8892L21.25 9.15939C21.8641 8.73201 22.1118 8.28523 21.9533 7.79956C21.7948 7.33333 21.3292 7.1002 20.5664 7.10994L14.0778 7.14878L12.1065 1.07815C11.8687 0.359382 11.5121 0 10.997 0C10.4917 0 10.1351 0.359382 9.89734 1.07815L7.926 7.14878L1.43737 7.10994C0.674579 7.1002 0.208985 7.33333 0.0504837 7.79956C-0.117923 8.28523 0.13964 8.73201 0.75383 9.15939L6.04377 12.8892L3.97338 18.9307C3.72572 19.6397 3.81488 20.1351 4.22103 20.4362Z"
      fill="#FBC900"
    />
  </svg>
);
