import { createSvgIcon } from "@mui/material";
import * as React from "react";
import { SVGProps } from "react";

interface LockComponentProps extends SVGProps<SVGSVGElement> {
  unlock?: boolean;
}

export const LockComponent = (props: LockComponentProps) => {
  const { unlock, ...rest } = props;
  return (
    <svg
      width={unlock ? 16 : 12}
      height={unlock ? 16 : 18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      {!unlock && (
        <path
          d="M1.877 17.516h8.247c1.186 0 1.876-.727 1.876-2.025V9.044c0-1.297-.69-2.019-1.877-2.019H1.877C.689 7.025 0 7.747 0 9.044v6.447c0 1.297.69 2.025 1.877 2.025Zm-.297-9.96h1.09V4.8c0-2.355 1.456-3.743 3.326-3.743 1.866 0 3.34 1.388 3.34 3.743v2.754h1.082V4.922C10.418 1.744 8.396 0 5.996 0 3.604 0 1.58 1.744 1.58 4.922v2.633Z"
          fill="#DBDBDB"
        />
      )}
      {unlock && (
        <path
          d="M1.633 15.588H8.81c1.033 0 1.633-.648 1.633-1.804v-5.74c0-1.152-.6-1.798-1.633-1.798H1.633C.6 6.246 0 6.892 0 8.044v5.74c0 1.156.6 1.804 1.633 1.804ZM8.309 6.72h.944V4.274c0-2.103 1.274-3.334 2.899-3.334 1.627 0 2.9 1.23 2.9 3.334V6.04c0 .354.213.546.476.546.252 0 .472-.175.472-.546V4.376C16 1.548 14.233 0 12.152 0c-2.087 0-3.843 1.548-3.843 4.376V6.72Z"
          fill="#DBDBDB"
        />
      )}
    </svg>
  );
};

export const Lock = createSvgIcon(
  <path
    d="M1.877 17.516h8.247c1.186 0 1.876-.727 1.876-2.025V9.044c0-1.297-.69-2.019-1.877-2.019H1.877C.689 7.025 0 7.747 0 9.044v6.447c0 1.297.69 2.025 1.877 2.025Zm-.297-9.96h1.09V4.8c0-2.355 1.456-3.743 3.326-3.743 1.866 0 3.34 1.388 3.34 3.743v2.754h1.082V4.922C10.418 1.744 8.396 0 5.996 0 3.604 0 1.58 1.744 1.58 4.922v2.633Z"
    fill="#DBDBDB"
  />,
  "Lock"
);

export const Unlock = createSvgIcon(
  <path
    d="M1.633 15.588H8.81c1.033 0 1.633-.648 1.633-1.804v-5.74c0-1.152-.6-1.798-1.633-1.798H1.633C.6 6.246 0 6.892 0 8.044v5.74c0 1.156.6 1.804 1.633 1.804ZM8.309 6.72h.944V4.274c0-2.103 1.274-3.334 2.899-3.334 1.627 0 2.9 1.23 2.9 3.334V6.04c0 .354.213.546.476.546.252 0 .472-.175.472-.546V4.376C16 1.548 14.233 0 12.152 0c-2.087 0-3.843 1.548-3.843 4.376V6.72Z"
    fill="#DBDBDB"
  />,
  "Unlock"
);
