import * as React from "react";
import { SVGProps } from "react";

export const Ability = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="29"
    height="28"
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <ellipse
      opacity="0.3"
      cx="14.0581"
      cy="14"
      rx="14.0581"
      ry="14"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.25 7.25C10.25 6.42157 10.9216 5.75 11.75 5.75H16.25C17.0784 5.75 17.75 6.42157 17.75 7.25V8.75C17.75 9.57843 17.0784 10.25 16.25 10.25H11.75C10.9216 10.25 10.25 9.57843 10.25 8.75V7.25ZM16.25 7.25H11.75V8.75H16.25V7.25Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 8.75C9.30109 8.75 9.11032 8.82902 8.96967 8.96967C8.82902 9.11032 8.75 9.30109 8.75 9.5V20C8.75 20.1989 8.82902 20.3897 8.96967 20.5303C9.11032 20.671 9.30109 20.75 9.5 20.75H18.5C18.6989 20.75 18.8897 20.671 19.0303 20.5303C19.171 20.3897 19.25 20.1989 19.25 20V9.5C19.25 9.30109 19.171 9.11032 19.0303 8.96967C18.8897 8.82902 18.6989 8.75 18.5 8.75H17C16.5858 8.75 16.25 8.41421 16.25 8C16.25 7.58579 16.5858 7.25 17 7.25H18.5C19.0967 7.25 19.669 7.48705 20.091 7.90901C20.5129 8.33097 20.75 8.90326 20.75 9.5V20C20.75 20.5967 20.5129 21.169 20.091 21.591C19.669 22.0129 19.0967 22.25 18.5 22.25H9.5C8.90326 22.25 8.33097 22.0129 7.90901 21.591C7.48705 21.169 7.25 20.5967 7.25 20V9.5C7.25 8.90326 7.48705 8.33097 7.90901 7.90901C8.33097 7.48705 8.90326 7.25 9.5 7.25H11C11.4142 7.25 11.75 7.58579 11.75 8C11.75 8.41421 11.4142 8.75 11 8.75H9.5Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7803 13.4697C17.0732 13.7626 17.0732 14.2374 16.7803 14.5303L13.7803 17.5303C13.4874 17.8232 13.0126 17.8232 12.7197 17.5303L11.2197 16.0303C10.9268 15.7374 10.9268 15.2626 11.2197 14.9697C11.5126 14.6768 11.9874 14.6768 12.2803 14.9697L13.25 15.9393L15.7197 13.4697C16.0126 13.1768 16.4874 13.1768 16.7803 13.4697Z"
      fill="white"
    />
  </svg>
);
