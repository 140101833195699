import * as React from "react";
import { SVGProps } from "react";

export const PlusDimmed = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={16} cy={16} r={15.5} fill="#fff" stroke="#F2F2F2" />
    <path
      d="M12 16h8M16 12v8"
      stroke="#D4D3D7"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
