import * as React from "react";
import { SVGProps } from "react";

export const OrderCreditcard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="34"
    height="26"
    viewBox="0 0 34 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.22056 20.9243H9.80546C10.6687 20.9243 11.2439 20.3073 11.2439 19.4319V16.551C11.2439 15.6697 10.6687 15.0585 9.80546 15.0585H6.22056C5.3618 15.0585 4.78662 15.6697 4.78662 16.551V19.4319C4.78662 20.3073 5.3618 20.9243 6.22056 20.9243ZM0.953868 9.20597H33.0835V5.72997H0.953868V9.20597ZM4.31326 25.9993H29.6822C32.5508 25.9993 34 24.4607 34 21.4654V4.57395C34 1.57864 32.5508 0.0292969 29.6822 0.0292969H4.31326C1.45926 0.0292969 0 1.569 0 4.57395V21.4654C0 24.4703 1.45926 25.9993 4.31326 25.9993ZM4.34707 24.0794C2.69788 24.0794 1.81146 23.1591 1.81146 21.3592V4.68014C1.81146 2.88018 2.69788 1.94912 4.34707 1.94912H29.653C31.2547 1.94912 32.1885 2.88018 32.1885 4.68014V21.3592C32.1885 23.1591 31.2547 24.0794 29.653 24.0794H4.34707Z"
      // fill="#664AE3"
      fill="currentColor"
    />
  </svg>
);
