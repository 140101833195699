import * as React from "react";
import { SVGProps } from "react";

export const Completion = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle opacity="0.3" cx="14" cy="14" r="14" fill="white" />
    <g clipPath="url(#clip0_836_672)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.71967 13.4697C10.0126 13.1768 10.4874 13.1768 10.7803 13.4697L14.5303 17.2197C14.8232 17.5126 14.8232 17.9874 14.5303 18.2803L10.7803 22.0303C10.6397 22.171 10.4489 22.25 10.25 22.25H6.5C6.08579 22.25 5.75 21.9142 5.75 21.5V17.75C5.75 17.5511 5.82902 17.3603 5.96967 17.2197L9.71967 13.4697ZM7.25 18.0607V20.75H9.93934L12.9393 17.75L10.25 15.0607L7.25 18.0607Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5303 10.4697C17.8232 10.7626 17.8232 11.2374 17.5303 11.5303L12.6553 16.4053C12.3624 16.6982 11.8876 16.6982 11.5947 16.4053C11.3018 16.1124 11.3018 15.6376 11.5947 15.3447L16.4697 10.4697C16.7626 10.1768 17.2374 10.1768 17.5303 10.4697Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2197 5.96967C17.5126 5.67678 17.9875 5.67678 18.2804 5.96967L22.0304 9.71967C22.3233 10.0126 22.3233 10.4874 22.0304 10.7803L21.6562 11.1545C21.6561 11.1546 21.656 11.1547 21.6559 11.1549C21.3403 11.4713 20.9654 11.7224 20.5527 11.8937C20.1398 12.0651 19.6971 12.1534 19.2501 12.1534C18.803 12.1534 18.3603 12.0651 17.9474 11.8937C17.5349 11.7225 17.1602 11.4716 16.8447 11.1553C16.5285 10.8399 16.2776 10.4652 16.1063 10.0526C15.9349 9.63973 15.8467 9.19706 15.8467 8.75C15.8467 8.30294 15.9349 7.86027 16.1063 7.44737C16.2776 7.03466 16.5287 6.65981 16.8451 6.34428L17.2197 5.96967ZM17.7587 7.56934C17.6493 7.70698 17.5594 7.85944 17.4917 8.02248C17.396 8.25309 17.3467 8.50032 17.3467 8.75C17.3467 8.99968 17.396 9.24691 17.4917 9.47752C17.5873 9.70776 17.7273 9.91691 17.9037 10.093M17.7587 7.56934L20.4307 10.2413C20.2931 10.3507 20.1406 10.4407 19.9776 10.5084C19.747 10.6041 19.4997 10.6534 19.2501 10.6534C19.0004 10.6534 18.7531 10.6041 18.5225 10.5084C18.2919 10.4126 18.0825 10.2723 17.9062 10.0955C17.9057 10.0949 17.9051 10.0944 17.9046 10.0938"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_836_672">
        <rect width="18" height="18" fill="white" transform="translate(5 5)" />
      </clipPath>
    </defs>
  </svg>
);
