import * as React from "react";
import { SVGProps } from "react";

interface MyPriceProps extends SVGProps<SVGSVGElement> {
  myprice: number;
}

export const MyPrice = (props: MyPriceProps) => {
  const { myprice, ...rest } = props;
  return (
    <svg
      width={142}
      height={65}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g filter="url(#myprice)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 4a2 2 0 0 0-2 2v38a2 2 0 0 0 2 2h56.983L69 57l6.017-11H132a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H6Z"
          fill="#EBE7FD"
          fillOpacity={0.4}
          shapeRendering="crispEdges"
        />
      </g>
      <text x="12" y="20" fontSize="12" fontWeight="400" fill="#664AE3">
        나의 입찰가
      </text>
      <text x="12" y="37" fontSize="14" fontWeight="500" fill="#664AE3">
        {myprice}원
      </text>
      <defs>
        <filter
          id="myprice"
          x={0}
          y={0}
          width={142}
          height={65}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={1} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.4 0 0 0 0 0.286275 0 0 0 0 0.886275 0 0 0 0.1 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_858_9889"
          />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx={2} dy={2} />
          <feGaussianBlur stdDeviation={3} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.4 0 0 0 0 0.286275 0 0 0 0 0.886275 0 0 0 0.1 0" />
          <feBlend
            in2="effect1_dropShadow_858_9889"
            result="effect2_dropShadow_858_9889"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_858_9889"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
