import * as React from "react";
import { SVGProps } from "react";

export const ArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={12} cy={12} r={11.5} fill="#fff" stroke="#DBDBDB" />
    <path
      d="m14 7-5 5 5 5"
      stroke="#DBDBDB"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
