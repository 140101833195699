import * as React from "react";
import { SVGProps } from "react";
import { createSvgIcon } from "@mui/material/utils";

export const MaterialCategory01 = createSvgIcon(
  <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
    <defs>
      <style>
        {
          ".cls-1{fill:#ea6809}.cls-1,.cls-2,.cls-3{isolation:isolate}.cls-2{fill:#f9d793}.cls-3{fill:#f7d292}"
        }
      </style>
    </defs>
    <g id="Layer_2-2">
      <path
        className="cls-1"
        d="M8.56 0v12H.03V4c0-2.21 1.79-4 4-4h4.54ZM10.56 0h18.58v12H10.56zM.03 28.1h8.53V40H4.03c-2.21 0-4-1.79-4-4v-7.9ZM10.56 28.1h18.58V40H10.56zM.03 14h18.99v12.1H.03z"
      />
      <path
        className="cls-1"
        d="M40 4v32c0 2.21-1.79 4-4 4h-4.87V28.1h8.83v-2H21.01V14h18.96v-2h-8.83V0h4.87c2.21 0 4 1.79 4 4Z"
      />
      <path className="cls-2" d="M0 12h.03v2H0zM19.02 11.98h2V12h-2z" />
      <path
        className="cls-3"
        d="M39.97 12v2H21.01v12.1h-1.99V14H.03v-2h8.53V0h2v12h18.58V0h2v12h8.83zM39.97 26.1v2h-8.83V40h-2V28.1H10.56V40h-2V28.1H.03v-2h39.94z"
      />
      <path className="cls-2" d="M0 26.1h.03v2H0z" />
    </g>
  </svg>,
  "Cat01"
);

export const MaterialCategory001 = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 226 226">
    <g
      fill="none"
      strokeMiterlimit={10}
      fontFamily="none"
      fontWeight="none"
      fontSize="none"
      textAnchor="none"
      style={{
        mixBlendMode: "normal",
      }}
    >
      <path d="M0 226V0h226v226z" />
      <path
        d="M183.625 193.042H42.375c-10.4 0-18.833-8.433-18.833-18.834V51.792c0-10.401 8.432-18.834 18.833-18.834h141.25c10.4 0 18.833 8.433 18.833 18.834v122.416c0 10.401-8.432 18.834-18.833 18.834z"
        fill="#e65100"
      />
      <path
        d="M23.542 80.042h178.916v9.416H23.542zm0 56.5h178.916v9.416H23.542z"
        fill="#ffe0b2"
      />
      <path
        d="M108.292 84.75h9.416v56.5h-9.416zM61.208 32.958h9.417V84.75h-9.417zm0 108.292h9.417v51.792h-9.417zm94.167-108.292h9.417V84.75h-9.417zm0 108.292h9.417v51.792h-9.417z"
        fill="#ffe0b2"
      />
    </g>
  </svg>,
  "Cat1"
);

export const MaterialCategory1 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 226 226" {...props}>
    <g
      fill="none"
      strokeMiterlimit={10}
      fontFamily="none"
      fontWeight="none"
      fontSize="none"
      textAnchor="none"
      style={{
        mixBlendMode: "normal",
      }}
    >
      <path d="M0 226V0h226v226z" />
      <path
        d="M183.625 193.042H42.375c-10.4 0-18.833-8.433-18.833-18.834V51.792c0-10.401 8.432-18.834 18.833-18.834h141.25c10.4 0 18.833 8.433 18.833 18.834v122.416c0 10.401-8.432 18.834-18.833 18.834z"
        fill="#e65100"
      />
      <path
        d="M23.542 80.042h178.916v9.416H23.542zm0 56.5h178.916v9.416H23.542z"
        fill="#ffe0b2"
      />
      <path
        d="M108.292 84.75h9.416v56.5h-9.416zM61.208 32.958h9.417V84.75h-9.417zm0 108.292h9.417v51.792h-9.417zm94.167-108.292h9.417V84.75h-9.417zm0 108.292h9.417v51.792h-9.417z"
        fill="#ffe0b2"
      />
    </g>
  </svg>
);

export const MaterialCategory02 = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.81 40">
    <path
      style={{
        fill: "#b5d6f2",
        isolation: "isolate",
      }}
      d="M4.64 3.6H30.9v33.69H4.64z"
    />
    <path
      d="M5.58 18.96 17.96 3.71H30.4l-.49 17.35-11.95 15.18H5.58s.22-17.28 0-17.28Z"
      style={{
        fill: "#99c6eb",
        isolation: "isolate",
      }}
    />
    <path
      d="M34.31 5c.83 0 1.5-.68 1.5-1.5v-2c0-.83-.67-1.5-1.5-1.5H1.5C.67 0 0 .67 0 1.5v2C0 4.32.67 5 1.5 5h2.41v30H1.5c-.83 0-1.5.67-1.5 1.5v2c0 .82.67 1.5 1.5 1.5h32.81c.83 0 1.5-.68 1.5-1.5v-2c0-.83-.67-1.5-1.5-1.5h-2.4V5h2.4Zm-17.4 30h-11V21.06h11V35Zm0-16.07h-11V5h11v13.93Zm13 16.07h-11V21.06h11V35Zm0-16.07h-11V5h11v13.93Z"
      style={{
        isolation: "isolate",
        fill: "#5e403b",
      }}
    />
  </svg>,
  "Cat02"
);

export const MaterialCategory002 = createSvgIcon(
  <svg
    viewBox="0 0 1890 1890"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 1.41421,
    }}
  >
    <path
      style={{
        fill: "#c9b298",
      }}
      d="M130.043 956.805h81.533v137.582h-81.533z"
      transform="matrix(10.2545 0 0 10.11261 -883.673 -9426.576)"
    />
    <path
      style={{
        fill: "#90c3e6",
      }}
      d="M137.69 964.121h26.787v29.549H137.69z"
      transform="matrix(12.03876 0 0 10.91374 -1147.958 -10206.004)"
    />
    <path
      d="M167.848 995.316h-6.037v-25.631h-25.853v-6.259h31.89v31.89Z"
      style={{
        fill: "#55768c",
      }}
      transform="matrix(10.11261 0 0 10.11261 -865.222 -9426.576)"
    />
    <path
      style={{
        fill: "#90c3e6",
      }}
      d="M137.69 964.121h26.787v29.549H137.69z"
      transform="matrix(12.03876 0 0 29.49991 -1147.958 -27739.51)"
    />
    <path
      d="M167.848 1087.77h-6.037v-80.29h-25.853v-5.91h31.89v86.2Z"
      style={{
        fill: "#55768c",
      }}
      transform="matrix(10.11261 0 0 10.11261 -865.222 -9426.576)"
    />
    <path
      style={{
        fill: "#90c3e6",
      }}
      d="M137.69 964.121h26.787v29.549H137.69z"
      transform="matrix(12.03876 0 0 29.49991 -748.643 -27739.51)"
    />
    <path
      d="M167.848 1087.77h-6.037v-80.29h-25.853v-5.91h31.89v86.2Z"
      style={{
        fill: "#55768c",
      }}
      transform="matrix(10.11261 0 0 10.11261 -465.906 -9426.576)"
    />
    <path
      style={{
        fill: "#90c3e6",
      }}
      d="M137.69 964.121h26.787v29.549H137.69z"
      transform="matrix(11.8352 0 0 10.91374 -715.148 -10206.004)"
    />
    <path
      d="M167.848 995.316h-6.037v-25.631h-25.853v-6.259h31.89v31.89Z"
      style={{
        fill: "#55768c",
      }}
      transform="matrix(9.94155 0 0 10.11261 -437.194 -9426.576)"
    />
  </svg>,
  "Cat2"
);

export const MaterialCategory03 = createSvgIcon(
  <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
    <defs>
      <style>{".cls-3{isolation:isolate;fill:#f49a19}"}</style>
    </defs>
    <g id="Layer_2-2">
      <ellipse className="cls-3" cx={20} cy={3.19} rx={3.82} ry={3.19} />
      <path
        style={{
          fill: "#f08113",
          isolation: "isolate",
        }}
        d="M17.5 28.55h5v6.73h-5z"
      />
      <path
        d="M38.73 30.27c-.21.07-.44.11-.67.11H2.03c-.6 0-1.15-.25-1.54-.66-.57-.58-.63-1.48-.28-2.21l3.58-6.15c-.11-.73-.16-1.48-.16-2.24.01-9.01 7.33-16.32 16.36-16.32s16.36 7.31 16.36 16.32c0 .98-.09 1.94-.26 2.88l3.63 5.36c.61 1.07.19 2.52-.99 2.92Z"
        style={{
          isolation: "isolate",
          fill: "#fcbf16",
        }}
      />
      <rect
        className="cls-3"
        y={34.23}
        width={40}
        height={5.77}
        rx={1.5}
        ry={1.5}
      />
    </g>
  </svg>,
  "Cat03"
);

export const MaterialCategory003 = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 361.061 361.061"
    // style={{
    //   enableBackground: "new 0 0 361.061 361.061",
    // }}
    xmlSpace="preserve"
  >
    <path
      style={{
        fill: "#f7b239",
      }}
      d="M235.815 320.719a61.345 61.345 0 0 1-12.161 16.831c-11.029 10.829-26.16 17.51-42.84 17.51-16.76 0-31.949-6.74-42.99-17.651a60.887 60.887 0 0 1-12.1-16.86h56.37l53.721.17"
    />
    <path
      style={{
        fill: "#4cc18e",
      }}
      d="M125.725 6h110.18c-9.919 20.42-30.85 34.5-55.069 34.51h-.021c-24.24 0-45.181-14.08-55.09-34.51"
    />
    <path
      style={{
        fill: "#a5a5a5",
      }}
      d="M197.317 38.258a61.252 61.252 0 0 1-16.482 2.252c5.71 0 11.24-.79 16.49-2.25l-.008-.002"
    />
    <path
      style={{
        fill: "#418366",
      }}
      d="M158.725 6h-33c9.91 20.43 30.85 34.51 55.09 34.51h.02a61.257 61.257 0 0 0 16.482-2.252C180.311 33.516 166.293 21.607 158.725 6"
    />
    <path
      style={{
        fill: "#f24125",
      }}
      d="m212.174 145.89 9.94 78.72a179.72 179.72 0 0 0-41.26-4.92 178.552 178.552 0 0 0-41.301 4.669l10.451-78.669 62.17.2"
    />
    <path
      style={{
        fill: "#a5a5a5",
      }}
      d="m150.004 145.69-10.45 78.669 10.451-78.669h-.001"
    />
    <path
      style={{
        fill: "#9e3b2b",
      }}
      d="m150.005 145.69-10.451 78.669.265-.062.095-.022.174-.04.269-.062.039-.009.527-.119.738-.165.05-.011a178.049 178.049 0 0 1 25.4-3.704l9.881-74.388-26.987-.087"
    />
    <path
      style={{
        fill: "#4cc18e",
      }}
      d="m341.525 321.06-105.71-.341.09-.17h-53.81l-162.561-.52c23.321-47.641 67.191-83.38 120.02-95.671a178.552 178.552 0 0 1 41.301-4.669 179.72 179.72 0 0 1 41.26 4.92c52.759 12.641 96.4 48.651 119.41 96.451"
    />
    <path
      style={{
        fill: "#a17a36",
      }}
      d="M182.095 320.55h-56.37c3.05 6.3 7.16 12 12.1 16.86l44.27.141h41.559a61.345 61.345 0 0 0 12.161-16.831l-53.72-.17"
    />
    <path
      style={{
        fill: "#a5a5a5",
      }}
      d="M180.163 219.689a180.754 180.754 0 0 1 0 0m.054 0h.002-.002"
    />
    <path
      style={{
        fill: "#9e3b2b",
      }}
      d="M185.685 219.771h.004-.004m-.843-.023.271.007-.271-.007m-.325-.008.318.008a33.74 33.74 0 0 0-.318-.008m-3.662-.05.328.001-.328-.001m-.692-.001a180.182 180.182 0 0 1 0 0m.054 0h.002-.002"
    />
    <path
      style={{
        fill: "#737373",
      }}
      d="M167.11 220.166"
    />
    <path
      style={{
        fill: "#418366",
      }}
      d="M180.217 219.689h-.054c-4.389.001-8.742.162-13.052.476a178.049 178.049 0 0 0-25.4 3.704l-.05.011-.738.165c-.456.103-.913.208-1.368.314-52.829 12.291-96.7 48.03-120.02 95.671h53.998c23.32-47.641 67.19-83.38 120.02-95.671a177.193 177.193 0 0 1 13.761-2.632 180.372 180.372 0 0 0-21.624-1.956h-.004l-.314-.009-.137-.004-.119-.003-.271-.007h-.007l-.318-.008h-.012a182.465 182.465 0 0 0-3.313-.048h-.008l-.328-.001-.635-.001-.007-.001"
    />
    <path
      style={{
        fill: "#fff",
      }}
      d="M300.955 301.257a5.988 5.988 0 0 1-4.561-2.098 153.86 153.86 0 0 0-19.813-19.329c-11.273-9.159-23.836-16.674-37.34-22.338a6.001 6.001 0 0 1 4.641-11.066 165.058 165.058 0 0 1 40.266 24.09 165.797 165.797 0 0 1 21.361 20.839 6 6 0 0 1-4.554 9.902zM217.463 250.059c-1.59 0-3.13-.64-4.25-1.76-.27-.27-.53-.58-.74-.91a5.762 5.762 0 0 1-.9-2.15c-.08-.39-.11-.79-.11-1.18 0-1.58.63-3.13 1.75-4.24a6.07 6.07 0 0 1 5.42-1.64c.38.08.76.19 1.12.34s.71.34 1.04.55c.32.22.63.47.91.75a6.044 6.044 0 0 1 1.75 4.24c0 .39-.04.79-.11 1.18a6.32 6.32 0 0 1-.9 2.15 6.12 6.12 0 0 1-.74.91c-.28.28-.59.53-.91.75-.33.22-.68.4-1.04.55s-.74.27-1.12.34c-.39.08-.781.12-1.17.12zM202.295 205.701a6 6 0 0 1-5.945-5.249l-4.299-34.046a6 6 0 1 1 11.906-1.503l4.299 34.046a6 6 0 0 1-5.961 6.752z"
    />
    <path
      style={{
        fill: "#333",
      }}
      d="M346.931 318.458c-11.743-24.394-29.093-46.339-50.172-63.464-20.239-16.442-44.089-28.55-69.203-35.188l-9.429-74.667a6.001 6.001 0 0 0-5.934-5.249l-25.359-.082V46.236c23.48-2.059 44.002-16.072 54.467-37.615A6 6 0 0 0 235.905 0H125.724a6 6 0 0 0-5.398 8.619c10.287 21.207 31.252 35.49 54.509 37.608v93.543l-24.811-.08h-.02a5.998 5.998 0 0 0-5.947 5.21l-9.912 74.62c-25.15 6.471-49.074 18.424-69.42 34.738-21.188 16.988-38.677 38.819-50.579 63.134a6 6 0 0 0 5.369 8.639l102.48.328c11.75 21.19 34.449 34.702 58.82 34.702 24.592 0 46.711-13.065 58.609-34.327l102.082.327h.02a6 6 0 0 0 5.405-8.603zM136.296 12h89.032c-10.283 14.078-26.587 22.502-44.513 22.51-17.932 0-34.242-8.425-44.519-22.51zm18.962 139.707 51.624.166 8.222 65.115a186.774 186.774 0 0 0-34.228-3.299 187.164 187.164 0 0 0-34.263 3.088l8.645-65.07zm25.557 197.353c-17.928 0-34.237-8.424-44.518-22.51h45.739l43.177.138c-10.292 13.998-26.554 22.372-44.398 22.372zM29.448 314.062c10.848-19.282 25.5-36.583 42.784-50.442 20.042-16.071 43.792-27.625 68.688-33.417 13.009-3.042 26.46-4.537 39.913-4.512a174.12 174.12 0 0 1 39.884 4.755c24.859 5.956 48.538 17.665 68.476 33.862 17.196 13.97 31.737 31.368 42.46 50.722l-302.205-.968z"
    />
  </svg>,
  "Cat3"
);
