import { Grid } from "@mui/material";
import {
  OrderMaterialImageOne,
  OrderMaterialImageTwo,
  OrderMaterialImageThree,
} from "Components/index";
import { colors } from "Context";
import { Body, Detail, Row } from "../Styles";

export const QuotationAccordianContent = (props: { id: string }) => {
  return (
    <>
      <Grid
        container
        sx={{ alignItems: "center" }}
        columnSpacing={{ lg: 0, xs: 10 }}
      >
        <Grid item xs={1} sx={{ pt: 1 }}>
          <OrderMaterialImageOne id={props.id} />
        </Grid>
        <Grid container item lg={9} md={8} xs={9} direction="column">
          <Detail sx={{ color: colors.grey._60 }}>자재1번가</Detail>
          <Body>그레이 매트 포쉐린 타일 (300*600)</Body>
          <Detail>그레이 | 100개</Detail>
        </Grid>
        <Grid item md={2} xs={12}>
          <Row sx={{ alignItems: "center", justifyContent: "right" }}>
            <Body>4,550,000</Body>
            <Detail>&nbsp;원</Detail>
          </Row>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{ alignItems: "center" }}
        columnSpacing={{ lg: 0, xs: 10 }}
      >
        <Grid item xs={1} sx={{ pt: 1 }}>
          <OrderMaterialImageTwo id={props.id} />
        </Grid>
        <Grid container item lg={9} md={8} xs={9} direction="column">
          <Detail sx={{ color: colors.grey._60 }}>vittz</Detail>
          <Body>스톤 룩 루체 포쉐린 타일 (100*80)</Body>
          <Detail>그레이 | 100개</Detail>
        </Grid>
        <Grid item md={2} xs={12}>
          <Row sx={{ alignItems: "center", justifyContent: "right" }}>
            <Body>4,550,000</Body>
            <Detail>&nbsp;원</Detail>
          </Row>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{ alignItems: "center" }}
        columnSpacing={{ lg: 0, xs: 10 }}
      >
        <Grid item xs={1} sx={{ pt: 1 }}>
          <OrderMaterialImageThree id={props.id} />
        </Grid>
        <Grid container item lg={9} md={8} xs={9} direction="column">
          <Detail sx={{ color: colors.grey._60 }}>쌍쌍곰</Detail>
          <Body>타일부자재 압착시멘트 25KG</Body>
          <Detail>화이트 | 12개</Detail>
        </Grid>
        <Grid item md={2} xs={12}>
          <Row sx={{ alignItems: "center", justifyContent: "right" }}>
            <Body>4,550,000</Body>
            <Detail>&nbsp;원</Detail>
          </Row>
        </Grid>
      </Grid>
    </>
  );
};
