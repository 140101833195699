import * as React from "react";
import { SVGProps } from "react";

export const SettingIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.1917 10.817C16.225 10.5503 16.25 10.2837 16.25 10.0003C16.25 9.71699 16.225 9.45033 16.1917 9.18366L17.95 7.80866C18.1083 7.68366 18.15 7.45866 18.05 7.27533L16.3833 4.39199C16.2833 4.20866 16.0583 4.14199 15.875 4.20866L13.8 5.04199C13.3667 4.70866 12.9 4.43366 12.3917 4.22533L12.075 2.01699C12.05 1.81699 11.875 1.66699 11.6667 1.66699H8.33332C8.12499 1.66699 7.94999 1.81699 7.92499 2.01699L7.60832 4.22533C7.09999 4.43366 6.63332 4.71699 6.19999 5.04199L4.12499 4.20866C3.93332 4.13366 3.71666 4.20866 3.61666 4.39199L1.94999 7.27533C1.84165 7.45866 1.89165 7.68366 2.04999 7.80866L3.80832 9.18366C3.77499 9.45033 3.74999 9.72533 3.74999 10.0003C3.74999 10.2753 3.77499 10.5503 3.80832 10.817L2.04999 12.192C1.89165 12.317 1.84999 12.542 1.94999 12.7253L3.61666 15.6087C3.71666 15.792 3.94165 15.8587 4.12499 15.792L6.19999 14.9587C6.63332 15.292 7.09999 15.567 7.60832 15.7753L7.92499 17.9837C7.94999 18.1837 8.12499 18.3337 8.33332 18.3337H11.6667C11.875 18.3337 12.05 18.1837 12.075 17.9837L12.3917 15.7753C12.9 15.567 13.3667 15.2837 13.8 14.9587L15.875 15.792C16.0667 15.867 16.2833 15.792 16.3833 15.6087L18.05 12.7253C18.15 12.542 18.1083 12.317 17.95 12.192L16.1917 10.817ZM9.99999 12.917C8.39165 12.917 7.08332 11.6087 7.08332 10.0003C7.08332 8.39199 8.39165 7.08366 9.99999 7.08366C11.6083 7.08366 12.9167 8.39199 12.9167 10.0003C12.9167 11.6087 11.6083 12.917 9.99999 12.917Z"
      fill={props.color ? props.color : "#6E7191"}
    />
  </svg>
);
export const SettingIconOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.66602 0L7.17578 2.52344C6.35161 2.83425 5.59479 3.26993 4.93164 3.81445L2.50781 2.97852L0.171875 7.02148L2.11328 8.70898C2.03865 9.16721 2 9.59185 2 10C2 10.4088 2.03981 10.8326 2.11328 11.291V11.293L0.171875 12.9805L2.50781 17.0215L4.92969 16.1875C5.5929 16.7323 6.35143 17.1656 7.17578 17.4766L7.66602 20H12.334L12.8242 17.4766C13.6489 17.1655 14.4049 16.7306 15.0684 16.1855L17.4922 17.0215L19.8262 12.9805L17.8867 11.291C17.9614 10.8328 18 10.4082 18 10C18 9.59246 17.9611 9.16837 17.8867 8.71094V8.70898L19.8281 7.01953L17.4922 2.97852L15.0703 3.8125C14.4071 3.26768 13.6486 2.83443 12.8242 2.52344L12.334 0H7.66602ZM9.31445 2H10.6855L11.0742 4L12.1172 4.39453C12.7459 4.63147 13.3107 4.95675 13.8008 5.35938L14.6641 6.06641L16.5859 5.40625L17.2715 6.5918L15.7363 7.92773L15.9121 9.02734V9.0293C15.9733 9.40423 16 9.71877 16 10C16 10.2812 15.9733 10.5957 15.9121 10.9707L15.7344 12.0703L17.2695 13.4062L16.584 14.5938L14.6641 13.9316L13.7988 14.6406C13.3087 15.0432 12.7459 15.3685 12.1172 15.6055H12.1152L11.0723 16L10.6836 18H9.31445L8.92578 16L7.88281 15.6055C7.25415 15.3685 6.68933 15.0432 6.19922 14.6406L5.33594 13.9336L3.41406 14.5938L2.72852 13.4082L4.26562 12.0703L4.08789 10.9746V10.9727C4.02762 10.5961 4 10.2807 4 10C4 9.71877 4.02674 9.40428 4.08789 9.0293L4.26562 7.92969L2.72852 6.59375L3.41406 5.40625L5.33594 6.06836L6.19922 5.35938C6.68933 4.95675 7.25415 4.63147 7.88281 4.39453L8.92578 4L9.31445 2ZM10 6C7.80348 6 6 7.80348 6 10C6 12.1965 7.80348 14 10 14C12.1965 14 14 12.1965 14 10C14 7.80348 12.1965 6 10 6ZM10 8C11.1115 8 12 8.88852 12 10C12 11.1115 11.1115 12 10 12C8.88852 12 8 11.1115 8 10C8 8.88852 8.88852 8 10 8Z"
      fill="#D9DBE9"
    />
  </svg>
);
