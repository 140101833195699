import * as React from "react";
import { SVGProps } from "react";

export const TutorialArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="23"
    height="20"
    viewBox="0 0 23 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.88154 0C0.382231 0 0.159509 0.327979 0.0839975 0.718568C0.0169717 1.03247 0 1.65669 0 2.38142C0 8.10547 2.3973 10.5247 7.97504 10.5247H17.5361L20.492 10.4054L16.8583 13.8136L13.2284 17.4963C13.0774 17.6565 13.0018 17.8716 13.0018 18.0991C13.0018 18.5672 13.3586 18.9084 13.8287 18.9084C14.0467 18.9084 14.2563 18.8164 14.5001 18.5839L22.7064 10.3296C22.9075 10.1324 23 9.91724 23 9.68128C23 9.4538 22.9075 9.23018 22.7064 9.04531L14.4336 0.719554C14.2525 0.542352 14.0467 0.466531 13.8287 0.466531C13.3586 0.466531 13.0018 0.807721 13.0018 1.27586C13.0018 1.50332 13.0897 1.72226 13.2284 1.87007L16.8583 5.55278L20.5005 8.97804L17.5361 8.8502H8.04764C3.47697 8.8502 1.68462 6.95497 1.68462 2.28943C1.68462 1.61631 1.71771 1.17139 1.71771 0.830197C1.71771 0.341192 1.36855 0 0.88154 0Z"
      fill="white"
      fillOpacity="0.85"
    />
  </svg>
);
