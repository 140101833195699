import * as React from "react";
import { SVGProps } from "react";

export const DashboardWeather = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={34}
    height={34}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.38 3.282V1.914a1.623 1.623 0 0 1 1.644-1.58c.877 0 1.589.702 1.607 1.58.01.897.01 1.802 0 2.699-.009.896-.739 1.58-1.644 1.58-.878 0-1.59-.693-1.608-1.58a32 32 0 0 1-.002-.887l.002-.444Zm1.606 22.069c4.6.018 8.359-3.734 8.359-8.345v.009c.01-4.603-3.75-8.364-8.35-8.355-4.609.01-8.303 3.706-8.34 8.327-.037 4.584 3.713 8.345 8.331 8.364ZM.343 16.673c.212-.61.572-1.09 1.237-1.248.139-.028.277-.046.416-.046h2.54c.96 0 1.662.702 1.662 1.627 0 .924-.71 1.617-1.662 1.626h-2.41c-.924 0-1.423-.351-1.737-1.22-.006-.018-.02-.037-.035-.055a.448.448 0 0 1-.02-.028v-.647l.009-.01Zm16.339 16.995c-.61-.213-1.09-.564-1.248-1.239a2.125 2.125 0 0 1-.046-.415v-2.542c0-.961.702-1.663 1.626-1.663.924 0 1.617.702 1.626 1.663v2.44c0 .887-.37 1.405-1.22 1.7-.018.007-.036.021-.055.035l-.01.008a.377.377 0 0 1-.017.013h-.657Zm12.727-15.045h2.66a1.623 1.623 0 0 0 1.598-1.626 1.61 1.61 0 0 0-1.598-1.627c-.887-.009-1.774-.009-2.66 0a1.61 1.61 0 0 0-1.598 1.627c0 .896.702 1.608 1.598 1.626ZM9.856 8.024v.008c-.02-.045-.039-.104-.06-.171-.062-.194-.145-.454-.31-.632a31.004 31.004 0 0 0-2.133-2.135c-.591-.536-1.552-.443-2.124.12-.573.564-.684 1.525-.148 2.126a29.701 29.701 0 0 0 2.152 2.163c.48.434 1.09.462 1.672.185.607-.295.912-.801.95-1.656v.001-.009Zm14.482.894c.287.6.794.887 1.644.934.043-.018.098-.037.16-.058.195-.065.464-.155.653-.33a31.923 31.923 0 0 0 2.097-2.09c.554-.61.48-1.561-.093-2.152-.572-.583-1.542-.684-2.152-.14a29.066 29.066 0 0 0-2.133 2.136c-.434.49-.471 1.1-.176 1.7ZM4.748 27.642c0-.471.176-.86.508-1.192l.443-.444c.44-.443.88-.885 1.33-1.322.702-.684 1.71-.684 2.356-.027.637.656.628 1.636-.046 2.329a81.91 81.91 0 0 1-1.792 1.793c-.517.499-1.22.628-1.829.35-.6-.267-.97-.84-.97-1.487Zm23.608 1.453c.591-.286.887-.795.914-1.654l.01.009c-.02-.044-.04-.105-.063-.176-.062-.192-.147-.454-.316-.637a31.923 31.923 0 0 0-2.088-2.098c-.61-.564-1.56-.48-2.152.092-.59.573-.692 1.534-.138 2.154.674.748 1.385 1.46 2.133 2.134.49.444 1.109.463 1.7.176Z"
      fill="#FFC121"
    />
  </svg>
);
