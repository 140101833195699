import React from "react";
import { Paper, styled } from "@mui/material";
import { CalculatorIcon, BurgerDots, DoughnutGraph } from "Components";

const StyledPaper = styled(Paper)(() => ({
  minHeight: "466px",
  height: "100%",
  width: "100%",
  padding: 24,
  border: "1px solid #F2F2F2",
  boxShadow:
    "2px 2px 6px rgba(102, 73, 226, 0.1), 0px 0px 2px rgba(102, 73, 226, 0.1)",
  borderRadius: "8px",
}));

const ChartLabel = styled(`div`)((props: { color: string }) => ({
  display: "flex",
  height: 16,
  marginBottom: 16,
  justifyContent: "space-between",
  alignItems: "center",
  padding: 0,
  div: {
    display: "flex",
    alignItems: "center",
    div: {
      width: 12,
      height: 12,
      borderRadius: 50,
      background: props.color,
    },
    p: {
      marginLeft: 4,
      fontWeight: 500,
      fontSize: 12,
      color: "#7d7b88",
    },
  },
  "div:last-of-type": {
    fontWeight: 500,
    fontSize: 12,
  },
}));

const LABEL_DATA = [
  {
    id: 1,
    name: "철근",
    percentage: "33%",
    color: "#664ae3",
  },
  {
    id: 2,
    name: "레미콘",
    percentage: "40%",
    color: "#58D9C9",
  },
  {
    id: 3,
    name: "외장재(스타콘)",
    percentage: "20%",
    color: "#FF7764",
  },
  {
    id: 4,
    name: "단열재",
    percentage: "7%",
    color: "#FFD964",
  },
];
export const AccumGraph = () => {
  return (
    <StyledPaper>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: 500,
            fontSize: "18px",
            maxWidth: "90%",
            marginBottom: 30,
          }}
        >
          <CalculatorIcon />
          <span
            style={{
              marginLeft: 2,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            누적 비용 대비 자재 비중
          </span>
        </div>
        <BurgerDots />
      </div>
      <div>
        <div
          style={{
            maxWidth: 210,
            maxHeight: 210,
            margin: "auto",
          }}
        >
          <DoughnutGraph />
        </div>
      </div>
      {LABEL_DATA.map((item) => {
        return (
          <ChartLabel color={item.color} key={item.id}>
            <div>
              <div />
              <p>{item.name}</p>
            </div>
            <div>{item.percentage}</div>
          </ChartLabel>
        );
      })}
    </StyledPaper>
  );
};
