import React from "react";
import {
  Box,
  styled,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  Typography,
} from "@mui/material";
import { colors } from "Context";

export const Container = styled("div")(() => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 36,
}));

export const MonthContainer = styled(Box)(() => ({
  minWidth: 54,
  color: colors.grey._60,
  fontSize: "1rem",
  fontWeight: 500,
}));

export const ChartContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  alignItems: "flex-start",
  gap: 4,
}));

export const Bar = styled(Box)(() => ({
  borderRadius: "0 4px 4px 0",
  height: 16,
}));

interface CustomTooltipProps extends TooltipProps {
  borderColor: string;
  textColor: string;
}

export function CustomTooltip(props: CustomTooltipProps) {
  const { borderColor, textColor, children, ...rest } = props;
  const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      "&:before": {
        border: `1px solid ${borderColor}`,
      },
      color: colors.white,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      color: textColor,
      background: colors.white,
      border: `1px solid ${borderColor}`,
    },
  }));
  return <CustomTooltip {...rest}>{children}</CustomTooltip>;
}

export const ChartZeroTypo = styled(Typography)(() => ({
  marginLeft: 12,
  color: colors.grey._60,
}));
