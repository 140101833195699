import * as React from "react";
import { SVGProps } from "react";

export function DashboardDailyRegistPhotoClicked(
  props: SVGProps<SVGSVGElement>
) {
  return (
    <svg
      width="66"
      height="58"
      // viewBox="0 0 66 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="29"
        cy="29"
        r="27.5"
        fill="white"
        stroke="#664AE3"
        strokeWidth="3"
      />
      <path
        d="M41 17.5C42.65 17.5 44 18.85 44 20.5V38.5C44 40.15 42.65 41.5 41 41.5H17C15.35 41.5 14 40.15 14 38.5V20.5C14 18.85 15.35 17.5 17 17.5H21.755L24.5 14.5H33.5L36.245 17.5H41ZM41 38.5V20.5H17V38.5H41ZM32 28L27.5 33.58L24.5 29.5L20 35.5H38L32 28Z"
        fill="#664AE3"
      />
      <circle cx="56" cy="10" r="10" fill="#FF794F" />
      <path
        d="M52 9.80818L55.1918 13L60 7"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
