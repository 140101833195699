import React from "react";
import { Typography, useTheme, useMediaQuery } from "@mui/material";
import { colors } from "Context";
import { useScroll } from "framer-motion";
import { Gallery } from "./Gallery";
import { Information } from "./Information";
import { Pricing } from "./Pricing";
import { Location } from "./Location";
import { Participation } from "./Participation";
import { Tutorial } from "./Tutorial";
import { useNavigate, useParams } from "react-router-dom";
import { PROJECT_DATA } from "../ProjectList";
import {
  Container,
  Wrapper,
  LeftContainer,
  RightContainer,
  TitleContainer,
  BadgeContainer,
  Title,
  AddressContainer,
} from "./Styles";
import { throttle } from "lodash";

export function ConstructorBidding() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { id } = useParams();
  const project = PROJECT_DATA.find((item) => `${item.id}` === id);
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const [active, setActive] = React.useState(false);
  const [scrollYValue, setScrollYValue] = React.useState(0);
  const [tutorialOpen, setTutorial] = React.useState(false);
  const handleActive = () => {
    setActive(true);
  };
  const { scrollY } = useScroll();
  const handleTutorialOpen = () => {
    setTutorial(true);
  };
  const handleNext = () => {
    navigate("/partner/manage-construction");
  };
  const update = React.useMemo(
    () =>
      throttle((value: number) => {
        setScrollYValue(value);
      }, 10),
    []
  );
  React.useEffect(() => {
    return scrollY.onChange(update);
  }, [scrollY, update]);
  return (
    <Container>
      <Tutorial open={tutorialOpen} handleNext={handleNext} />
      <Wrapper container spacing={1}>
        <LeftContainer item lg={9} md={12} sm={12} xs={12}>
          <TitleContainer>
            <BadgeContainer>
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  maxWidth: "70px",
                  height: "24px",
                  background: colors.purple._05,
                  p: "4px 12px",
                  fontSize: "12px",
                  color: colors.purple._60,
                  borderRadius: "4px",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  mr: "0.5rem",
                }}
              >
                참여 가능
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  maxWidth: "56px",
                  height: "24px",
                  background: "#FFF5F5",
                  p: "4px 12px",
                  fontSize: "12px",
                  color: "#FF5036",
                  borderRadius: "4px",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                D-6
              </Typography>
            </BadgeContainer>
            <Title>
              {project?.title || "창소프트 재건축 프로젝트"} 시공사를 찾아요.
            </Title>
            <AddressContainer>
              <Typography sx={{ fontSize: "14px", color: colors.grey._60 }}>
                {project?.location ||
                  "서울시 강남구 테헤란로 103길 8-7 (삼성동)"}
              </Typography>
              <Typography sx={{ fontSize: "1rem", color: colors.grey._60 }}>
                🔥벌써
                <span style={{ fontSize: "1.2rem", color: colors.purple._60 }}>
                  &nbsp;24개&nbsp;
                </span>
                시공 파트너가 참여를 희망해요.
              </Typography>
            </AddressContainer>
          </TitleContainer>
          <Gallery />
          <Information />
          <Pricing active={active.toString()} handleActive={handleActive} />
          <Location />
        </LeftContainer>
        <RightContainer
          scrolly={scrollYValue}
          activefloat={matches.toString()}
          item
          lg={3}
          md={12}
          sm={12}
          xs={12}
        >
          <Participation
            active={active}
            handleActive={handleActive}
            handleTutorialOpen={handleTutorialOpen}
            projectName={project?.title}
            projectType={project?.form}
            projectScale={project?.area}
            projectBudget={project?.budget}
          />
        </RightContainer>
      </Wrapper>
    </Container>
  );
}
