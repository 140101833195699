import React from "react";
import { Backdrop, Button, Typography } from "@mui/material";
import { TutorialMaster, TutorialArrow } from "Components";
import { ResponsiveTutorialBox } from "Components/Icons/ResponsiveTutorialBox";
import { colors } from "Context";
import { TypingMultiline } from "react-kr-typing-anim";

interface PropType {
  open: boolean;
  handleTutorialClose(): void;
  handleMatching(): void;
}

export const Tutorial = (props: PropType) => {
  const { open, handleMatching } = props;
  const [active, setActive] = React.useState(false);
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        background: "rgba(37, 37, 37, 0.9)",
      }}
      open={open}
      // onClick={handleTutorialClose}
    >
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingLeft: "1rem",
            paddingRight: "1rem",
          }}
        >
          <TutorialMaster style={{ minWidth: 80 }} />
          <div style={{ position: "relative", marginLeft: "2rem" }}>
            {/* <TutorialBox /> */}
            <ResponsiveTutorialBox>
              <div
                style={{
                  // position: "absolute",
                  width: "100%",
                  top: 46,
                  left: 59,
                  fontSize: 18,
                  fontWeight: 500,
                  letterSpacing: -0.3,
                  paddingLeft: 24,
                  paddingRight: 24,
                }}
              >
                {open ? (
                  <TypingMultiline
                    Tag="div"
                    postDelay={700}
                    speed={30}
                    cursor={false}
                    fixedWidth
                    onDone={() => setActive(true)}
                    strs={`시공 파트너 매칭을 선택해 주셨군요.
          과연 몇 개의 시공 파트너가 참여를 희망하는지, 결과를 확인해 볼까요? 🏃🏻‍♀️`}
                  />
                ) : null}
                <div
                  style={{
                    display: active ? "flex" : "none",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    onClick={handleMatching}
                    disableRipple
                    sx={{
                      color: colors.white,
                      fontSize: 12,
                      fontWeight: 400,
                      letterSpacing: -0.3,
                      textShadow: `0px 0px 10px #EDEDED`,
                      height: 16,
                      mt: 1,
                    }}
                  >
                    확인
                  </Button>
                </div>
              </div>
            </ResponsiveTutorialBox>
          </div>
        </div>
        <div
          style={{ display: "flex", alignItems: "center", paddingLeft: "27%" }}
        >
          <TutorialArrow />
          <Typography
            sx={{ ml: 2, fontWeight: 400, fontSize: 12, letterSpacing: -0.3 }}
          >
            확인 버튼을 눌러 다음 페이지를 확인하세요.
          </Typography>
        </div>
      </div>
    </Backdrop>
  );
};
