import * as React from "react";
import { SVGProps } from "react";

export const OrderEqual = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99847 19.99C15.4808 19.99 20 15.4656 20 9.99502C20 4.51761 15.4739 0 9.99158 0C4.51917 0 0 4.51761 0 9.99502C0 15.4656 4.52604 19.99 9.99847 19.99Z"
      fill="#664AE3"
    />
    <path
      d="M6.00335 12.4607C5.59481 12.4607 5.3125 12.2541 5.3125 11.8595C5.3125 11.461 5.58414 11.2407 6.00335 11.2407H13.9797C14.392 11.2407 14.6637 11.461 14.6637 11.8595C14.6637 12.2541 14.3814 12.4607 13.9797 12.4607H6.00335ZM6.00335 8.76881C5.59481 8.76881 5.3125 8.56227 5.3125 8.16382C5.3125 7.76231 5.58414 7.54883 6.00335 7.54883H13.9797C14.392 7.54883 14.6637 7.76231 14.6637 8.16382C14.6637 8.56227 14.3814 8.76881 13.9797 8.76881H6.00335Z"
      fill="white"
    />
  </svg>
);
