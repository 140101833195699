import { styled } from "@mui/material";
import { ProgPercentHorizontal } from "Components/Icons";

const Wrap = styled(`div`)(() => ({
  marginTop: 40,
  marginBottom: 15,
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
}));

const StyledBar = styled("div")(() => ({
  borderTop: "4px solid #836DE9",
  height: "50px",
  //   width: "100px",
  width: "12.5%",
  display: "flex",
  justifyContent: "space-between",
  position: "relative",
}));

const GreyBar = styled("div")(() => ({
  borderTop: "4px solid #f2f2f2",
  height: "50px",
  width: "12.5%",
  display: "flex",
  position: "relative",
  svg: {
    position: "absolute",
    left: -18,
    bottom: 60,
  },
}));

const PurpleDot = styled(`span`)(() => ({
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  top: -6,
  left: -2,
  width: "8px",
  height: "8px",
  backgroundColor: "#836DE9",
  borderRadius: "4px",
}));

const HollowDot = styled(`span`)(() => ({
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  top: -8,
  width: "14px",
  height: "14px",
  backgroundColor: "#fafafc",
  borderRadius: "50%",
  boxShadow: "0 0 0 4px #836DE9",
}));

const GreyDot = styled(`span`)(() => ({
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  bottom: 45,
  right: 0,
  width: "8px",
  height: "8px",
  backgroundColor: "#d3d4d7",
  borderRadius: "4px",
}));

const OneSection = styled(`div`)(() => ({
  position: "absolute",
  top: 20,
  left: -24,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  maxWidth: 55,

  "div:first-of-type": {
    width: "40px",
    height: "18px",
    marginBottom: "8px",
    background: "#ebe7fd",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    span: {
      fontWeight: 500,
      fontSize: "0.6rem",
      lineHeight: "16px",
      color: "#664ae3",
    },
  },
}));

const ResponsiveSpan = styled(`span`)(() => ({
  maxWidth: 60,
  fontWeight: 500,
  fontSize: "1rem",
  lineHeight: "24px",
  color: "#7d7b88",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

const LastSection = styled(`div`)(() => ({
  position: "absolute",
  bottom: -24,
  right: 0,
}));

export const HorizontalProgress = () => {
  return (
    <Wrap>
      <StyledBar>
        <PurpleDot />
        <OneSection>
          <div>
            <span>완료</span>
          </div>
          <ResponsiveSpan
            sx={{ fontSize: { lg: "1rem", md: "0.8rem", xs: "0.6rem" } }}
          >
            가설 공사
          </ResponsiveSpan>
        </OneSection>
      </StyledBar>
      <StyledBar>
        <PurpleDot />
        <OneSection>
          <div>
            <span>완료</span>
          </div>
          <ResponsiveSpan
            sx={{ fontSize: { lg: "1rem", md: "0.8rem", xs: "0.6rem" } }}
          >
            토공사
          </ResponsiveSpan>
        </OneSection>
      </StyledBar>
      <StyledBar>
        <PurpleDot />
        <OneSection>
          <div>
            <span>완료</span>
          </div>
          <ResponsiveSpan
            sx={{ fontSize: { lg: "1rem", md: "0.8rem", xs: "0.6rem" } }}
          >
            기초 공사
          </ResponsiveSpan>
        </OneSection>
      </StyledBar>
      <StyledBar>
        <PurpleDot />
        <OneSection>
          <div>
            <span>완료</span>
          </div>
          <ResponsiveSpan
            sx={{ fontSize: { lg: "1rem", md: "0.8rem", xs: "0.6rem" } }}
          >
            골조 공사
          </ResponsiveSpan>
        </OneSection>
      </StyledBar>
      <StyledBar>
        <PurpleDot />
        <OneSection>
          <div>
            <span>완료</span>
          </div>
          <ResponsiveSpan
            sx={{ fontSize: { lg: "1rem", md: "0.8rem", xs: "0.6rem" } }}
          >
            전기 공사
          </ResponsiveSpan>
        </OneSection>
      </StyledBar>
      <StyledBar>
        <PurpleDot />
        <OneSection>
          <div>
            <span>완료</span>
          </div>
          <ResponsiveSpan
            sx={{ fontSize: { lg: "1rem", md: "0.8rem", xs: "0.6rem" } }}
          >
            설비 공사
          </ResponsiveSpan>
        </OneSection>
      </StyledBar>

      <GreyBar>
        <GreyDot />
        <ProgPercentHorizontal />
        <HollowDot />
        <OneSection style={{ top: 14 }}>
          <div
            style={{
              width: "46px",
              height: "24px",
              marginBottom: "8px",
              background: "#664ae3",
              borderRadius: "4px",
            }}
          >
            <span style={{ color: "white" }}>진행중</span>
          </div>
          <ResponsiveSpan
            sx={{
              fontSize: { lg: "1rem", md: "0.8rem", xs: "0.6rem" },
              color: "#262338",
            }}
          >
            마감 공사
          </ResponsiveSpan>
        </OneSection>
        <LastSection>
          <ResponsiveSpan
            sx={{
              fontSize: { lg: "1rem", md: "0.8rem", xs: "0.6rem" },
              color: "#a8a7af",
            }}
          >
            준공
          </ResponsiveSpan>
        </LastSection>
      </GreyBar>
    </Wrap>
  );
};
