import React from "react";
import { Grid, CircularProgress } from "@mui/material";
import { useAppSelector } from "store";
import { Viewer } from "./Viewer";
import { Content } from "./Content";
import { colors } from "Context";

export function Curation() {
  const { loading } = useAppSelector((state) => state.forge);
  return (
    <Grid
      sx={{
        width: "100vw",
        height: { lg: "100vh", xs: "auto" },
        p: { lg: "0 0", xs: "0 1rem" },
        position: "relative",
      }}
      container
    >
      <Grid
        item
        sx={{
          width: { lg: "58.33%", xs: "100%" },
          height: { lg: "100%", xs: "40vh" },
          pt: "64px",
          position: { lg: "fixed", xs: "unset" },
          top: 0,
        }}
        lg={7}
      >
        {loading && (
          <CircularProgress
            sx={{
              position: "absolute",
              marginLeft: "50%",
              marginTop: "50%",
              color: colors.purple._100,
            }}
          />
        )}
        <Viewer />
      </Grid>
      <Grid
        item
        sx={{
          marginLeft: { lg: "58.33%", xs: 0 },
          width: "100%",
        }}
        lg={5}
      >
        <Content />
      </Grid>
    </Grid>
  );
}
