import * as React from "react";
import { SVGProps } from "react";

export const AreaIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 2 2 5l8 3 8-3-8-3Z"
      stroke="#D4D3D7"
      strokeWidth={2.8}
      strokeLinejoin="round"
    />
    <path
      d="M10 8 2 5v9.75l8 3V8ZM18 14.75V5l-8 3v9.75l8-3Z"
      stroke="#D4D3D7"
      strokeWidth={2.8}
      strokeLinejoin="round"
    />
  </svg>
);
