import { Box, LinearProgressProps } from "@mui/material";
import { colors } from "Context";
import { BorderLinearProgress, DetailTypo } from "./Styles";

export function PaymentProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        mt: "2rem",
      }}
    >
      <BorderLinearProgress
        variant="determinate"
        {...props}
        sx={{
          position: "absolute",
          zIndex: 1,
          width: "100%",
        }}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          position: "absolute",
          zIndex: 2,
        }}
      >
        <Box sx={{ minWidth: 35 }}>
          <DetailTypo
            style={{ fontWeight: 700, color: colors.white, textAlign: "right" }}
          >{`${Math.round(props.value)}%`}</DetailTypo>
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <DetailTypo
            style={{ fontWeight: 700, color: colors.white }}
          >{`${Math.round(100 - props.value)}%`}</DetailTypo>
        </Box>
      </div>
    </Box>
  );
}
