import { Paper, styled } from "@mui/material";
import { PurpleCalendar } from "Components/Images";

const StyledPaper = styled(Paper)(() => ({
  width: "100%",
  height: "100%",
  minHeight: 331,
  paddingTop: 24,
  border: "1px solid #F2F2F2",
  boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.08)",
  borderRadius: "8px",
  paddingLeft: 24,
  paddingRight: 24,
  "span:first-of-type": {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: "24px",
    letterSpacing: -0.3,
  },
  div: {
    display: "flex",
    justifyContent: "center",
    marginTop: 19,
  },
}));

export const ManageCalendar = () => {
  return (
    <StyledPaper>
      <span>공사 일정 관리</span>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <PurpleCalendar />
      </div>
    </StyledPaper>
  );
};
