import { colors } from "Context";
import { useNavigate } from "react-router-dom";
import {
  Column,
  HeadTypo,
  PossibleBoard,
  PossibleTypo,
  Row,
  SmallestTypo,
  TitleTypo,
} from "./Styles";

export const Possible = () => {
  const navigate = useNavigate();
  return (
    <PossibleBoard>
      <Row
        style={{
          paddingBottom: 16,
          borderBottom: `1px solid ${colors.grey._10}`,
        }}
      >
        <Column style={{ alignItems: "start" }}>
          <TitleTypo>참여 가능한 프로젝트</TitleTypo>
          <SmallestTypo>설정하신 조건에 맞는 추천 프로젝트입니다.</SmallestTypo>
        </Column>
        <HeadTypo
          sx={{
            fontSize: 28,
            textDecoration: "underline",
            textUnderlinePosition: "under",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: colors.white,
              color: colors.purple._60,
              textDecoration: "underline",
              textDecorationColor: colors.purple._60,
            },
          }}
          onClick={() => {
            navigate("/partner/project-list");
          }}
        >
          4건
        </HeadTypo>
      </Row>

      <Column sx={{ pt: 3 }}>
        <Row style={{ paddingBottom: 4, justifyContent: "left" }}>
          <PossibleTypo sx={{ marginRight: 2 }}>#서울 강남구</PossibleTypo>
          <PossibleTypo sx={{ marginRight: 2 }}>#경기 성남시</PossibleTypo>
          <PossibleTypo>#상가 건물</PossibleTypo>
        </Row>
        <Row sx={{ justifyContent: "left" }}>
          <PossibleTypo sx={{ marginRight: 2 }}>#50억 미만</PossibleTypo>
          <PossibleTypo>#입찰 가능</PossibleTypo>
        </Row>
        <Row style={{ paddingTop: 20, justifyContent: "left" }}>
          <SmallestTypo
            style={{
              color: colors.grey._40,
              display: "flex",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            *설정 조건은
            <span
              style={{
                color: colors.grey._40,
                textDecoration: "underline",
                marginLeft: 2,
                cursor: "pointer",
                fontSize: 10,
                fontWeight: 400,
                letterSpacing: -0.3,
              }}
            >
              프로젝트 목록&gt;필터
            </span>
            에서 변경 가능합니다.
          </SmallestTypo>
        </Row>
      </Column>
    </PossibleBoard>
  );
};
