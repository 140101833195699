import React from "react";
import {
  Grid,
  styled,
  Typography,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  IconButton,
} from "@mui/material";
import { colors } from "Context";
import { LockComponent } from "./Lock";

const Container = styled(Grid)(() => ({
  width: "100%",
  paddingBottom: "3rem",
  borderBottom: `1px solid ${colors.grey._20}`,
}));

export function Information() {
  const [more, setMore] = React.useState(false);
  const handleShowMore = () => {
    setMore(!more);
  };
  return (
    <Container container sx={{ mt: "2rem" }}>
      <Grid item md={6} sx={{ width: "100%" }}>
        <MetaData title="공사 위치">서울시 강남구 삼성동</MetaData>
        <MetaData title="공사 완료 희망일">2023년 상반기 중</MetaData>
        <MetaData
          title="의뢰인"
          isLocked
          tooltip="참여 신청하고 자세한 정보 확인하기"
        >
          왕방울 / 010-4***-1***
        </MetaData>
        <MetaData
          title="담당 건축사"
          isLocked
          tooltip="참여 신청하고 자세한 정보 확인하기"
        >
          빌*** 건축사무소(김**)
        </MetaData>
      </Grid>
      <Grid
        item
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <Typography
          sx={{ color: colors.grey._60, fontSize: "14px", mb: "10px" }}
        >
          추가 요청 사항
        </Typography>
        <Typography
          component="span"
          sx={{
            color: colors.grey._100,
            fontSize: "14px",
            fontWeight: 400,
            maxWidth: "440px",
            overflow: "hidden",
            display: more ? "inherit" : "-webkit-box",
            WebkitLineClamp: more ? 9999 : 4,
            WebkitBoxOrient: "vertical",
          }}
        >
          입찰요청서에 구분 표기된 자재들은 제가 직접 지급할 계획입니다. 해당
          부분을 반드시 유념하여 견적 계산 부탁드립니다. 가급적 자재는 제가
          지정한 자재들로 공사가 진행되었으면 좋겠습니다. 마음대로 바꾸는 것은
          원치 않아요. 유의 부탁드립니다! 선댁한 자재는 공사현장으로 묶음배송이
          올 예정입니다. 입찰요청서에 구분 표기된 자재들은 제가 직접 지급할
          계획입니다. 해당 부분을 반드시 유념하여 견적 계산 부탁드립니다. 가급적
          자재는 제가 지정한 자재들로 공사가 진행되었으면 좋겠습니다. 마음대로
          바꾸는 것은 원치 않아요. 유의 부탁드립니다! 선댁한 자재는 공사현장으로
          묶음배송이 올 예정입니다.
        </Typography>
        <span
          style={{
            fontSize: "12px",
            fontWeight: 400,
            color: "#A8A7AF",
            letterSpacing: "-0.3px",
            textDecorationLine: "underline",
            cursor: "pointer",
          }}
          onClick={handleShowMore}
        >
          {more ? "닫기" : "더보기"}
        </span>
      </Grid>
    </Container>
  );
}

interface MetaDataProps {
  title: string;
  children: React.ReactNode;
  isLocked?: boolean;
  tooltip?: string;
}

function MetaData(props: MetaDataProps) {
  const { title, children, isLocked, tooltip } = props;
  const [hover, setHover] = React.useState(false);
  return (
    <Grid container sx={{ width: "100%", mb: "10px" }}>
      <Grid item lg={3} xs={3} sx={{ minWidth: "120px" }}>
        <Typography
          sx={{
            color: colors.grey._60,
            fontSize: "14px",
            fontWeight: 400,
            minHeight: "24px",
          }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid
        item
        lg={9}
        xs={9}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        <Typography
          sx={{
            color: colors.grey._100,
            fontSize: "14px",
            fontWeight: 400,
            mr: "6px",
          }}
        >
          {children}
        </Typography>
        {isLocked && (
          <CustomTooltip title={tooltip} placement="right">
            <IconButton size="small" sx={{ height: "18px" }}>
              <LockComponent unlock={hover} />
            </IconButton>
          </CustomTooltip>
        )}
      </Grid>
    </Grid>
  );
}

export function CustomTooltip(props: TooltipProps) {
  const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      "&:before": {
        border: `1px solid #FCFCFC`,
        boxShadow:
          "2px 2px 6px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.05)",
      },
      color: "#ffffff",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      color: colors.grey._60,
      backgroundColor: "#FCFCFC",
      boxShadow:
        "2px 2px 6px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.05)",
    },
  }));
  return <CustomTooltip {...props} />;
}
