import React from "react";
import { Paper, styled, Container } from "@mui/material";
import { GraphIcon, BarGraph } from "Components/Icons";

const StyledPaper = styled(Paper)(() => ({
  height: "100%",
  width: "100%",
  border: "1px solid #F2F2F2",
  boxShadow:
    "2px 2px 6px rgba(102, 73, 226, 0.1), 0px 0px 2px rgba(102, 73, 226, 0.1)",
  borderRadius: "8px",
}));
export const BudgetGraph = () => {
  return (
    <StyledPaper
      sx={{
        maxWidth: { lg: "574px", sx: "100%" },
        minHeight: { lg: "466px", xs: "100%" },
        maxHeight: { lg: "466px", xs: "100%" },
      }}
    >
      <Container sx={{ padding: "25px 0", width: "100%" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: 500,
            fontSize: "18px",
          }}
        >
          <GraphIcon />
          공사 소요 비용
        </div>
        <div style={{ width: "100%", height: "100%", padding: 10 }}>
          <BarGraph />
        </div>
      </Container>
    </StyledPaper>
  );
};
