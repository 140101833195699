import React from "react";
import { Grid } from "@mui/material";
import {
  Wrap,
  TabGrid,
  TabDiv,
  CustomTabs,
  CustomTab,
  TitleDiv,
  ParterInfoBox,
  TitleInfoDiv,
  RankDiv,
  IconDiv,
  NameTypo,
  InfoTypo,
  ContentDiv,
  ContentTypo,
  UnitTypo,
} from "./TabStyles";
import {
  bidAverageList,
  constructionCapabilityEvaluationList,
  gradeList,
  TabPropType,
} from "./Types";

export const Tab = () => {
  return (
    <Wrap>
      <TabGrid
        container
        sx={{
          padding: {
            lg: "0px 16px",
            md: "0px 16px",
            sm: "0px 24px",
            xs: "0px 32px",
          },
        }}
      >
        <TabDiv>
          <CustomTabs value={0} sx={{ mb: 3 }}>
            <CustomTab label="입찰순위" />
            <CustomTab label="원가 계산서" />
          </CustomTabs>
        </TabDiv>

        <Grid container spacing={2}>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <TitleDiv color="#FF5036" background="rgba(255, 203, 203, 0.2)">
              <span style={{ fontWeight: 500 }}>입찰평균</span>에&nbsp;가까워요
            </TitleDiv>
            <div>
              {bidAverageList.map((item) => {
                return <PartnerInfoDiv key={item.rank} info={item} />;
              })}
            </div>
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <TitleDiv color="#008FE2" background="rgba(189, 231, 255, 0.2)">
              <span style={{ fontWeight: 500 }}>시공능력평가</span>&nbsp;높아요
            </TitleDiv>
            <div>
              {constructionCapabilityEvaluationList.map((item) => {
                return <PartnerInfoDiv key={item.rank} info={item} />;
              })}
            </div>
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <TitleDiv color="#00A185" background="rgba(176, 219, 217, 0.2)">
              <span style={{ fontWeight: 500 }}>평점이</span>&nbsp;좋아요
            </TitleDiv>
            <div>
              {gradeList.map((item) => {
                return <PartnerInfoDiv key={item.rank} info={item} />;
              })}
            </div>
          </Grid>
        </Grid>
      </TabGrid>
    </Wrap>
  );
};

const PartnerInfoDiv = (props: TabPropType) => {
  const {
    info: { rank, icon, name, info, beforeUnit, content, unit },
  } = props;
  return (
    <ParterInfoBox>
      <RankDiv>
        {rank}
        <IconDiv>
          {icon}
          <TitleInfoDiv>
            <NameTypo>{name}</NameTypo>
            <InfoTypo>{info}</InfoTypo>
          </TitleInfoDiv>
        </IconDiv>
      </RankDiv>
      <ContentDiv>
        <ContentTypo>
          <span style={{ paddingRight: 4 }}>{beforeUnit}</span>
          {content.toLocaleString()}
        </ContentTypo>
        <UnitTypo>{unit}</UnitTypo>
      </ContentDiv>
    </ParterInfoBox>
  );
};
