import React from "react";
import {
  BuildingIcon,
  KRWIcon,
  CurationCalendarIcon as CalendarIcon,
} from "Components";
import { Grid, Typography, Box } from "@mui/material";
import { colors } from "Context";

export function MetaField() {
  return (
    <Box
      sx={{
        pl: "2rem",
        pt: "2rem",
        pb: "20px",
        borderBottom: "solid 1px #f2f2f2",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography
          component="span"
          variant="subtitle1"
          sx={{
            fontWeight: 500,
            fontSize: { xs: 16, md: 20 },
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          비트라 신사동 카페
        </Typography>
        <Typography
          component="span"
          variant="subtitle1"
          sx={{
            ml: "0.5rem",
            fontSize: { xs: 14, md: 16 },
            color: colors.grey._40,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          서울특별시 강남구 테헤란로103길 8-7
        </Typography>
      </div>
      <Grid container sx={{ display: "flex", justifyContent: "flex-start" }}>
        <TextWithIcon icon={<BuildingIcon />} text="상가주택" />
        <TextWithIcon icon={<KRWIcon />} text="10억" />
        <TextWithIcon icon={<CalendarIcon />} text="22년 하반기 완공 목표" />
      </Grid>
    </Box>
  );
}

interface TextWithIconProps {
  icon: React.ReactNode;
  text: string;
}

function TextWithIcon(props: TextWithIconProps) {
  const { icon, text } = props;
  return (
    <Grid
      item
      xs="auto"
      sx={{ display: "flex", alignItems: "center", marginLeft: "1rem" }}
    >
      {icon}
      <Typography
        component="span"
        variant="subtitle1"
        sx={{
          ml: 0.4,
          mt: 0.1,
          color: colors.grey._40,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {text}
      </Typography>
    </Grid>
  );
}
