import * as React from "react";
import { SVGProps } from "react";

export const PlayIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={46}
    height={46}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={46} height={46} rx={4} fill="#F6F4FF" />
    <path
      d="M22.5 35C29.338 35 35 29.326 35 22.5 35 15.662 29.326 10 22.488 10 15.662 10 10 15.662 10 22.5 10 29.326 15.674 35 22.5 35Zm0-2.083A10.366 10.366 0 0 1 12.096 22.5c0-5.784 4.607-10.417 10.392-10.417A10.387 10.387 0 0 1 32.917 22.5 10.377 10.377 0 0 1 22.5 32.917Z"
      fill="#664AE3"
    />
    <path
      d="m19.89 27.162 7.033-4.03c.52-.287.507-.98 0-1.28l-7.033-4.03c-.531-.298-1.236-.07-1.236.515v8.31c0 .575.655.85 1.236.515Z"
      fill="#664AE3"
    />
  </svg>
);
