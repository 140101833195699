import { styled, Grid, Typography } from "@mui/material";
import { colors } from "Context";

export const Wrap = styled(Grid)(() => ({
  height: "calc(100vh - 64px)",
}));
export const Left = styled(Grid)(() => ({
  position: "relative",
  background: "#FCFCFC",
  display: "flex",
  justifyContent: "center",
  flexDirection: "row",
  paddingBottom: 400,
}));
export const DemoDiv = styled(`div`)(() => ({
  width: "fit-content",
  background: colors.grey._10,
  borderRadius: 8,
  fontSize: 14,
  color: colors.grey._60,
  height: 34,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: `6px 7px`,
  fontWeight: 400,
  letterSpacing: -0.3,
  boxShadow: "2px 2px 6px rgb(0 0 0 / 8%), 0px 0px 2px rgb(0 0 0 / 5%)",
}));

export const TypoDiv = styled(`div`)(() => ({
  marginTop: 131,
  textAlign: "center",
}));
export const BoxGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  maxWidth: 285,
  width: "100%",
}));
export const MainTypo = styled(Typography)(() => ({
  fontSize: 20,
  fontWeight: 400,
  letterSpacing: -0.3,
  color: colors.grey._60,
}));
export const SubTypo = styled(Typography)(() => ({
  fontSize: 24,
  fontWeight: 500,
  letterSpacing: -0.3,
  color: colors.grey._100,
}));

export const BoxExplainTypo = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 400,
  color: colors.grey._40,
  textAlign: "center",
  marginTop: 26,
  whiteSpace: "nowrap",
  span: {
    fontWeight: 700,
  },
}));

export const CharacterDiv = styled(`div`)(() => ({
  position: "absolute",
  right: 0,
  bottom: 0,
}));

export const Right = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "center",
  paddingTop: 149,
  paddingBottom: 260,
}));
export const Container = styled(`div`)(() => ({
  width: "100%",
  maxWidth: 378,
  minWidth: 378,
  display: "flex",
  flexDirection: "column",
}));
export const Title = styled(Typography)(() => ({
  fontSize: 24,
  fontWeight: 500,
  letterSpacing: -0.3,
  color: colors.black,
  textAlign: "center",
  marginBottom: 92,
}));

export const ExampleDiv = styled(`div`)(() => ({
  width: "100%",
  maxWidth: 378,
  minHeight: 52,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "#FAFAFA",
  borderRadius: 99,
  color: colors.grey._100,
  fontSize: 18,
  letterSpacing: -0.3,
  boxShadow: "2px 2px 6px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.05)",
  marginBottom: 44,
}));
