import React from "react";
import moment from "moment";
import { nanoid } from "nanoid";
import { RequestName } from "./RequestName";
import { Status, StatusType } from "./Status";

enum RequestType {
  field = "현장",
  partner = "거래처",
}

function createData(
  id: string,
  name: React.ReactNode,
  type: RequestType,
  requestor: string,
  amount: number,
  status: React.ReactNode,
  issueDate: string
) {
  return { id, name, type, requestor, amount, status, issueDate };
}

moment.locale("kr", {
  weekdays: ["일", "월", "화", "수", "목", "금", "토"],
  meridiem: (hours) => (hours < 12 ? "오전" : "오후"),
});

export const rows = [
  createData(
    nanoid(10),
    <RequestName text="청구" />,
    RequestType.field,
    "쌍림동 현장",
    6500000,
    <Status status={StatusType.accepted} />,
    moment()
      .subtract(Math.ceil(Math.random() * 30), "day")
      .format("YYYY.MM.DD (dddd)")
  ),
  createData(
    nanoid(10),
    <RequestName text="지급" />,
    RequestType.field,
    "삼성동 현장",
    6500000,
    <Status status={StatusType.paid} />,
    moment()
      .subtract(Math.ceil(Math.random() * 30), "day")
      .format("YYYY.MM.DD (dddd)")
  ),
  createData(
    nanoid(10),
    <RequestName text="청구" />,
    RequestType.partner,
    "빌더건설(철근)",
    6500000,
    <Status status={StatusType.onApprovement} />,
    moment()
      .subtract(Math.ceil(Math.random() * 30), "day")
      .format("YYYY.MM.DD (dddd)")
  ),
  createData(
    nanoid(10),
    <RequestName text="청구" />,
    RequestType.partner,
    "빌더건설(철근)",
    6500000,
    <Status status={StatusType.rejected} />,
    moment()
      .subtract(Math.ceil(Math.random() * 30), "day")
      .format("YYYY.MM.DD (dddd)")
  ),
  createData(
    nanoid(10),
    <RequestName text="청구" />,
    RequestType.field,
    "삼성동 현장",
    6500000,
    <Status status={StatusType.approved} />,
    moment()
      .subtract(Math.ceil(Math.random() * 30), "day")
      .format("YYYY.MM.DD (dddd)")
  ),
  createData(
    nanoid(10),
    <RequestName text="지급" />,
    RequestType.field,
    "삼성동 현장",
    6500000,
    <Status status={StatusType.paid} />,
    moment()
      .subtract(Math.ceil(Math.random() * 30), "day")
      .format("YYYY.MM.DD (dddd)")
  ),
  createData(
    nanoid(10),
    <RequestName text="지급" />,
    RequestType.field,
    "쌍림동 현장",
    6500000,
    <Status status={StatusType.paid} />,
    moment()
      .subtract(Math.ceil(Math.random() * 30), "day")
      .format("YYYY.MM.DD (dddd)")
  ),
];
