import * as React from "react";
import { SVGProps } from "react";

export const ProgressBall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={46}
    height={46}
    // viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={23} cy={23} r={22.5} fill="#fff" stroke="#664AE3" />
    <mask
      id="aProgressBall"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={46}
      height={46}
    >
      <circle cx={23} cy={23} r={22.5} fill="#fff" stroke="#fff" />
    </mask>
    <g mask="url(#aProgressBall)">
      <path
        opacity={0.1}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.873 12.894s-7.765-5.319-20.81-.862C24.017 16.49-6.614 14.943-6.614 14.943l3.536 44.792h58.161l2.789-46.841Z"
        fill="#664AE3"
      />
      <path
        opacity={0.296}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-8.17 19.84s19.015-12.53 32.06-8.073c13.044 4.457 28.867 2.503 28.867 2.503l.023 46.661h-77.505l.972-37.317L-8.17 19.84Z"
        fill="#664AE3"
      />
      <path
        opacity={0.85}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-5.75 14.994s15.381-3.737 28.426.72C35.721 20.171 55.2 10.72 55.2 10.72v51.117H-2.961L-5.75 14.994Z"
        fill="#664AE3"
      />
      <path
        d="M15.703 18.132H14.06l-2.424 3.24c-.552.744-1.056 1.692-1.056 2.64 0 1.884 1.164 3.084 3 3.084 1.716 0 3.096-1.212 3.096-2.94 0-1.536-1.02-2.652-2.628-2.652-.468 0-.936.12-1.344.348l3-3.72Zm-2.148 7.872c-1.104 0-1.704-.744-1.704-1.776 0-.984.744-1.716 1.752-1.716 1.056 0 1.752.684 1.752 1.74 0 1.044-.804 1.752-1.8 1.752Zm9.957-6.936v-.936h-6.084v1.212h4.74a2.783 2.783 0 0 0-.42.636L18.172 27h1.44l3.9-7.932ZM27.41 23.364c1.247 0 2.027-.9 2.027-2.688 0-1.788-.78-2.652-2.028-2.652-1.212 0-2.004.864-2.004 2.652 0 1.788.792 2.688 2.004 2.688Zm5.963-5.34h-.948l-4.86 9.12h.96l4.848-9.12ZM27.41 22.62c-.66 0-1.116-.564-1.116-1.944 0-1.356.444-1.908 1.116-1.908.672 0 1.128.528 1.128 1.908 0 1.392-.456 1.944-1.128 1.944Zm6.096 4.5c1.212 0 2.016-.888 2.016-2.676 0-1.788-.804-2.652-2.016-2.652s-2.028.864-2.028 2.652c0 1.788.816 2.676 2.028 2.676Zm0-.744c-.672 0-1.128-.552-1.128-1.932s.456-1.92 1.128-1.92c.672 0 1.116.54 1.116 1.92 0 1.392-.444 1.932-1.116 1.932Z"
        fill="#fff"
      />
    </g>
  </svg>
);
