import React from "react";
import { styled, Button, Typography, ButtonProps } from "@mui/material";
import { colors } from "Context";

const CustomBox = styled(Button)(() => ({
  width: "100%",
  maxWidth: 285,
  minHeight: 153,
  border: "1px solid #F2F2F2",
  boxShadow:
    "2px 2px 15px 3px rgba(102, 73, 226, 0.05), 0px 0px 2px rgba(102, 73, 226, 0.05)",
  borderRadius: 12,
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  textAlign: "center",
  ":hover": {
    background: "rgba(235, 231, 253, 0.25)",
    boxShadow:
      "2px 2px 15px 3px rgba(102, 73, 226, 0.1), 0px 0px 2px rgba(102, 73, 226, 0.08)",
    border: `1px solid ${colors.purple._40}`,
    cursor: "pointer",
  },
}));

const TitleTypo = styled(Typography)(() => ({
  fontSize: 18,
  fontWeight: 500,
  letterSpacing: -0.3,
  color: colors.black,
}));
const SubTypo = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 400,
  letterSpacing: -0.3,
  color: colors.grey._40,
}));

interface PropType extends ButtonProps {
  icon: React.ReactNode;
  title: string;
  sub: string;
}

export const SelectRoleBox = (props: PropType) => {
  const { icon, title, sub, ...rest } = props;
  return (
    <CustomBox {...rest}>
      <div>{icon}</div>
      <TitleTypo>{title}</TitleTypo>
      <SubTypo>{sub}</SubTypo>
    </CustomBox>
  );
};
