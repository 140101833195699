import { createSvgIcon } from "@mui/material";

export const Reload = createSvgIcon(
  <svg width={12} height={13} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.126 4.36 3.105 2.62a.473.473 0 0 1 0-.814l3.02-1.74c.31-.187.702.047.702.402v.603A5.911 5.911 0 1 1 .225 5.29a1 1 0 1 1 1.924.545A3.91 3.91 0 1 0 9.821 6.91a3.912 3.912 0 0 0-2.994-3.803v.85a.47.47 0 0 1-.701.401Z"
      fill="#A8A7AF"
    />
  </svg>,
  "Reload"
);
