import React from "react";
import { styled, Paper, TextareaAutosize } from "@mui/material";
import { colors } from "Context";

const StyledPaper = styled(Paper)(() => ({
  width: "100%",
  height: "100%",
  border: "1px solid #F2F2F2",
  boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.08)",
  borderRadius: "8px",
  paddingTop: 31,
  paddingLeft: 24,
  paddingRight: 24,
  paddingBottom: 24,
}));

const SectionHeader = styled(`div`)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  borderBottom: "1px solid #dbdbdb",
  paddingBottom: 20,
  "span:first-of-type": {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "24px",
    color: "#262338",
  },
}));

const InputWrapper = styled(`div`)(() => ({
  paddingTop: 20,
  width: "100%",
  position: "relative",
  span: {
    position: "absolute",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "20px",
    color: colors.grey._60,
    right: 10,
    bottom: 15,
  },
}));
export const TextArea = styled(TextareaAutosize)(() => ({
  width: "100%",
  minHeight: 88,
  padding: 12,
  resize: "none",
  fontSize: 16,
  fontWeight: 400,
  color: colors.grey._100,
  border: `1px solid ${colors.grey._20}`,
  borderRadius: 4,
  "&::placeholder": {
    color: colors.grey._40,
  },
  "&:hover": {
    outline: "none",
  },
  "&:focus": {
    outline: `solid 1px ${colors.purple._60}`,
    borderRadius: 4,
  },
}));

export const CommentSection = () => {
  const [comment, setComment] = React.useState("");

  return (
    <StyledPaper>
      <SectionHeader>
        <span>건축주에게 전할 메세지</span>
      </SectionHeader>
      <InputWrapper>
        <TextArea
          placeholder="미작성 시 기본 메세지로 전송됩니다."
          onChange={(e) => setComment(e.target.value)}
        />
        <span>{comment.length} / 100자 이상</span>
      </InputWrapper>
    </StyledPaper>
  );
};
