import { colors } from "Context";
import {
  Board,
  BodyTypo,
  DetailTypo,
  Row,
  StyledMenuItem,
  StyledMenuItemWrap,
  TitleTypo,
  WorkStatusBox,
  WorkStatusTypo,
} from "./Styles";
import {
  DashboardPiechart,
  DashboardGraphPurple,
  DashboardGraphMint,
  DashboardStatusEnd,
} from "Components/Icons/index";
import {
  Box,
  FormControl,
  Grid,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";

const worksites = [
  "서울 강남구 삼성로 현장",
  "경기 화성시 동탄지성로 현장",
  "대구 동구 동호로 현장",
];

const workStatusArray = [
  { workerCount: 0, restingCount: 0, finishCount: 0 },
  {
    workerCount: 25,
    restingCount: 10,
    finishCount: 0,
  },
  { workerCount: 32, restingCount: 14, finishCount: 5 },
  { workerCount: 12, restingCount: 2, finishCount: 10 },
];

export const WorkStatus = () => {
  const [selectedWorksite, setSelectedWorksite] = React.useState("");
  const handleChangeSite = (event: SelectChangeEvent) => {
    setSelectedWorksite(event.target.value);
  };
  const selectedIndex = worksites.indexOf(selectedWorksite);
  const selectedWorker = workStatusArray[selectedIndex + 1].workerCount;
  const selectedResting = workStatusArray[selectedIndex + 1].restingCount;
  const selectedFinish = workStatusArray[selectedIndex + 1].finishCount;
  return (
    <Board>
      <Row>
        <TitleTypo>현장별 근무 현황</TitleTypo>
        <DetailTypo
          sx={{
            fontWeight: 700,
            cursor: "pointer",
            color: colors.purple._60,
            "&:hover": {
              color: colors.purple._10,
            },
          }}
        >
          + 더보기
        </DetailTypo>
      </Row>
      <Box sx={{ minWidth: 120, mt: 3, mb: 2 }}>
        <FormControl fullWidth>
          <Select
            value={selectedWorksite}
            displayEmpty
            renderValue={(selected) => {
              if (selected.length === 0) {
                return (
                  <BodyTypo sx={{ color: colors.grey._40 }}>
                    현장을 선택해 주세요.
                  </BodyTypo>
                );
              }
              return selected;
            }}
            onChange={handleChangeSite}
            sx={{
              height: 40,
              fontSize: 14,
              fontWeight: 400,
              color: colors.grey._100,
            }}
          >
            {worksites.map((el) => (
              <StyledMenuItemWrap key={el} value={el}>
                <StyledMenuItem el={el}>{el}</StyledMenuItem>
              </StyledMenuItemWrap>
            ))}
          </Select>
        </FormControl>
      </Box>

      <div style={{ margin: "auto" }}>
        <Grid container>
          <Grid
            item
            lg={6}
            sm={6}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "auto",
            }}
          >
            <DashboardPiechart
              selectedWorker={selectedWorker}
              selectedResting={selectedResting}
              selectedFinish={selectedFinish}
            />
          </Grid>

          <Grid
            item
            lg={6}
            sm={6}
            xs={12}
            sx={{ padding: { sm: "30px 0 0 35px", xs: "30px 35px 0 35px" } }}
          >
            <Row
              style={{
                marginBottom: 20,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <DashboardGraphPurple />
              <BodyTypo style={{ textAlign: "left", margin: "0 16px" }}>
                근무중
              </BodyTypo>
              <WorkStatusBox selectedWorksite={selectedWorksite}>
                <WorkStatusTypo
                  selectedworksite={selectedWorksite}
                  sx={{ fontWeight: 700, marginRight: 1 }}
                >
                  {selectedWorker}
                </WorkStatusTypo>
                <WorkStatusTypo selectedworksite={selectedWorksite}>
                  명
                </WorkStatusTypo>
              </WorkStatusBox>
            </Row>
            <Row
              style={{
                marginBottom: 20,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <DashboardGraphMint />
              <BodyTypo style={{ textAlign: "left", margin: "0 16px" }}>
                휴식중
              </BodyTypo>
              <WorkStatusBox selectedWorksite={selectedWorksite}>
                <WorkStatusTypo
                  selectedworksite={selectedWorksite}
                  sx={{ fontWeight: 700, marginRight: 1 }}
                >
                  {selectedResting}
                </WorkStatusTypo>
                <WorkStatusTypo selectedworksite={selectedWorksite}>
                  명
                </WorkStatusTypo>
              </WorkStatusBox>
            </Row>
            <Row
              style={{
                marginBottom: 20,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <DashboardStatusEnd />
              <BodyTypo style={{ textAlign: "left", margin: "0 16px" }}>
                작업 종료
              </BodyTypo>
              <WorkStatusBox selectedWorksite={selectedWorksite}>
                <WorkStatusTypo
                  selectedworksite={selectedWorksite}
                  sx={{ fontWeight: 700, marginRight: 1 }}
                >
                  {selectedFinish}
                </WorkStatusTypo>
                <WorkStatusTypo selectedworksite={selectedWorksite}>
                  명
                </WorkStatusTypo>
              </WorkStatusBox>
            </Row>
          </Grid>
        </Grid>
      </div>
    </Board>
  );
};
