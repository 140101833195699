// Content for 'my-awesome-extension.js'

export function MyAwesomeExtension(
  this: Autodesk.Viewing.Extension,
  viewer: Autodesk.Viewing.GuiViewer3D,
  options: any
) {
  Autodesk.Viewing.Extension.call(this, viewer, options);
}

MyAwesomeExtension.prototype = Object.create(
  Autodesk.Viewing.Extension.prototype
);
MyAwesomeExtension.prototype.constructor = MyAwesomeExtension;

MyAwesomeExtension.prototype.load = function () {
  alert("MyAwesomeExtension is loaded!");
  return true;
};

MyAwesomeExtension.prototype.unload = function () {
  alert("MyAwesomeExtension is now unloaded!");
  return true;
};

Autodesk.Viewing.theExtensionManager.registerExtension(
  "MyAwesomeExtension",
  MyAwesomeExtension
);
