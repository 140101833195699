import * as React from "react";
import { SVGProps } from "react";

export const BookMarkActive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={21}
    height={26}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.494 24.161c.69 0 1.1-.312 2.368-1.284l5.44-3.616c.071-.056.198-.056.254 0l5.582 3.616c1.269.972 1.677 1.284 2.368 1.284.944 0 1.494-.491 1.494-1.362V4.705C20 2.583 18.661 1.5 16.011 1.5H4.99C2.339 1.5 1 2.583 1 4.705V22.8c0 .87.55 1.362 1.494 1.362Z"
      fill="#664AE3"
      stroke="#664AE3"
      strokeWidth={2}
      strokeLinejoin="round"
    />
  </svg>
);
