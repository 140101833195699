import React from "react";
import { Typography } from "@mui/material";
import { colors } from "Context";

interface RequestNameProps {
  text: string;
}

export function RequestName(props: RequestNameProps) {
  const background =
    props.text === "청구" ? colors.purple._40 : colors.purple._05;
  const color = props.text === "청구" ? "#ffffff" : colors.purple._60;
  return (
    <Typography
      sx={{
        maxWidth: "46px",
        height: "24px",
        background,
        p: "4px 12px",
        fontSize: "12px",
        color,
        borderRadius: "4px",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      }}
    >
      {props.text}
    </Typography>
  );
}
