import { nanoid } from "nanoid";

export function createData(
  name: string,
  standard: string,
  unit: string,
  amount: number,
  price: number,
  keyword: string
) {
  return { id: nanoid(10), name, standard, unit, amount, price, keyword };
}
