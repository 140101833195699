import React from "react";
import { Grid, styled, ImageList, ImageListItem } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import leftLarge from "./leftLarge.png";
import right1 from "./right_1.png";
import right2 from "./right_2.png";
import right3 from "./right_3.png";
import right4 from "./right_4.png";

const Container = styled(Grid)(() => ({
  width: "100%",
  marginTop: 24,
}));

const imageData = [
  {
    img: leftLarge,
    title: "left large",
    rows: 2,
    cols: 2,
  },
  {
    img: right1,
    title: "right1",
    rows: 1,
    cols: 1,
  },
  {
    img: right2,
    title: "right2",
    rows: 1,
    cols: 1,
  },
  {
    img: right3,
    title: "right3",
    rows: 1,
    cols: 1,
  },
  {
    img: right4,
    title: "right4",
    rows: 1,
    cols: 1,
  },
];

export function Gallery() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Container container rowSpacing={1} columnSpacing={1} sx={{ ml: 0 }}>
      <ImageList
        sx={{ width: "100%", ml: 0 }}
        variant="quilted"
        cols={matches ? 2 : 4}
        rowHeight={171}
        gap={16}
      >
        {imageData.map((item) => (
          <ImageListItem
            key={item.img}
            cols={item.cols || 1}
            rows={item.rows || 1}
          >
            <img
              src={item.img}
              alt={item.title}
              loading="lazy"
              style={{ borderRadius: 4 }}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Container>
  );
}
