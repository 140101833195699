import * as React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { styled } from "@mui/material";
import { colors } from "Context";

const StyledMenuItem = styled(MenuItem)(() => ({
  fontSize: 14,
  fontWeight: 400,
  color: "#6E7191",
}));

export default function OrderSelectPayMonth() {
  const [payMonth, setPayMonth] = React.useState("");
  const handleChange = (event: SelectChangeEvent) => {
    setPayMonth(event.target.value as string);
  };

  return (
    <Box
      sx={{
        maxWidth: 492,
        mb: { xs: 9 },
      }}
    >
      <FormControl fullWidth>
        <Select
          id="payMonth-select"
          value={payMonth}
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return "일시불";
            }
            return selected;
          }}
          sx={{
            height: 46,
            fontSize: 14,
            fontWeight: 400,
            color: colors.grey._60,
            borderRadius: "8px",
          }}
        >
          <StyledMenuItem value="일시불">일시불</StyledMenuItem>
          <StyledMenuItem value="3개월">3개월</StyledMenuItem>
          <StyledMenuItem value="6개월">6개월</StyledMenuItem>
          <StyledMenuItem value="12개월">12개월</StyledMenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
