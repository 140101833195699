import * as React from "react";
import { SVGProps } from "react";

export function DashboardDailyRegistWorkClicked(
  props: SVGProps<SVGSVGElement>
) {
  return (
    <svg
      width="66"
      height="58"
      // viewBox="0 0 66 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="29"
        cy="29"
        r="27.5"
        fill="#FAFAFC"
        stroke="#664AE3"
        strokeWidth="3"
      />
      <path
        d="M39.5 15.5H33.23C32.6 13.76 30.95 12.5 29 12.5C27.05 12.5 25.4 13.76 24.77 15.5H18.5C16.85 15.5 15.5 16.85 15.5 18.5V39.5C15.5 41.15 16.85 42.5 18.5 42.5H39.5C41.15 42.5 42.5 41.15 42.5 39.5V18.5C42.5 16.85 41.15 15.5 39.5 15.5ZM29 15.5C29.825 15.5 30.5 16.175 30.5 17C30.5 17.825 29.825 18.5 29 18.5C28.175 18.5 27.5 17.825 27.5 17C27.5 16.175 28.175 15.5 29 15.5ZM32 36.5H21.5V33.5H32V36.5ZM36.5 30.5H21.5V27.5H36.5V30.5ZM36.5 24.5H21.5V21.5H36.5V24.5Z"
        fill="#664AE3"
      />
      <circle cx="56" cy="10" r="10" fill="#FF794F" />
      <path
        d="M52 9.80818L55.1918 13L60 7"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
