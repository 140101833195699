import React from "react";
import {
  Wrap,
  PageContainer,
  FileReportCard,
  ButtonWrapper,
  PageHeader,
} from "./Styles";
import { Grid } from "@mui/material";
import {
  ProjectInfoCard,
  SiteWeather,
  ManageCalendar,
  ModelStatus,
  ConsProgress,
  HorizontalProgress,
  PersonnelStatus,
  SitePics,
  CommentSection,
  ArrowHeadDown,
} from "Components";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Tutorial } from "./Tutorial";
import { useNavigate } from "react-router-dom";

export function ManageConstruction() {
  const navigate = useNavigate();
  const [tutorialOpen, setTutorial] = React.useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const handleTutorialOpen = () => {
    setTutorial(true);
  };
  const handleNext = () => {
    navigate("/partner/manage-payments");
  };
  React.useEffect(() => {
    return;
  }, [matches]);

  return (
    <React.Suspense>
      <Wrap>
        <Tutorial open={tutorialOpen} handleNext={handleNext} />
        <PageContainer container spacing={2}>
          <Grid item container spacing={2}>
            <Grid item xl={12} lg={12} md={12} xs={12}>
              <PageHeader>
                <span
                  style={{
                    fontWeight: 700,
                    fontSize: 16,
                    lineHeight: "24px",
                    marginRight: 20,
                  }}
                >
                  공사현장
                </span>
                <div>
                  <span>서울 강남구 테헤란로 현장</span>
                  <ArrowHeadDown />
                </div>
              </PageHeader>
            </Grid>
            <Grid item xl={6} lg={6} md={12} xs={12}>
              <ProjectInfoCard />
            </Grid>
            <Grid item xl={3} lg={3} md={6} xs={6}>
              <SiteWeather />
            </Grid>
            <Grid item xl={3} lg={3} md={6} xs={6}>
              <ManageCalendar />
            </Grid>
          </Grid>
          <Grid item container lg={12}>
            {matches ? (
              <Grid
                item
                container
                lg={12}
                sx={{
                  minHeight: 150,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <HorizontalProgress />
              </Grid>
            ) : (
              <Grid
                item
                lg={2}
                sx={{
                  minHeight: { lg: 913, md: 200, xs: 200 },
                  maxHeight: { lg: 1100, md: 200, xs: 200 },
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ConsProgress />
              </Grid>
            )}
            <Grid item container lg={10} md={12} xs={12} spacing={2}>
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
                sx={{ maxHeight: 60, minHeight: 60 }}
              >
                <FileReportCard>
                  <span>데일리 현장 리포트 작성</span>
                </FileReportCard>
              </Grid>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                xs={12}
                sx={{ minHeight: 532 }}
              >
                <ModelStatus />
              </Grid>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                xs={12}
                sx={{ minHeight: 412 }}
              >
                <PersonnelStatus />
              </Grid>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                xs={12}
                sx={{ minHeight: 250 }}
              >
                <SitePics />
              </Grid>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                xs={12}
                sx={{ minHeight: 200 }}
              >
                <CommentSection />
              </Grid>
            </Grid>
          </Grid>
          <ButtonWrapper>
            <button onClick={handleTutorialOpen}>
              <span>등록하기</span>
            </button>
          </ButtonWrapper>
        </PageContainer>
      </Wrap>
    </React.Suspense>
  );
}
